import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';

//Action
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_RESET_STATE = 'FORGOT_PASSWORD_RESET_STATE';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case FORGOT_PASSWORD_SUCCESS: return { ...state, success: true, error: false }
        case FORGOT_PASSWORD_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        case FORGOT_PASSWORD_RESET_STATE: return { ...state, success: false, error: false }
        default: return state;
    }
}

//Action creator

export const forgotPasswordSuccess = (token: any) => {
    return { type: FORGOT_PASSWORD_SUCCESS, payload: token }
}

export const forgotPasswordError = (errorText: string) => {
    return { type: FORGOT_PASSWORD_ERROR, payload: errorText }
}

export const forgotPasswordResetState = () => {
    return { type: FORGOT_PASSWORD_RESET_STATE, payload: FORGOT_PASSWORD_RESET_STATE }
}

//Thunks
export const handleSubmit = (email: string, logoUrl: string) => {

    let userInfo = {
        email: email.replace(" ", ""),
        logoUrl: logoUrl,
        sendEmail: true,
        sendSMS: false
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.sendResetPasswordLink, userInfo)
            .then(function (resp) {
                if (resp.success) {
                    dispatch(forgotPasswordSuccess(resp.response));
                }
                else {
                    dispatch(forgotPasswordError(resp.error));
                }
            })
    }

}