import { FC, useMemo } from "react";
import auth from "../../../networking/api/Auth";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";

interface AuthorizedProps {
    required?: boolean,
    children: any
}

const Authorized: FC<AuthorizedProps> = props => {
    const { required = true, children } = props;

    const authorized = useMemo<boolean>(() => {
        return auth();
    }, [PrembidLocalStorage.currentUser?.jwtToken]);
     
    return <>
        {required && authorized && children}
        {!required && !authorized && children}
    </>
}

export default Authorized;