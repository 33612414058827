import { Button } from "@mui/material";
import React, { useState, useReducer, FC, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import ns from "@/lib/utils/NotificationService";
import { connect } from "react-redux";
import { handleClearWishlistBiddingNotification, handleLotWishlistDataUpsert, handleSetMyActivityWishListStatus, handleSetWishlistBiddingNotification } from "@/pages/MyActivity/store/MyActivity";
import { toggleWishlist } from "../../../pages/Lots/store/Lots";
import LoginRegisterModal from "../../Modal/LoginRegisterModal";
import { handleClearAutoWishlistData, handleSetAutoWishlistData } from "../../LoginForm/store/LoginForm";

export interface MyActivityWishlistProps {
    handleLotWishlistDataUpsert_d: (
        myWishlistData: any,
        onCompletedCallback?: (res: any) => void
    ) => void;
    lotId: string;
    isOnWishlist: boolean;
    handleToggleWishList_d: (lotId: string) => void;
    handleSetAutoWishlistData_d: (
        dataObject: any,
        onCompletedCallback?: (res: any) => void
    ) => void;
    logoutSuccess: any;
    utilityClasses?: string;
    size?: number;
    handleClearAutoWishlistData_d: () => void;
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    handleClearWishlistBiddingNotification_d: (onCompletedCallback?: () => void) => void,
    wishlistNotificationFlagObject: any;
    handleSetMyActivityWishListStatus_d: (payload: any, onCompletedCallback?: () => void) => void,
    isMyActivityWishlistObject: any,
    clearMyActivityWishlistStatus_d?: () => void,
}

export const MyActivityWishlist: FC<MyActivityWishlistProps> = (props) => {
    const {
        lotId,
        handleLotWishlistDataUpsert_d,
        isOnWishlist,
        handleToggleWishList_d,
        handleSetAutoWishlistData_d,
        utilityClasses = "btn-white d-30 btn-pill p-0 btn-icon btn-animated-icon align-items-right",
        size,
        handleClearAutoWishlistData_d,
        handleSetWishlistBiddingNotification_d,
        wishlistNotificationFlagObject,
        handleSetMyActivityWishListStatus_d,
        isMyActivityWishlistObject,
    } = props;
    const [isLoginModal, setIsLoginModal] = React.useState<boolean>(false);
    const [status, toggleStatus] = useReducer((state) => {
        return !state;
    }, isOnWishlist);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoginModal === false) {
            handleClearAutoWishlistData_d();
        }
    }, [handleClearAutoWishlistData_d, isLoginModal])

    const handleOnClick = () => {
        if (PrembidLocalStorage.currentProfile) {
            setLoading(true);
            toggleStatus();
            let object = { ...isMyActivityWishlistObject }
            object.lotId = lotId;
            if (window?.location?.href?.includes("myActivity")) {
                handleSetMyActivityWishListStatus_d(object);
            }

            const data = {
                lotId: lotId,
                profileId: PrembidLocalStorage.currentProfile,
            };

            handleLotWishlistDataUpsert_d(data, (res) => {
                if (res.success) {
                    handleToggleWishList_d(lotId);
                    if (window?.location?.href?.toLowerCase().includes("lots")) {
                        let payload = { ...wishlistNotificationFlagObject }
                        payload.wishlistedActivity = true;
                        handleSetWishlistBiddingNotification_d(payload);
                    }
                } else {
                    ns.error("", "Failed to wishlist item, please try again.");
                    toggleStatus();
                }
                setLoading(false);
            });
        } else {
            let data = {
                lotId: lotId,
            };
            handleSetAutoWishlistData_d(data, function () {
                setIsLoginModal(true);
            });
        }
    };

    return (
        <>
            <Button
                aria-controls="simple-menu"
                size="large"
                disabled={loading}
                className={utilityClasses}
                onClick={handleOnClick}
            >
                {(loading && status) || (!loading && isOnWishlist) ? <FaHeart className="text-primary" size={size} /> : <FaRegHeart size={size} className="text-primary" />}
            </Button>
            {isLoginModal && (
                <LoginRegisterModal
                    isShown={isLoginModal}
                    hide={() => setIsLoginModal(!isLoginModal)}
                    isMobile={false}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    logoutSuccess: state.loginForm.logoutSuccess,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject,
    isMyActivityWishlistObject: state.myActivity.isMyActivityWishlistObject,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotWishlistDataUpsert_d: (
            myWishlistData: string,
            onCompletedCallback?: (res: any) => void
        ) =>
            dispatch(
                handleLotWishlistDataUpsert(myWishlistData, onCompletedCallback)
            ),
        handleToggleWishList_d: (lotId: string) => dispatch(toggleWishlist(lotId)),
        handleSetAutoWishlistData_d: (
            dataObject: any,
            onCompletedCallback?: (res: any) => void
        ) => dispatch(handleSetAutoWishlistData(dataObject, onCompletedCallback)),
        handleClearAutoWishlistData_d: () => dispatch(handleClearAutoWishlistData()),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
        handleClearWishlistBiddingNotification_d: (onCompletedCallback?: () => void) => dispatch(handleClearWishlistBiddingNotification(onCompletedCallback)),
        handleSetMyActivityWishListStatus_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetMyActivityWishListStatus(payload, onCompletedCallback)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivityWishlist);
