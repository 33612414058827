import * as React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import "./index.css";
import { SingletonHooksContainer } from "react-singleton-hook";
import createStore from "@/store";
import { NetworkProvider } from '@/components/wrappers/NetworkProvider';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { initializeLogging } from "./lib/utils/LoggingHelper";
import { SignalRContextProvider } from "prembid-shared-library-npm/signalr";

export const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <NetworkProvider>
            <SignalRContextProvider>
                <SingletonHooksContainer />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <SnackbarProvider maxSnack={5}>
                        <App />
                    </SnackbarProvider>
                </LocalizationProvider>
                <div style={{ display: "none" }}></div>
            </SignalRContextProvider>
        </NetworkProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initializeLogging();

//Testing SW cleanup