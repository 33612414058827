import { insertUrlParam } from "../utils/General";
import useHistory from "./useHistory";


const useNavigator = ({ auctionId, lotId }) => {
    const history = useHistory();

    const recordScrollPosition = () => {
        var top = String(window.scrollY);
        insertUrlParam("top", top.toString());
    };

    const btnViewLotDetailsRoute = () => {
        recordScrollPosition();
        history.push("/LotBidAndInfo/" + auctionId + "/" + lotId);
    };

    const btnBidRoute = () => {
        recordScrollPosition();
        history.push("/LotBidAndInfo/" + auctionId + "/" + lotId);
    };

    return {
        btnViewLotDetailsRoute,
        btnBidRoute,
    };
};

export { useNavigator };
