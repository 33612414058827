import { FC, memo } from 'react';
import { useBiddingTableStyles } from '../BiddingTable.styles';
import { Skeleton } from '../../../dumb/Skeleton';

type ReserveMetProps = {
    showLoading?: boolean;
    reserveMet: boolean;
    showReserve: boolean | undefined;
}

const ReserveMet: FC<ReserveMetProps> = ({ showLoading = false, reserveMet, showReserve }) => {

    const { classes: { leftRightTextContainer } } = useBiddingTableStyles();

    return <>
        {
            showLoading ? (
                <div className={leftRightTextContainer}>
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                </div>
            ) : (
                <>
                    {
                        showReserve &&
                        <div className={leftRightTextContainer}>
                            <h5 className="text-black text-left">Reserve Met</h5>
                            <h5
                                className="text-right"
                                style={{ color: reserveMet ? "#1bc943" : "#f83245" }}>
                                {reserveMet ? "Yes" : "No"}
                            </h5>
                        </div>
                    }
                </>
            )
        }
    </>
}

export default memo(ReserveMet);