import React, { ReactNode } from 'react'
import {
    Paper, TableRow, TableCell, Collapse, TableContainer, TableBody, Table,
    IconButton, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Typography,
    Menu, MenuItem, FormControl, InputLabel, Select, InputAdornment, TextField,
    Tooltip
} from '@mui/material';
import { makeStyles } from "tss-react/mui";
import { MdExpandLess, MdExpandMore, MdFilterList, MdSearch } from "react-icons/md";
import { FaTrashAlt, FaEdit, FaGlobe } from "react-icons/fa";
import clsx from 'clsx';
import EnhancedTableHead from './EnhancedTableHead'
import { GoogleMaps } from '../composite/GoogleMaps';

interface Props {
    dataModel: NestedGridDataModel | undefined,
    onEdit?: (id: any) => void,
    onDelete?: (id: any) => void,
    onAdd?: () => void,
    tableActions?: any[],
}
export interface NestedGridDataModel {
    headers: Header[],
    rows: Row[],
    headerButtons?: any
}
export interface Header {
    title: string,
    align: "left" | "center" | "right" | "justify" | "inherit" | undefined
    canFilter?: boolean
}
export interface Row {
    id: string,
    cells: Cell[],
    customData?: CustomData[],
    venueData?: VenueData,
    actionButtons?: any
}
export interface Cell {
    thumbnailUrl?: string,
    text?: string | ReactNode,
    align: "left" | "center" | "right" | "justify" | "inherit" | undefined
    element?: any
}
export interface CustomData {
    title: string,
    values?: KeyValue[]
    element?: any
}
export interface KeyValue {
    key: string,
    value: any
}
export interface VenueData {
    title?: string,
    name: string,
    description: string,
    addressLine1: string,
    addressLine2: string,
    country: string,
    state: string,
    city: string,
    zip: string,
    lat: number,
    lng: number
}

const NestedAccordianGrid: React.FC<Props> = props => {
    const {
        dataModel,
        onEdit,
        onDelete,
        onAdd,
        tableActions,
    } = props;

    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    const [selectedFilters, setSelectedFilters] = React.useState<any>({});
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [filteredDataModel, setFilteredDataModel] = React.useState<NestedGridDataModel | undefined>(dataModel);
    const [searchOpen, setSearchOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [filterLists, setFilterLists] = React.useState<any>([]);
    const [showFilters, setShowFilters] = React.useState<boolean>(false);
    const [headerButtons, setHeaderButtons] = React.useState<any>([]);
    //------------------------------------ Logic ------------------------------------//

    React.useEffect(() => {
        if (dataModel) {
            const filterLists = dataModel.headers.map((head, index) => {
                if (head.canFilter) {
                    let list = dataModel.rows.map((row) => {
                        return row.cells[index].text;
                    });
                    let distinctList = list.filter(unique);
                    return {
                        key: head.title,
                        values: distinctList
                    };
                };
            });
            const hasFilterItems = dataModel.headers.filter(head => head?.canFilter === true).length > 0;
            setShowFilters(hasFilterItems);
            setFilterLists(filterLists.filter(value => value !== undefined));
            setHeaderButtons(dataModel.headerButtons ? dataModel.headerButtons : [])
        }
    }, [dataModel]);

    React.useEffect(() => {
        setFilteredDataModel(dataModel);
        onSearchApplied()
    }, [dataModel]);

    const unique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const handleClickFilter = event => {
        setAnchorElFilter(event.target);
    };

    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };

    const handleSelect = event => {
        const filterInfo = {
            name: event.target.name,
            value: event.target.value
        };
        onFilterChanged(filterInfo);
    };

    const onFilterClick = event => {
        onFilterApplied();
    }

    const openSearch = () => setSearchOpen(true);

    const closeSearch = () => setSearchOpen(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const useStyles = makeStyles()((theme) => ({
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }));

    const { classes } = useStyles();

    function descendingComparator(a, b, orderBy) {
        const header = dataModel?.headers.filter(head => head.title === orderBy)[0];
        const index = dataModel?.headers.indexOf(header ? header : { title: '', align: 'left' });
        let valA = '';
        let valB = '';
        if (index) {
            if (a.cells[index] && b.cells[index]) {
                valA = a.cells[index].text;
                valB = b.cells[index].text;
            }
        }
        if (valB < valA) {
            return -1;
        }
        if (valB > valA) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el) => [el]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const onFilterChanged = (filterInfo) => {
        setSelectedFilters({ ...selectedFilters, [filterInfo.name]: filterInfo.value });
    }

    const onFilterApplied = () => {
        let newResultsSet = { ...dataModel } as NestedGridDataModel;

        for (const [key, value] of Object.entries(selectedFilters)) {
            if (value !== "All") {
                const header = dataModel?.headers.filter(head => head.title === key)[0];
                const headerIndex = dataModel?.headers.indexOf(header ? header : { title: '', align: 'left' });

                if (headerIndex !== undefined) {
                    const newRows = newResultsSet.rows.filter(row => row.cells[headerIndex].text === value);
                    newResultsSet.rows = newRows;
                }
            }
        };

        setFilteredDataModel(newResultsSet);
    }

    const onSearchApplied = () => {
        if (searchQuery && searchQuery.length) {
            let newResultsSet = { ...dataModel } as NestedGridDataModel;
            let newRows = [] as Row[];
            let lowerCaseSearchQuery = searchQuery.toLowerCase();

            //search rows
            dataModel?.headers.forEach((head, index) => {
                if (searchQuery && searchQuery !== '') {
                    const matchedRowCell = dataModel.rows.filter(row => row.cells[index].text?.toString().toLowerCase().includes(lowerCaseSearchQuery) === true);
                    newRows = newRows.concat(matchedRowCell);
                }
            });

            //search address
            const matchedRowVenue = dataModel?.rows.filter(row => row.venueData?.addressLine1?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.addressLine2?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.city?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.country?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.description?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.name?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.state?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.title?.toLowerCase().includes(lowerCaseSearchQuery) === true
                || row.venueData?.zip?.toLowerCase().includes(lowerCaseSearchQuery) === true);

            if (matchedRowVenue)
                newRows = newRows.concat(matchedRowVenue);

            //search custom data
            const matchedRowCustomData = dataModel?.rows.filter(row => {
                row.customData?.filter(customData => customData?.values?.filter(vals => vals?.value?.toString().toLowerCase().includes(lowerCaseSearchQuery)))
            });
            if (matchedRowCustomData)
                newRows = newRows.concat(matchedRowCustomData);

            newResultsSet.rows = newRows.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            setFilteredDataModel(newResultsSet);
        }
        else
            setFilteredDataModel(dataModel)
    };

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setSearchQuery(e.target.value)
    }

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSearchApplied();
        }
    };

    //------------------------------------ Logic ------------------------------------//

    //------------------------------------ HTML Components ------------------------------------//
    function MapData(props) {
        let mapSection = <GoogleMaps
            center={{
                lat: Number(props.venueData.lat),
                lng: Number(props.venueData.lng)
            }}
            canEdit={false}
        />

        if (props.venueData.lng == undefined || props.venueData.lat == undefined || props.venueData.lng == 0 || props.venueData.lat == 0) {
            mapSection =
                <div className="text-center my-5">
                    <FaGlobe className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90" />
                    <h6 className="font-size-xxl mb-1 mt-3 text-primary">No map data</h6>
                </div>
        }

        return mapSection;
    }

    function VenueData(props) {
        const { venueData } = props;
        let venueAccordian = <></>;
        if (venueData) {
            let title = 'Venue';
            if (venueData.title !== undefined && venueData.title !== null)
                title = venueData.title;

            venueAccordian = <Accordion>
                <AccordionSummary className="bg-neutral-primary"
                    expandIcon={<MdExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={6}>
                            <div className="p-3">
                                {'Name: ' + venueData.name}
                                <br />
                                {'Description: ' + venueData.description}
                                <br />
                                {"Address line 1: " + venueData.addressLine1}
                                <br />
                                {"Address line 2: " + venueData.addressLine2}
                                <br />
                                {"Country: " + venueData.country}
                                <br />
                                {"State: " + venueData.state}
                                <br />
                                {"City: " + venueData.city}
                                <br />
                                {"Zip: " + venueData.zip}
                            </div>
                        </Grid>
                        <Grid item sm={6} className='p-3'>
                            <MapData venueData={venueData} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>;
        }
        return venueAccordian;
    }

    function Cell(props) {
        if (props.image) {
            return <div className="avatar-icon-wrapper avatar-icon-xl">
                <div className="avatar-icon rounded">
                    <img alt="..." src={props.image} /></div></div>
        } else if (props.cellEl) {
            return props.cellEl
        }
        else if (props.text) {
            return props.text
        }
        else {
            return ''
        }
    }

    function Row(props) {
        const { row } = props;
        const actionButtons = row.actionButtons ? row.actionButtons : []
        const [open, setOpen] = React.useState(false);

        return (
            <>
                <TableRow>
                    {row.cells.map((rowData, index) => (
                        <TableCell key={index} align={rowData.align} >
                            <Cell cellEl={rowData.element} text={rowData.text} image={rowData.thumbnailUrl} />
                        </TableCell>
                    ))}
                    <TableCell align="right">
                        {row.customData || row.venueData ?
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <MdExpandLess /> : <MdExpandMore />}
                            </IconButton>
                            : <></>}
                        {actionButtons.map((btn, index) => (
                            <React.Fragment key={index}>{btn}</React.Fragment>
                        ))}
                        {onEdit !== undefined ?
                            <Tooltip arrow title="Edit" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                                <Button onClick={() => onEdit(row.id)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <FaEdit />
                                </Button>
                            </Tooltip>
                            : <></>
                        }
                        {onDelete !== undefined ?
                            <Tooltip arrow title="Delete" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                                <Button onClick={() => onDelete(row.id)} size="small" className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <FaTrashAlt />
                                </Button>
                            </Tooltip>
                            : <></>
                        }
                    </TableCell>
                </TableRow>
                {row.customData || row.venueData ?
                    <TableRow >
                        <TableCell style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={row.cells.length + 1}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                                    {row.venueData ? <VenueData venueData={row.venueData} /> : <></>}
                                    {row.customData ? row.customData.map((customData, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary className="bg-neutral-primary"
                                                expandIcon={<MdExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>{customData.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {customData.element ?
                                                    <>{customData.element}</>
                                                    :
                                                    <Grid container
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        spacing={2}>
                                                        {customData.values?.map((values, index) => (
                                                            <Grid item key={index}>
                                                                <div>{values.key + ": "}{values.value}</div>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                }

                                            </AccordionDetails>
                                        </Accordion>
                                    )) : <></>}
                                </div>
                            </Collapse>
                        </TableCell>
                        <TableCell style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }} />
                    </TableRow> : <></>}
            </>
        );
    }

    function MainTable(props) {
        const { dataModel, tableActions } = props;

        if (dataModel) {
            return <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        onAdd={onAdd}
                        dataModel={dataModel}
                        actions={tableActions}
                    />
                    <TableBody>
                        {stableSort(props.dataModel.rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <Row key={index} row={row} />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        else {
            return <></>;
        }
    }
    //------------------------------------ HTML Components ------------------------------------//

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-between bg-white">
                    <div className={clsx("search-wrapper search-wrapper--grow w-100", { 'is-active': searchOpen })}>
                        <div className="px-4 py-3 bg-white d-flex align-items-right">
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                onFocus={openSearch}
                                onBlur={closeSearch}
                                onChange={onChange}
                                value={searchQuery}
                                onKeyPress={onKeyPress}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdSearch />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-right">
                        <div className="d-flex align-items-center">
                            {headerButtons.map((btn, index) => (
                                <React.Fragment key={index}>{btn}</React.Fragment>
                            ))}
                            {showFilters &&
                                <Tooltip arrow title="Filter" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                                    <Button onClick={handleClickFilter} variant="text" className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize">
                                        <MdFilterList />
                                    </Button>
                                </Tooltip>
                            }
                        </div>
                        <Menu anchorEl={anchorElFilter}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElFilter)}
                            classes={{ list: 'p-0' }}
                            onClose={handleCloseFilter}>
                            <div className="dropdown-menu-xxl overflow-hidden p-0">
                                <div className="p-3">
                                    <Grid container spacing={4}>
                                        {filterLists.map((filter, index) => (
                                            <Grid key={index} item md={6}>
                                                <FormControl variant="outlined" size="small" className="w-100">
                                                    <InputLabel>{filter.key}</InputLabel>
                                                    <Select
                                                        name={filter.key}
                                                        id={filter.key}
                                                        fullWidth
                                                        label={filter.key}
                                                        value={selectedFilters[filter.key] ? selectedFilters[filter.key] : "All"}
                                                        onChange={handleSelect}>
                                                        <MenuItem value="All">All</MenuItem>
                                                        {filter.values.map((filterVal, index) => {
                                                            return (<MenuItem key={index} value={filterVal}>{filterVal}</MenuItem>);
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                            <div className="divider" />
                            <div className="p-3 text-center bg-secondary">
                                <Button onClick={onFilterClick} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">Filter results</Button>
                            </div>
                        </Menu>
                    </div>
                </div>
            </React.Fragment>
            <div className="mb-spacing-6-x2">
                <MainTable dataModel={filteredDataModel} tableActions={tableActions} />
            </div>
        </React.Fragment>
    )
}

export default NestedAccordianGrid;