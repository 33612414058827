import * as React from "react";
import { connect } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import ProfileSelector from "./components/ProfileSelector";
import ProfileAdd from "./components/ProfileAdd";
import DocumentAcceptance from "./components/DocumentAcceptance";
import DocumentUploads from "./components/DocumentUploads";
import { resetModal, updateState } from "./store/RegistrationModal";
import AddressCapture from "./components/AddressCapture";
import RegistrationComplete from "./components/RegistrationComplete";
import { useSetProfileAsDefault } from "prembid-shared-library-npm/networking";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { handleChangeProfile } from "../LoginForm/store/LoginForm";
import ns from '@/lib/utils/NotificationService';
import { handleBidProfilesFetch } from "../../pages/BiddingProfile/store/BiddingProfile";
import { useConfirmationDialogActivator } from "../containers/ConfirmationDialogActivator";

interface RegistrationModalProps {
    open: boolean;
    onClose: () => void;
    auctionId: string,
    settings: any,
    state: any,
    updateState: (data: any) => void,
    resetModal: () => void,
    defaultProfileId?: string,
    directToFinish?: boolean
    skipProfileSelect?: boolean,
    handleChangeProfile_d: (profileId: string) => any,
    handleBidProfileListFetch_d: () => void,
}

export const RegistrationModal: React.FC<RegistrationModalProps> = props => {
    const {
        open,
        onClose,
        auctionId,
        settings,
        state,
        updateState,
        resetModal,
        defaultProfileId,
        directToFinish = false,
        skipProfileSelect = false,
        handleChangeProfile_d,
        handleBidProfileListFetch_d
    } = props;

    const [skipProfileSelectForThisSession] = React.useState(skipProfileSelect);

    const { isLoading: isSettingProfileAsDefault, mutate: setProfileAsDefault } = useSetProfileAsDefault({
        onSuccess(data, { profileId, onCompletedCallback }) {
            handleChangeProfile_d(profileId);
            PrembidLocalStorage.defaultProfile = profileId;

            handleBidProfileListFetch_d();
            onCompletedCallback?.();
        }
    });

    const confirmationDialog = useConfirmationDialogActivator();

    const onSetAsDefault = useCallback((onCancel?: () => void) => {
        const id = state?.selectedProfileId;

        if (!id) return;

        confirmationDialog?.showConfirmationDialog({
            title: "Set as default profile",
            description: `Are you sure you want to set this as your default profile?`,
        }, {
            onOkPress() {
                setProfileAsDefault({ profileId: id })
            },
            onCancelPress: onCancel
        });
    }, [confirmationDialog, setProfileAsDefault, state?.selectedProfileId])

    const changeToDefaultPrompt = useCallback((onCompletedCallback: () => void) => {
        const id = state?.selectedProfileId;

        if (!id) return;

        ns.showDialog({
            title: 'Set as default profile',
            message: 'The current profile that you are registering with is not your default profile. Would you like to set this as your default profile?',
            onSubmit: () => {
                setProfileAsDefault({ profileId: id, onCompletedCallback })
            },
            onCancel: () => {
                onCompletedCallback();
            },
            locales: {
                cancelText: 'No',
                submitText: 'Yes'
            },
            allowCloseOnBackDropClick: false
        })
    }, [setProfileAsDefault, state?.selectedProfileId])

    useEffect(() => {
        updateState({ auctionId, setSelectedProfileId: defaultProfileId })

        return () => {
            resetModal();
        }
    }, [])

    const setStep = (step: string, canBack: boolean = true) => {
        updateState({ step, canBack })
    }

    const onBackStep = (step: string) => {
        switch (step) {
            case 'FINISH': setStep("ADDRESS_CAPTURE"); break;

            case "ADDRESS_CAPTURE":
            case "DOCUMENT_ACCEPTANCE":
            case "DOCUMENT_UPLOADS": setStep("PROFILE_SELECTOR"); break;

            case "PROFILE_SELECTOR":
            default: setStep("PROFILE_SELECTOR", false); break;
        }
    }

    const onNextStep = (step: string) => {
        switch (step) {
            case "PROFILE_SELECTOR": setStep("ADDRESS_CAPTURE"); break;

            case "ADDRESS_CAPTURE": setStep("DOCUMENT_ACCEPTANCE"); break;

            case "DOCUMENT_ACCEPTANCE": setStep("DOCUMENT_UPLOADS"); break;

            case "DOCUMENT_UPLOADS": setStep("FINISH"); break;

            case "FINISH": onClose(); break;

            case "PROFILE_ADD": setStep("PROFILE_SELECTOR"); break;

            default: setStep("PROFILE_SELECTOR", false); break;
        }
    }

    const renderContent = (step: string) => {
        switch (step) {
            case "PROFILE_ADD": return <ProfileAdd onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} />

            case "ADDRESS_CAPTURE": return <AddressCapture onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} />

            case "DOCUMENT_UPLOADS": return <DocumentUploads onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} />

            case "DOCUMENT_ACCEPTANCE": return <DocumentAcceptance onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} />

            case "FINISH": return <RegistrationComplete onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} changeToDefault={changeToDefaultPrompt} />

            case "PROFILE_SELECTOR":
            default: return <ProfileSelector onClose={handleOnClose} onNextStep={() => onNextStep(step)} onBackStep={() => onBackStep(step)} skipProfileSelect={skipProfileSelectForThisSession} onSetAsDefault={onSetAsDefault} isSettingProfileAsDefault={isSettingProfileAsDefault} />
        }
    }

    const handleOnClose = () => {
        onClose();
    }

    return (
        <Dialog open={open} onClose={(event, reason) => {
            if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
                return;

            handleOnClose();
        }} aria-labelledby="form-dialog-title" PaperProps={{
            style: { maxWidth: '95%' }
        }}
        >
            <DialogTitle id="form-dialog-title" className="text-black">Registration Wizard</DialogTitle>
            <DialogContent>
                {renderContent(state?.step)}
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateState: (data: any) => dispatch(updateState(data)),
        resetModal: () => dispatch(resetModal()),
        handleChangeProfile_d: (profileId: string) => dispatch(handleChangeProfile(profileId)),
        handleBidProfileListFetch_d: () => dispatch(handleBidProfilesFetch()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModal)