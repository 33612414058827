import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';
import { orderBy } from 'lodash';

//Action
const ERROR = 'ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const GET_BIDPROFILE_BYID_SUCCESS = 'GET_BIDPROFILE_BYID_SUCCESS';
const DELETE_BIDPROFILE_SUCCESS = 'DELETE_BIDPROFILE_SUCCESS';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    bidProfiles: undefined,
    bidProfileId: undefined,
    bidProfile: undefined,
    success: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false }
        case GET_BIDPROFILE_BYID_SUCCESS: return { ...state, error: false, bidProfiles: action.payload }
        case DELETE_BIDPROFILE_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload }
        default: return state
    }
}

//Action creator
export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const bidProfileByIdFetchSuccess = (bidProfiles: any) => {
    return { type: GET_BIDPROFILE_BYID_SUCCESS, payload: bidProfiles }
}

export const handleReload = () => {
    return { type: DELETE_BIDPROFILE_SUCCESS, payload: DELETE_BIDPROFILE_SUCCESS }
}

//Thunks
export const handleBidProfilesFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.profile.retrieveBiddingProfiles, null, null)
            .then(function (res) {
                if (res.success) {
                    var profiles = res.response;
                    var orderedListIList = orderBy(profiles.iBiddingProfiles ?? [], [x => x.firstName + x.lastName], ['asc']);
                    var orderedListCList = orderBy(profiles.cBiddingProfiles ?? [], ['companyName'], ['asc']);

                    dispatch(bidProfileByIdFetchSuccess({ ...profiles, iBiddingProfiles: orderedListIList, cBiddingProfiles: orderedListCList }))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleBidProfileDelete = (id: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {

        const deleteData = {
            Id: id
        }

        return requestClient.post(apiPaths.profile.IndividualBiddingProfileDelete, deleteData)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleBidCompanyProfileDelete = (id: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {

        const deleteCompanyData = {
            Id: id
        }

        return requestClient.post(apiPaths.profile.CompanyBiddingProfileDelete, deleteCompanyData)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

