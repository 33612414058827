import React, { FC, memo, CSSProperties } from "react";

import { usePricingDisplayStyles } from "./PricingDisplay.styles";
import { Box, Paper } from "@mui/material";
import { AuctionTypeEnum } from "@/lib/utils/AuctionTypeEnum";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { useSelector } from "react-redux";
import { CurrencyLabel } from "../../composite/CurrencyLabel";

export interface PricingDisplayProps {
    containerStyle?: CSSProperties;
    auctionType: number;
    guidePrice?: number;
    overrideGuidePriceRulesAndAlwaysHide: boolean;
    registrationStatus: number;
    openingBid: number;
    highestBid: number;
    highestBidColour?: string;
    highestBidSuffix?: string;
    fontSize?: string | number;
}

const PricingDisplay: FC<PricingDisplayProps> = ({
    auctionType,
    guidePrice,
    overrideGuidePriceRulesAndAlwaysHide,
    registrationStatus,
    openingBid,
    highestBid,
    highestBidColour,
    highestBidSuffix,
    containerStyle,
    fontSize,
}) => {
    const { settings } = useSelector((state: any) => state.settings);
    const { classes: { pricingDisplayContainer } } = usePricingDisplayStyles();

    const isTender = Number(auctionType) === Number(AuctionTypeEnum.Tender.toString());
    const isTimed = Number(auctionType) === Number(AuctionTypeEnum.Timed.toString());
    const isLoggedIn = !!PrembidLocalStorage.currentUser?.userId;
    const isRegistered = registrationStatus === 1;
    const hasGuidePrice = guidePrice && guidePrice > 0;
    const hasOpeningBid = openingBid >= 0;
    const hasHighestBid = highestBid > 0;
    const pricingDisplayHasContent = hasGuidePrice || (isTimed && hasOpeningBid);

    const getPaperComponent = (showGuidePrice) => {
        return pricingDisplayHasContent ? (
            <Paper
                className={`${pricingDisplayContainer} text-primary`}
                style={containerStyle}
            >
                {showGuidePrice && (
                    <CurrencyLabel
                        style={{ fontSize }}
                        value={guidePrice}
                        prefixString="Guide Price: "
                    />
                )}

                {isTimed && hasOpeningBid && (
                    <CurrencyLabel
                        style={{ fontSize }}
                        textColour={hasHighestBid ? highestBidColour : settings?.Styles?.Primary}
                        value={hasHighestBid ? highestBid : openingBid}
                        prefixString={hasHighestBid ? "Highest Bid: " : "Opening Bid: "}
                        suffixString={hasHighestBid ? highestBidSuffix : ""}
                    />
                )}
            </Paper>
        ) : (
            <div className={pricingDisplayContainer} style={containerStyle} />
        );
    };

    if (isTender) {
        if (isLoggedIn && !isRegistered && !overrideGuidePriceRulesAndAlwaysHide) {
            return getPaperComponent(hasGuidePrice);
        }
        if (!isLoggedIn && !overrideGuidePriceRulesAndAlwaysHide) {
            return getPaperComponent(hasGuidePrice);
        }

        return getPaperComponent(false);
    }

    if (isLoggedIn) {
        if (isRegistered) {
            return getPaperComponent(false);
        }
        if (!overrideGuidePriceRulesAndAlwaysHide) {
            return getPaperComponent(hasGuidePrice);
        }
        return getPaperComponent(false);
    }

    if (!overrideGuidePriceRulesAndAlwaysHide) {
        return getPaperComponent(hasGuidePrice);
    }

    return getPaperComponent(false);
};

export default memo(PricingDisplay);
