import React, { FC, memo, CSSProperties } from "react";

import { useTooltipInfoContentStyles } from "./TooltipInfoContent.styles";
import { AuctionLotStatusEnum } from "@/lib/utils/AuctionLotStatusEnum";
import { AuctionTypeEnum } from "@/lib/utils/AuctionTypeEnum";
import { List, ListItem } from "@mui/material";

export interface TooltipInfoContentProps {
    containerStyle?: CSSProperties;
    isActive: boolean;
    now: Date,
    start: Date,
    end: Date;
    hasExtensions: boolean;
    auctionType: AuctionTypeEnum;
    settings: any;
    validating: boolean;
}

const TooltipInfoContent: FC<TooltipInfoContentProps> = ({
    auctionType,
    isActive,
    now,
    start,
    end,
    hasExtensions,
    settings,
    validating
}) => {
    //Updated npm
    const classes = useTooltipInfoContentStyles();

    const placeholderText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tender" : "bid";
    const placeholderActioningText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tendering" : "bidding";

    let lotStatus = AuctionLotStatusEnum["Unavailable"];

    if (!isActive) lotStatus = AuctionLotStatusEnum["Unavailable"];

    if (now < start) lotStatus = AuctionLotStatusEnum.Awaiting;

    if (now > end) lotStatus = AuctionLotStatusEnum.Completed;

    if (start < now && now < end) {
        if (hasExtensions) {
            lotStatus = AuctionLotStatusEnum.Extended;
        } else {
            lotStatus = AuctionLotStatusEnum.Open;
        }
    }

    if (validating) lotStatus = AuctionLotStatusEnum.Validating;

    return (
        <List>
            <ListItem>
                <h5 className="text-primary font-weight-bold">
                    {AuctionLotStatusEnum[lotStatus.toString()]} -{" "}
                    {lotStatus === AuctionLotStatusEnum["Unavailable"] && (
                        <span className="text-black font-weight-normal">
                            {"This " +
                                settings.LotName.toLowerCase() +
                                " is no longer available and " +
                                placeholderText +
                                "s cannot be submitted on it"}
                        </span>
                    )}
                    {lotStatus === AuctionLotStatusEnum.Awaiting && (
                        <span className="text-black font-weight-normal">
                            {"This " +
                                settings.LotName.toLowerCase() +
                                " hasn't opened yet, but you need to register if you want to " +
                                placeholderText +
                                " when it does"}
                        </span>
                    )}
                    {lotStatus === AuctionLotStatusEnum.Open && (
                        <span className="text-black font-weight-normal">
                            {"This " +
                                settings.LotName.toLowerCase() +
                                " is open for you to " +
                                placeholderText +
                                " on " +
                                "if you have registered and been approved"}
                        </span>
                    )}
                    {lotStatus === AuctionLotStatusEnum.Extended && (
                        <span className="text-black font-weight-normal">
                            {"This " +
                                settings.LotName.toLowerCase() +
                                " has been extended due to " +
                                placeholderActioningText +
                                " activity, " +
                                placeholderActioningText +
                                " is still open on certain " +
                                settings.LotName.toLowerCase() +
                                "s"}
                        </span>
                    )}
                    {lotStatus === AuctionLotStatusEnum.Completed && (
                        <span className="text-black font-weight-normal">
                            {"This " +
                                settings.LotName.toLowerCase() +
                                " has completed and " +
                                placeholderActioningText +
                                " is closed"}
                        </span>
                    )}
                    {lotStatus === AuctionLotStatusEnum.Validating && (
                        <span className="text-black font-weight-normal">
                            {"Validating the status of the " + settings.LotName.toLowerCase()}
                        </span>
                    )}
                </h5>
            </ListItem>
            <ListItem>
                <h5 className="text-primary font-weight-bold">
                    STC -{" "}
                    <span className="text-black font-weight-normal">
                        {"Subject to Confirmation, conditions need to be met in order for the " +
                            settings.LotName.toLowerCase() +
                            " to be sold"}
                    </span>
                </h5>
            </ListItem>
            <ListItem>
                <h5 className="text-primary font-weight-bold">
                    Buyers Commission -{" "}
                    <span className="text-black font-weight-normal">
                        {"Commission payable by the buyer on top of the purchase price"}
                    </span>
                </h5>
            </ListItem>
            <ListItem>
                <h5 className="text-primary font-weight-bold">
                    Reserve -{" "}
                    <span className="text-black font-weight-normal">
                        {"Has the minimum price for the " +
                            settings.LotName.toLowerCase() +
                            " been met?"}
                    </span>
                </h5>
            </ListItem>
        </List>
    );
};

export default memo(TooltipInfoContent);
