import { Card, Grid } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBidding } from "prembid-shared-library-npm/hooks";
import { useSignalRConnectionStatus } from 'prembid-shared-library-npm/signalr';
import { AuctionTypeEnum } from 'prembid-shared-library-npm/types';
import BidButtonContainer from './sub-components/BidButtonContainer';
import CustomBidButtonContainer from './sub-components/CustomBidButtonContainer';
import MaxBidButtonContainer from './sub-components/MaxBidButtonContainer';
import TenderBidButtonContainer from './sub-components/TenderBidButtonContainer';
import GuidePrice from './sub-components/GuidePrice';
import ReserveMet from './sub-components/ReserveMet';
import TableOfBids from './sub-components/TableOfBids';
import PaddleNumber from './sub-components/PaddleNumber';
import MinimumIncrement from './sub-components/MinimumIncrement';
import { uniqueId } from 'lodash';
import ns from '@/lib/utils/NotificationService';
import CountdownTimerContainer from './sub-components/CountdownTimerContainer';
import Heading from './sub-components/Heading';
import { RootState } from '../../../store/Store';
import { setLotClosingValidating } from '../../../pages/LotBidAndInfo/store/LotBidAndInfo';
import ParticipateButton from '../../Buttons/ParticipateButton';

interface BiddingTableProps {
    auctionId: string;
    lotId: string;
    showCompactView: boolean;
}

const BiddingTable: FC<BiddingTableProps> = ({ auctionId, lotId, showCompactView }) => {

    const bidTableRef = React.useRef<HTMLTableElement>(null);
    const dispatch = useDispatch();

    const profileId = useSelector((state: RootState) => state.loginForm.changeProfileId);
    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);
    const { settings } = useSelector((state: RootState) => state.settings);

    const scrollToTop = useCallback(() => {
        bidTableRef.current?.scrollTo({ top: 0 });
    }, []);

    const {
        auction,
        lot,
        now,
        maximumBidAmount,
        tenderAmount,
        paddleNumber,
        registrationStatus,
        soldBanner,
        locked,
        startingPrice,
        validating,
        countDownTimer,
        topBidder,
        reserveMet,
        minimumIncrement,
        loading,
        loadingRegisteredProfile,
        finalizingText,
        lockedText,
        unlockedText,
        refresh,
        handleBidSubmittedSuccess,
        handleMaxBidSubmittedSuccess,
        handleTenderSubmittedSuccess,
        lotStatusFailureCount,
        error,
        end,
        start,
        tableRowData,
        isExtended,
    } = useBidding({
        auctionId,
        lotId,
        profileId,
        currentDateTimeOffset,
        locales: {
            lotName: settings.LotName,
        },
        onScrollToTop: scrollToTop,
    });

    useEffect(() => {
        if (bidTableRef.current && tableRowData?.length) {
            scrollToTop();
        }
    }, [tableRowData, scrollToTop]);

    useSignalRConnectionStatus({
        onConnect: () => {
            refresh();
        },
    });

    useEffect(() => {
        if (locked?.isLocked === false && unlockedText) ns.information(uniqueId(), unlockedText);
        else if (locked?.isLocked === true && lockedText) ns.information(uniqueId(), lockedText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locked]);

    useEffect(() => {
        if (error) ns.error(uniqueId(), error);
    }, [error]);

    useEffect(() => {
        dispatch(setLotClosingValidating({
            lotId: lotId,
            validating: validating,
            validatingText: finalizingText,
            validatingErrorText: lotStatusFailureCount > 3 ? `Unable to connect, retrying(${lotStatusFailureCount - 3})...` : undefined,
            soldBanner: {
                showBanner: soldBanner?.showBanner,
                bannerText: soldBanner?.bannerText
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validating, soldBanner?.showBanner]);

    return <>
        {
            auction?.auctionType?.toString() !== AuctionTypeEnum.Streamed.toString() &&
            <Card raised={true} className="p-3">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}>
                    {
                        !showCompactView && <>
                            <Grid item xs={12}>
                                <Heading showLoading={loading || loadingRegisteredProfile} auctionType={auction?.auctionType} />
                            </Grid>

                            <Grid item xs={12}>
                                <CountdownTimerContainer
                                    showLoading={loading || loadingRegisteredProfile}
                                    auctionType={auction?.auctionType}
                                    isActive={lot?.isActive}
                                    validating={validating}
                                    isExtended={isExtended}
                                    isWinning={topBidder?.bidder === paddleNumber}
                                    profileId={profileId}
                                    start={start}
                                    end={end}
                                    now={now}
                                    countDownTimer={countDownTimer} />
                            </Grid>

                            <Grid item xs={12}>
                                <ReserveMet showLoading={loading || loadingRegisteredProfile} reserveMet={reserveMet} showReserve={lot?.showReserve} />
                            </Grid>

                            <Grid item xs={12}>
                                <GuidePrice showLoading={loading || loadingRegisteredProfile} guidePrice={lot?.guidePrice} registrationStatus={registrationStatus} />
                            </Grid>
                        </>
                    }

                    {
                        auction?.auctionType !== AuctionTypeEnum.Tender ? (<>
                            {
                                !showCompactView && <>
                                    <Grid item xs={12}>
                                        <PaddleNumber showLoading={loading || loadingRegisteredProfile} paddleNumber={paddleNumber} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TableOfBids showLoading={loading || loadingRegisteredProfile} tableRowData={tableRowData || []} showPaddleNumber={lot?.showPaddleNumber || false} paddleNumber={paddleNumber} bidTableRef={bidTableRef} />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <MinimumIncrement showLoading={loading || loadingRegisteredProfile} minimumIncrement={minimumIncrement} />
                            </Grid>

                            {
                                auction?.auctionType?.toString() === AuctionTypeEnum.Timed.toString() && (validating || (end && end > now)) && registrationStatus === 1 && <>
                                    <Grid item xs={12}>
                                        <BidButtonContainer
                                            showLoading={loading || loadingRegisteredProfile}
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            increment={minimumIncrement}
                                            topBidderAmount={topBidder?.amount}
                                            startingPrice={startingPrice}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleBidSubmittedSuccess}
                                        />
                                        <CustomBidButtonContainer
                                            showLoading={loading || loadingRegisteredProfile}
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleBidSubmittedSuccess}
                                        />
                                        <MaxBidButtonContainer
                                            showLoading={loading || loadingRegisteredProfile}
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            increment={minimumIncrement}
                                            topBidderAmount={topBidder?.amount}
                                            startingPrice={startingPrice}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleMaxBidSubmittedSuccess}
                                            maximumBidAmount={maximumBidAmount}
                                        />
                                    </Grid>
                                </>
                            }
                        </>) : (
                            <>
                                {
                                    (validating || (end && end > now)) && registrationStatus === 1 &&
                                    <Grid item xs={12}>
                                        <TenderBidButtonContainer
                                            showLoading={loading || loadingRegisteredProfile}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleTenderSubmittedSuccess}
                                            tenderAmount={tenderAmount}
                                        />
                                    </Grid>
                                }
                            </>
                        )
                    }

                    <Grid item xs={12}>
                        <ParticipateButton
                            showLoading={loading || loadingRegisteredProfile}
                            auctionId={auctionId}
                            auctionType={auction?.auctionType}
                        />
                    </Grid>
                </Grid>
            </Card>
        }
    </>
};

export default BiddingTable;