import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';

export const downloadFile = (url: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.downloadFile(apiPaths.document.downloadSecureFile, { url: encodeURIComponent(url) }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                if (onCompletedCallback) onCompletedCallback(null);
            })
    }
}