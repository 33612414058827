import React from 'react'

import { connect } from 'react-redux';
import { Outlet } from 'react-router';

const BlankLayout: React.FC = props => {
    return (
        <Outlet />
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps, null)(BlankLayout);
