import { FC, memo, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { useFetchLotContacts } from "prembid-shared-library-npm/networking";
import LotContactDisplay from "./sub-components/LotContactDisplay";
import { useLotContactListStyles } from "./LotContactList.styles";
import { useDispatch } from "react-redux";
import { setLotContact } from "../../../pages/LotBidAndInfo/store/LotBidAndInfo";
import { Skeleton } from "../../dumb/Skeleton";

export interface LotContactListContainerProps {
    lotId: string;
}

const LotContactListContainer: FC<LotContactListContainerProps> = ({ lotId }) => {

    const { classes } = useLotContactListStyles();
    const dispatch = useDispatch();

    const { data: contacts, isFetching: isFetchingContacts } = useFetchLotContacts(
        { lotId },
        {
            cacheTime: 0,
            onSuccess(response) {
                dispatch(setLotContact({
                    firstName: response[0]?.firstName,
                    lastName: response[0]?.lastName,
                    email: response[0]?.email,
                    phoneNumber: response[0]?.phoneNumber,
                    imageUrl: ""
                }));
            }
        },
    );

    return (
        <>
            {
                isFetchingContacts ? (
                    <Card raised={true}>
                        <Skeleton animation="wave" variant="rectangular" height="150px" width="100%" />
                    </Card>
                ) : (
                    <Box className={classes.container}>
                        {
                            contacts?.map((item) => {
                                return <div className="mb-4"><LotContactDisplay {...item} /></div>
                            })
                        }
                    </Box>
                )
            }
        </>
    );
};

export default LotContactListContainer;