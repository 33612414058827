import { CSSProperties, FC, useEffect, useRef, useState, memo } from 'react';
import GoogleMapReact from 'google-map-react';
import MapControl from './MapControl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/Store';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Card } from '@mui/material';
import { Skeleton } from '../../dumb/Skeleton';

const coloredMarker = (maps: any) => {
    return {
        path: "M15 13 15 8 16 8 12 5 8 8 9 8 9 13 11 13 11 11 13 11 13 13Zm-3-10.984Q14.906 2.016 16.945 4.055T18.984 9Q18.984 10.453 18.257 12.328T16.499 15.844 14.46 18.914 12.749 21.187L11.999 21.984Q11.718 21.656 11.249 21.117T9.561 18.961 7.428 15.82 5.764 12.375 5 9Q5 6.094 7.039 4.055T11.984 2.016ZM11.25 10 11.25 9 10.25 9 10.25 10Zm1.5-1L13.75 9 13.75 10 12.75 10Z",
        fillColor: "red",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new maps.Point(14, 20),
    }
};

interface GoogleMapsProps {
    canEdit?: boolean,
    center?: {
        lat: number,
        lng: number
    },
    style?: CSSProperties,
    showLabels?: boolean,
    allowZoom?: boolean,
    address?: string,
    showLoading?: boolean;
    displayLocation?: boolean;
}

const GoogleMaps: FC<GoogleMapsProps> = props => {
    const {
        canEdit = true,
        center,
        style,
        showLabels = true,
        allowZoom = true,
        address,
        showLoading = false,
        displayLocation = true
    } = props;

    const [mapState, setMapState] = useState<any>();
    const [primaryLocation, setPrimaryLocation] = useState<any>(undefined);

    const { settings } = useSelector((state: RootState) => state.settings);

    const primaryMarker = useRef<any>(null);

    useEffect(() => {
        if (!primaryLocation && center && mapState?.googlemaps) {
            handleOnPlacesChanged('clear');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [center, primaryLocation, mapState]);

    const createMapOptions = (maps: any) => {
        let options: any = {
            mapTypeId: "roadmap",
            keyboardShortcuts: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: allowZoom,
            zoomControlOptions: {
                position: 1
            },
        };

        if (!canEdit) {
            options = {
                ...options,
                navigationControl: false,
            }
        }

        return options;
    }

    const handleApiLoaded = (map, maps) => {
        primaryMarker.current = new maps.Marker({ map });

        if (center) {
            primaryMarker.current.setPosition(center);
            if (showLabels) primaryMarker.current.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
            primaryMarker.current.setIcon(coloredMarker(maps));
            primaryMarker.current.setVisible(true);
        }

        setMapState({
            apiReady: true,
            map: map,
            googlemaps: maps
        });
    };

    const handleOnPlacesChanged = (place: any) => {
        if (place === 'clear') {
            if (center?.lng && center?.lat) {
                primaryMarker.current?.setPosition(center);
                if (showLabels) primaryMarker.current?.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
                primaryMarker.current?.setIcon(coloredMarker(mapState.googlemaps));
                primaryMarker.current?.setVisible(true);
            }
            else {
                primaryMarker.current?.setVisible(false);
            }

            mapState.map.setCenter(center?.lat && center?.lng ? center : center);
            mapState.map.setZoom(center?.lat && center?.lng ? 15 : 5);
            mapState.map.panBy(0, canEdit ? -60 : -30);
            setPrimaryLocation(undefined);

            return;
        }

        if (!place?.geometry) return; //No place selected

        if (place.geometry.viewport) {
            mapState.map.fitBounds(place.geometry.viewport);
            mapState.map.panBy(0, canEdit ? -60 : -30);
        } else {
            mapState.map.setCenter(place.geometry.location);
            mapState.map.setZoom(10);
            mapState.map.panBy(0, canEdit ? -60 : -30);
        }

        primaryMarker.current.setPosition(place.geometry.location);
        if (showLabels) primaryMarker.current.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
        primaryMarker.current.setIcon(coloredMarker(mapState.googlemaps));
        primaryMarker.current.setVisible(true);

        let address: any = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
        };

        place.address_components.forEach(x => {
            if (x.types.includes('street_number')) address.addressLine1 = x.long_name;
            if (x.types.includes('route')) address.addressLine1 += ' ' + x.long_name;
            if (x.types.includes('locality')) address.city = x.long_name;
            if (x.types.includes('administrative_area_level_1')) address.state = x.long_name;
            if (x.types.includes('country')) address.country = x.long_name;
            if (x.types.includes('postal_code')) address.zip = x.long_name;
        });

        setPrimaryLocation({ searchString: place.formatted_address, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng(), address })
    }

    return <>
        {
            showLoading ? (
                <>
                    <Skeleton animation="wave" variant="text" component="h3" width="100%" className="mb-2" />
                    <Card raised={true}>
                        <Skeleton animation="wave" variant="rectangular" height="400px" width="100%" />
                    </Card>
                </>
            ) : (
                    <>
                        {
                            displayLocation &&
                            <>
                                {
                                    address &&
                                    <div className="d-flex align-items-center mb-2">
                                        <h5 className="font-weight-bold d-flex align-items-center" style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>
                                            <FaMapMarkerAlt className="mr-1" color="red" size="25" />
                                            {address}
                                        </h5>
                                    </div>
                                }

                                {
                                    center && center?.lat != 0 && center?.lng != 0 &&
                                    <Card raised={true}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{
                                                key: settings?.GoogleMapsApiKey,
                                                libraries: ['places']
                                            }}
                                            keyboardShortcuts={false}
                                            streetViewControl={false}
                                            fullscreenControl={false}
                                            mapTypeControl={false}
                                            zoomControl={true}
                                            options={createMapOptions}
                                            defaultCenter={center}
                                            defaultZoom={5}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                            style={{ width: '100%', height: 'calc(100%)', position: 'relative', ...(style || {}) }}>

                                            {
                                                mapState?.apiReady && canEdit &&
                                                <MapControl map={mapState?.map} controlPosition={mapState?.googlemaps?.ControlPosition?.TOP_LEFT} style={{ width: '100%' }}
                                                    locationState={{ primaryLocation: true }}>
                                                </MapControl>
                                            }
                                        </GoogleMapReact>
                                    </Card>
                                }
                            </>
                        }
                </>
            )
        }
    </>
}

export default memo(GoogleMaps);
