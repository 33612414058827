import React, { FC, memo, CSSProperties } from "react";
import { useLotFeaturesStyles } from "./LotFeatures.styles";
import { Typography } from "@mui/material";

export interface LotFeaturesProps {
    containerStyle?: CSSProperties;
    lotFeatures?: { key: string; value: string; order: number }[]; // Adjust type accordingly
}

const LotFeatures: FC<LotFeaturesProps> = ({
    lotFeatures = [],
}) => {
    const {
        classes: { lotFeaturesContainer, lotFeatureContainer, lotFeatureText },
    } = useLotFeaturesStyles();

    return (
        <div className={lotFeaturesContainer}>
            {lotFeatures
                .sort((a, b) => a.order - b.order) // Sort by 'order' property
                .slice(0, 4) // Get the first 4 items
                .map((lotFeature, index) => (
                    <div className={lotFeatureContainer} key={`lotF-${index}`}>
                        <Typography className={lotFeatureText} style={{ color: "#5a5a5a" }}>
                            {`${lotFeature.key}`}
                        </Typography>
                        <Typography
                            className={lotFeatureText}
                            style={{ color: "#5a5a5a" }}
                        >
                            {lotFeature.value.length >= 15
                                ? lotFeature.value.slice(0, 15) + "..."
                                : lotFeature.value}
                        </Typography>
                    </div>
                ))}
        </div>
    );
};

export default memo(LotFeatures);
