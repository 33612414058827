import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    List,
    ListItem,
    Grid,
} from '@mui/material';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { DiApple, DiAndroid } from "react-icons/di";
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal";
import useHistory from '../../../lib/hooks/useHistory';
import { Version } from '../../composite/Version';

interface Props {
    settings: any
}

const Footer: React.FC<Props> = props => {

    const {
        settings
    } = props;

    const history = useHistory();

    const [poweredBySrc, setPoweredBySrc] = React.useState<any>(undefined);

    const [privacyPolicyModalState, setPrivacyPolicyModalState] = React.useState<any>({ open: false, url: undefined });

    React.useEffect(() => {
        if (settings) {
            setPoweredBySrc(settings?.ProductLogoUrl);
        }
    }, [settings])

    const howItWorksRedirect = () => {
        history.push('/howitworks')
    }

    const twitterSocial = () => {
        window.open(settings.TwitterUrl);
    }

    const facebookSocial = () => {
        window.open(settings.FacebookUrl);
    }

    const instagramSocial = () => {
        window.open(settings.InstagramUrl);
    }

    const linkedInSocial = () => {
        window.open(settings.LinkedInUrl);
    }

    const contactUs = () => {
        window.open(settings.ContactUsUrl);
    }

    const aboutUs = () => {
        window.open(settings.AboutUsUrl);
    }

    const iosApp = () => {
        window.open(settings.IOSAppUrl);
    }

    const androidApp = () => {
        window.open(settings.AndroidAppUrl);
    }

    const ourWebsite = () => {
        window.open(settings.ProductWebsite);
    }

    const handlePrivacyLinkClicked = () => {
        setPrivacyPolicyModalState({ open: true, url: settings?.ClientPrivacyPolicy ? 'ClientPrivacyPolicy' : undefined });
    }

    const handleTermsOfUseClicked = () => {
        setPrivacyPolicyModalState({ open: true, url: 'ClientTermsOfUse', title: 'Terms Of Use' });
    }

    function changeHoverTextColor(e) {
        if (settings.Styles.Primary === "#000000") {
            e.target.style.color = settings?.Styles?.OverrideFooterSubTextHoverColor ? settings?.Styles?.OverrideFooterSubTextHoverColor : "#ffffff";
        } else {
            e.target.style.color = settings?.Styles?.OverrideFooterSubTextHoverColor ? settings?.Styles?.OverrideFooterSubTextHoverColor : settings.Styles.Primary;
        }
    }

    function changeUnhoverTextColor(e) {
        e.target.style.color = settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff";
    }

    return (
        <div>
            {privacyPolicyModalState.open && <PrivacyPolicyModal
                onSubmit={() => null}
                isDialogOpened={privacyPolicyModalState.open}
                handleCloseDialog={() => setPrivacyPolicyModalState({ open: false, url: undefined })}
                isViewOnlyMode={true}
                overrideUrl={privacyPolicyModalState.url}
                overrideTitle={privacyPolicyModalState.title}
            />}

            <div className="bg-composed-wrapper py-5" style={{ background: settings?.Styles?.OverrideFooterBackgroundColor ? settings?.Styles?.OverrideFooterBackgroundColor : "#2b2b2b" }}>
                <Container className="pt-1" z-over="true">

                    <h5 className="text-center" style={{ color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>{settings.VendorName}</h5>
                    <h4 className="text-center mb-5" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>{settings.VendorSlogan}</h4>

                    <Grid container spacing={3}>
                        <Grid item sm={3}>
                            <div className="my-4 my-xl-0">
                                <h4 className="b-2" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                                    Navigation
                                </h4>
                                <List component="div" className="nav-transparent-alt flex-column">

                                    <ListItem onClick={howItWorksRedirect} onMouseOver={changeHoverTextColor} onMouseLeave={changeUnhoverTextColor} className="px-0 d-block d-xl-flex " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>How it Works</ListItem>

                                </List>
                            </div>
                        </Grid>
                         <Grid item sm={3}>
                            <div className="my-4 my-xl-0">
                                <h4 className="mb-2" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                                    Support
                                </h4>
                                <List className="nav-transparent-alt flex-column">

                                    <ListItem className="px-0 d-block d-xl-flex" style={{ color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}><h6 className=""><HiOutlineMail /> {settings.VendorEmailAddress}</h6></ListItem>

                                </List>
                            </div>
                        </Grid> 
                        <Grid item sm={3}>
                            {
                                (settings.AboutUsUrl || settings.ContactUsUrl || settings.AndroidAppUrl || settings.IOSAppUrl) &&
                                <div className="my-4 my-xl-0">
                                    <h4 className="mb-2" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                                        Services
                                    </h4>
                                    <List className="nav-transparent-alt flex-column">
                                        {
                                            settings.AboutUsUrl &&
                                            <ListItem onClick={aboutUs} onMouseOver={changeHoverTextColor} onMouseLeave={changeUnhoverTextColor} className="px-0 d-block d-xl-flex" style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>About us</ListItem>

                                        }

                                        {
                                            settings.ContactUsUrl &&
                                            <ListItem onClick={contactUs} onMouseOver={changeHoverTextColor} onMouseLeave={changeUnhoverTextColor} className="px-0 d-block d-xl-flex " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>Contact us</ListItem>
                                        }

                                        {
                                            (settings.AndroidAppUrl || settings.IOSAppUrl) &&
                                            <List className="nav-transparent-alt d-flex justify-content-start" style={{ marginTop: '-10px' }}>

                                                <ListItem className="px-0 mr-3 d-block d-xl-flex" style={{ color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>Download app</ListItem>

                                                <ListItem onClick={androidApp} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                    <span className="btn-wrapper--icon"><DiAndroid /></span>
                                                </ListItem>
                                                <ListItem onClick={iosApp} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                    <span className="btn-wrapper--icon"><DiApple /></span>
                                                </ListItem>

                                            </List>
                                        }
                                    </List>
                                </div>
                            }
                        </Grid>
                        <Grid item sm={3}>
                            {
                                (settings.TwitterUrl || settings.FacebookUrl || settings.InstagramUrl || settings.LinkedInUrl) &&
                                <div className="my-4 my-xl-0">
                                    <h4 className="mb-2" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                                        Connect
                                    </h4>
                                    <List className="nav-transparent-alt flex-column">

                                        <ListItem className="px-0 d-block d-xl-flex" style={{ color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}><h6>Stay up to date with our latest updates via our socials!</h6></ListItem>

                                    </List>
                                    <List className="nav-transparent-alt d-flex justify-content-start" style={{ marginTop: '-10px' }}>
                                        {
                                            settings.TwitterUrl &&
                                            <ListItem onClick={twitterSocial} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                <span className="btn-wrapper--icon"><FaTwitter /></span>
                                            </ListItem>
                                        }

                                        {
                                            settings.FacebookUrl &&
                                            <ListItem onClick={facebookSocial} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                <span className="btn-wrapper--icon"><FaFacebook /></span>
                                            </ListItem>
                                        }

                                        {
                                            settings.InstagramUrl &&
                                            <ListItem onClick={instagramSocial} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                <span className="btn-wrapper--icon"><FaInstagram /></span>
                                            </ListItem>
                                        }

                                        {
                                            settings.LinkedInUrl &&
                                            <ListItem onClick={linkedInSocial} onMouseOver={changeHoverTextColor} onMouseOut={changeUnhoverTextColor} className="px-0 mr-3 " style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff" }}>
                                                <span className="btn-wrapper--icon"><FaLinkedinIn /></span>
                                            </ListItem>
                                        }
                                    </List>
                                </div>
                            }
                        </Grid>
                    </Grid>

                    <div className="divider border-1 d-md-block rounded-circle border-light opacity-5 mx-auto mx-xl-0 mb-3 w-100" />

                    <div className="d-block d-md-flex align-items-center justify-content-between">
                        <span className="btn-wrapper--icon">
                            <h6 style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>Powered by <img style={{ cursor: 'pointer', maxWidth: '200px' }} src={poweredBySrc} onClick={ourWebsite} /></h6>
                        </span>
                        <List className="nav-transparent-alt text-nowrap d-flex justify-content-center text-white pr-0">
                            <ListItem onClick={handlePrivacyLinkClicked} onMouseOver={changeHoverTextColor} onMouseLeave={changeUnhoverTextColor} style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff", marginTop: '-15px', paddingRight: 0, paddingLeft: 0 }}>Privacy Policy</ListItem>
                            {settings?.ClientTermsOfUse && <ListItem onClick={handleTermsOfUseClicked} onMouseOver={changeHoverTextColor} onMouseLeave={changeUnhoverTextColor} style={{ cursor: 'pointer', color: settings?.Styles?.OverrideFooterSubTextColor ? settings?.Styles?.OverrideFooterSubTextColor : "#ffffff", marginTop: '-15px', paddingRight: 0, paddingLeft: 0, marginLeft: 20 }}>Terms Of Use</ListItem>}
                        </List>
                    </div>

                    <div className="d-block d-md-flex align-items-center justify-content-between">
                        <div className="text-center d-block mb-md-0" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                            Copyright &copy; {"R and R Tech (Pty) Ltd - All Rights Reserved"}
                        </div>
                        <div className="text-center d-block mb-md-0" style={{ color: settings?.Styles?.OverrideFooterTextColor ? settings?.Styles?.OverrideFooterTextColor : "#ffffff" }}>
                            <Version />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
