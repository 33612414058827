import React, { useMemo, useState } from "react";
import {
    InputAdornment,
    Grid,
    DialogContentText,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import { clearApiResponses } from "@/pages/Bids/store/Bidding";
import { connect } from "react-redux";
import { RegisteredUserStatusEnum } from "@/lib/utils/RegisteredUserStatusEnum";
import { submitBid } from "@/pages/Bids/store/Bidding";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";
import ns from "@/lib/utils/NotificationService";
import clsx from "clsx";
import { sortBy, sum } from "lodash";
import { handleSetWishlistBiddingNotification } from "../../pages/MyActivity/store/MyActivity";
import { LoadingButton } from "@mui/lab";
import { CurrencyInput } from "../dumb/currency/CurrencyInput";
import { CurrencyLabel } from "../composite/CurrencyLabel";
import { CurrencyComponent } from "../dumb/currency/CurrencyComponent";

interface Props {
    submitBid_d: (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback: (res: any) => void) => void;
    clearResponses_d: () => void;
    auctionId: string;
    lotId: string;
    validateResult: any;
    success: boolean;
    bidError: boolean;
    bidErrorText: string;
    profileId: string;
    loginSuccess: boolean;
    logoutSuccess: boolean;
    onBidSuccessfullySubmitted: (customAmount: number, paddleNumber: string) => void;
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    wishlistNotificationFlagObject: any,
    biddingCalculation: any;
    commission: any;
    settings: any;
}

const BidButton: React.FC<Props> = (props) => {
    const {
        clearResponses_d,
        submitBid_d,
        auctionId,
        lotId,
        validateResult,
        success,
        bidError,
        bidErrorText,
        profileId,
        loginSuccess,
        logoutSuccess,
        onBidSuccessfullySubmitted,
        handleSetWishlistBiddingNotification_d,
        wishlistNotificationFlagObject,
        biddingCalculation,
        commission,
        settings,
    } = props;

    const [isBusy, setIsBusy] = React.useState(false);
    const [vatPercentage, setVatPercentage] = React.useState<number>(15);
    const [bid, setBid] = React.useState({
        status: RegisteredUserStatusEnum.NotRegistered,
        reason: "",
    });

    const [customAmountField, setCustomAmountField] = React.useState<any>({
        customAmount: 0,
    });
    const [confirmationModal, setConfirmationModal] = React.useState<any>({
        open: false,
        id: "",
    });

    const onCustomAmountChange = (e: { target: { id: any; value: string } }) => {
        setCustomAmountField({
            ...customAmountField,
            [e.target.id]: e.target.value,
        });
    };

    const submitBid = () => {
        showConfirmationModal(true, "");
    };

    React.useEffect(() => {
        if (success) {
            setCustomAmountField({ customAmount: 0 });
            clearResponses_d();
            setIsBusy(false);
        }
    }, [success]);

    React.useEffect(() => {
        if (bidError) {
            clearResponses_d();
            setIsBusy(false);
        }
    }, [bidError]);

    React.useEffect(() => {
        if (loginSuccess) {
        }
    }, [loginSuccess]);

    React.useEffect(() => {
        if (validateResult && validateResult.length > 0) {
            const result = validateResult.filter(
                (result) => result.auctionId === auctionId
            )[0];
            if (result) {
                if (
                    result.status === RegisteredUserStatusEnum.Approved &&
                    PrembidLocalStorage.currentUser
                ) {
                } else {
                    setBid({
                        ...bid,
                        status: result.status,
                        reason: result.reason,
                    });
                }
            }
        }
    }, [validateResult]);

    const onConfirmationModalClosed = () => {
        showConfirmationModal(false, "");
    };

    const onConfirmationModalOk = () => {
        showConfirmationModal(false, "");

        setIsBusy(true);
        submitBid_d(
            auctionId,
            lotId,
            Number(customAmountField.customAmount),
            profileId,
            false,
            (res) => {
                if (res?.success) {
                    ns.success("", "Custom bid successfully submitted");
                    onBidSuccessfullySubmitted(Number(customAmountField.customAmount), res.response?.paddleNumber);
                    let temp = { ...wishlistNotificationFlagObject };
                    temp.biddingActivityFlag = true;
                    handleSetWishlistBiddingNotification_d(temp);
                }
            }
        );
    };

    const showConfirmationModal = (open: boolean, id: string) => {
        setConfirmationModal({ ...confirmationModal, open: open, id: id });
    };

    const buyersCommissionAmount = useMemo(() => { return Number(customAmountField.customAmount) * commission / 100; }, [customAmountField.customAmount, commission]);

    const determineCalculationAmount = (basedOn: any, subTotal: number, custombidAmount: number, amount: number, type: any) => {
        let total = custombidAmount;
        if (basedOn === "cumulativeTotal") total = subTotal;

        switch (type) {
            case "percentage": return total * amount / 100;
            default: return amount;
        }
    }

    const additionCostAmounts = useMemo<number[]>(() => {
        if ((biddingCalculation?.calculations?.length ?? 0) === 0) return [];

        let orderdedList = sortBy(biddingCalculation?.calculations, ["order"]);

        let totals: number[] = [];

        let subTotal = Number(customAmountField.customAmount) + buyersCommissionAmount;

        for (let i = 0; i < orderdedList.length; i++) {
            let item = orderdedList[i];
            let calculationAmount = determineCalculationAmount(item.basedOn, subTotal, customAmountField.customAmount, item.amount, item.type);
            subTotal += calculationAmount;
            totals.push(calculationAmount);
        }

        return totals;
    }, [customAmountField.customAmount, buyersCommissionAmount, biddingCalculation?.calculations]);

    const totalExVatAmount = useMemo(() => sum(additionCostAmounts ?? [0]) + Number(customAmountField.customAmount) + buyersCommissionAmount, [customAmountField.customAmount, buyersCommissionAmount, additionCostAmounts]);

    const vatAmount = useMemo(() => {
        return totalExVatAmount * vatPercentage / 100;
    }, [totalExVatAmount])

    const total = useMemo(() => {
        return totalExVatAmount + (biddingCalculation?.applyVat ? vatAmount : 0);
    }, [totalExVatAmount, vatAmount])

    return <React.Fragment>
        <div className="m-2" />
        <Grid
            container
            spacing={2}
        >
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    value={customAmountField.customAmount}
                    onChange={onCustomAmountChange}
                    id="customAmount"
                    fullWidth
                    variant="outlined"
                    className="mt-1 mb-1 mr-1"
                    label="Custom Bid"
                    InputProps={{
                        inputComponent: CurrencyInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <CurrencyComponent priceWithSymbol={""} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <LoadingButton
                    style={{ height: 56 }}
                    onClick={submitBid}
                    fullWidth
                    variant="contained"
                    className={clsx("p-3 mt-1 text-white text-capitalize btn-primary")}
                    loading={isBusy}>
                    {"Submit"}
                </LoadingButton>
            </Grid>
        </Grid>

        <ConfirmationDialog
            open={confirmationModal.open}
            payload={confirmationModal.id}
            okButtonText="Confirm"
            cancelButtonText="Cancel"
            title="Please note you are about to log a custom bid"
            content={
                <>
                    <DialogContentText>
                        Our system will bid the amount provided on your behalf. This
                        action cannot be undone,{" "}
                        <strong style={{ color: settings.Styles.Primary }}>
                            please confirm
                        </strong>{" "}
                        that you are about to make the below custom bid before
                        proceeding.
                    </DialogContentText>

                    {
                        biddingCalculation &&
                        <>
                            <DialogContentText>
                                The total amount you are liable to pay if your bid is accepted.
                            </DialogContentText>
                            <DialogContentText>
                                See breakdown below
                            </DialogContentText>

                            <Grid className="p-1">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" style={{ width: "100%" }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Bid Amount</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel value={customAmountField.customAmount} textColour={settings?.Styles?.Primary}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Buyers Commission</TableCell>
                                                <TableCell align="right"><CurrencyLabel value={buyersCommissionAmount} /></TableCell>
                                            </TableRow>

                                            {biddingCalculation?.calculations?.map((calculation: any, index: number) => (
                                                <TableRow>
                                                    <TableCell>{calculation.description}</TableCell>
                                                    <TableCell align="right"><CurrencyLabel value={additionCostAmounts[index]} /></TableCell>
                                                </TableRow>))}

                                            {biddingCalculation?.applyVat && <TableRow>
                                                <TableCell>VAT ({vatPercentage.toFixed(2)} %)</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel value={vatAmount} />
                                                </TableCell>
                                            </TableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </>
                    }

                    <DialogContentText
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 24,
                            paddingTop: 10
                        }}
                    >
                        Total Amount
                        <CurrencyLabel
                            textColour={settings?.Styles?.Primary}
                            value={biddingCalculation ? total : customAmountField.customAmount}
                        />
                    </DialogContentText>
                    <DialogContentText>Happy Bidding!</DialogContentText>
                </>
            }
            onCancel={onConfirmationModalClosed}
            onOk={onConfirmationModalOk}
        />
    </React.Fragment>
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        submitBid_d: (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback: (res: any) => void) => dispatch(submitBid(auctionId, lotId, amount, profileId, isTenderSubmission, onCompletedCallback)),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    success: state.bidding.success,
    validateResult: state.participateButton.validateResult,
    bidError: state.bidding.error,
    bidErrorText: state.bidding.errorText,
    profileId: state.loginForm.changeProfileId,
    loginSuccess: state.loginForm.loginSuccess,
    logoutSuccess: state.loginForm.logoutSuccess,
    settings: state.settings.settings,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject,
});

export default connect(mapStateToProps, mapDispatchToProps)(BidButton);
