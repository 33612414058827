import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { Person as PersonIcon, Business as BusinessIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
    Button,
    Menu,
    Avatar,
    Badge,
    List,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Toolbar,
    IconButton,
    Drawer,
    Dialog,
    DialogTitle,
    Grid,
    CircularProgress,
    Tooltip,
} from "@mui/material";
import useStyles from "./NavbarStyles";
import LoginRegisterModal from "@/components/Modal/LoginRegisterModal";
import { handleLogout, handleChangeProfile, toggleLoginRegisterModal } from "@/components/LoginForm/store/LoginForm";
import {
    FaGavel,
    FaHome,
    FaLockOpen,
    FaQuestion,
    FaBriefcase,
    FaBell,
    FaLock,
    FaUserTag
} from "react-icons/fa";
import {
    MdPerson,
    MdPeople
} from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import { BiddingProfileEnums } from '@/lib/utils/BiddingProfileEnums';
import { clearNotificationPanelState, retrieveNotificationCount, togglePanel as toggleNotificationPanel } from '@/components/NotificationPanel/store/NotificationPanel';
import { useSetProfileAsDefault } from "prembid-shared-library-npm/networking";
import { orderBy } from "lodash";
import { handleBidProfilesFetch } from "@/pages/BiddingProfile/store/BiddingProfile";
import { handleClearWishlistBiddingNotification } from "../../pages/MyActivity/store/MyActivity";
import useHistory from "../../lib/hooks/useHistory";
import { Authorized } from "../composite/Authorized";
import { ConnectionStatusIndicator } from "../composite/ConnectionStatusIndicator";
import { useConfirmationDialogActivator } from "../containers/ConfirmationDialogActivator";

interface Props {
    handleLogout_d: () => void,
    handleChangeProfile_d: (profileId: string) => any,
    loginSuccess: boolean,
    logoutSuccess: boolean,
    settings: any,
    bidProfiles: any,
    unreadNotificationCount: number,
    showNotificationPanel: () => void,
    refreshNotificationPanelCount: (profileId: string, onCompletedCallback: (response: any) => void) => void,
    handleBidProfileListFetch_d: () => void,
    handleClearWishlistBiddingNotification_d: (onCompletedCallback?: () => void) => void,
    clearNotificationPanelState_d: () => void,
    isShown: boolean,
    toggle: () => void,
    profileId: string,
    wishlistNotificationFlagObject: any;
}

const Navbar: React.FC<Props> = (props) => {
    const { handleLogout_d, handleChangeProfile_d, loginSuccess, logoutSuccess, settings, clearNotificationPanelState_d, bidProfiles, unreadNotificationCount, showNotificationPanel, refreshNotificationPanelCount, isShown, toggle, profileId, handleBidProfileListFetch_d, wishlistNotificationFlagObject, handleClearWishlistBiddingNotification_d } = props;

    const currentUser = PrembidLocalStorage.currentUser;
    const history = useHistory();
    const { classes } = useStyles();
    const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [currentProfileId, setCurrentProfileId] = React.useState<any>('');
    const [currentProfile, setCurrentProfile] = React.useState<any>('No Profiles');
    const [currentProfileList, setCurrentProfileList] = React.useState<any>([]);
    const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);


    const { isLoading: isSettingProfileAsDefault, mutate: setProfileAsDefault } = useSetProfileAsDefault({
        onSuccess(data, { profileId }) {
            handleChangeProfile_d(profileId);
            PrembidLocalStorage.defaultProfile = profileId;
            handleBidProfileListFetch_d();
        }
    });

    const confirmationDialog = useConfirmationDialogActivator();

    const currentProfilePrefix = "Transacting as: ";

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
        reloadAuctions: false
    });

    const { mobileView, drawerOpen } = state;

    const AlterBiddingProfilesToKeepDefaultFirst = (list) => {
        let tempArrayObjects = [...list];
        let targetIndex;
        let targetvalue;
        tempArrayObjects.map((x, index) => {
            if (x.isDefaultProfile === true) {
                targetIndex = index;
            }
        });

        if (targetIndex > -1) {
            targetvalue = tempArrayObjects[targetIndex];
            tempArrayObjects.splice(targetIndex, 1);
            tempArrayObjects.unshift(targetvalue);
        }
        return tempArrayObjects ?? []
    };

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 1150
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    useEffect(() => {
        if (profileId && currentProfileId !== profileId) {
            const existingProfile = currentProfileList?.find(x => x.profileId === profileId);
            if (existingProfile) {
                setCurrentProfile(currentProfilePrefix + existingProfile.profileName);
                setCurrentProfileId(existingProfile.profileId);
            }
        }
    }, [profileId])

    useEffect(() => {
        if (loginSuccess)
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

        setAnchorEl(null);
        setAnchorEl2(null);

        let currentSessionStorageProfile = sessionStorage.getItem("current_user")

        if (currentUser && currentSessionStorageProfile) {

            let currentSessionStorageProfileObject = JSON.parse(currentSessionStorageProfile)

            if (currentUser.profiles && currentUser.profiles.length > 0) {
                setProfileDialogOpen(false);

                if (currentUser.profiles[0].profileId !== null) {
                    setCurrentProfileList(AlterBiddingProfilesToKeepDefaultFirst(currentUser.profiles))
                }

                if (currentUser.profiles[0].profileId !== null && currentSessionStorageProfileObject.currentProfileId === "") {
                    setCurrentProfileId(currentUser.profiles[0].profileId)
                    setCurrentProfile(currentProfilePrefix + currentUser.profiles[0].profileName)
                    setCurrentProfileList(AlterBiddingProfilesToKeepDefaultFirst(currentUser.profiles))
                }
            } else {

                if (window.location.pathname !== '/individualProfileAdd' && window.location.pathname !== '/companyProfileAdd') {
                    setProfileDialogOpen(true);
                }
            }
        }
    }, [loginSuccess]);

    React.useEffect(() => {
        if (bidProfiles) {
            var combinedProfiles: any[] = [];

            if (bidProfiles.iBiddingProfiles && bidProfiles.iBiddingProfiles.length > 0) {
                for (var i = 0; i < bidProfiles.iBiddingProfiles.length; i++) {
                    combinedProfiles.push({
                        profileId: bidProfiles.iBiddingProfiles[i].id,
                        profileName: bidProfiles.iBiddingProfiles[i].firstName + ' ' + bidProfiles.iBiddingProfiles[i].lastName,
                        isDefaultProfile: bidProfiles.iBiddingProfiles[i].isDefaultProfile
                    })
                }
            }

            if (bidProfiles.cBiddingProfiles && bidProfiles.cBiddingProfiles.length > 0) {
                for (var i = 0; i < bidProfiles.cBiddingProfiles.length; i++) {
                    combinedProfiles.push({
                        profileId: bidProfiles.cBiddingProfiles[i].id,
                        profileName: bidProfiles.cBiddingProfiles[i].companyName,
                        isDefaultProfile: bidProfiles.cBiddingProfiles[i].isDefaultProfile
                    })
                }
            }

            if (combinedProfiles && combinedProfiles.length > 0) {
                var orderedListIList = orderBy(combinedProfiles ?? [], ['profileName'], ['asc']);
                setCurrentProfileList(AlterBiddingProfilesToKeepDefaultFirst(orderedListIList))
                if (combinedProfiles.length === 1) {
                    setCurrentProfile(currentProfilePrefix + combinedProfiles[0].profileName)
                    handleChangeProfile(combinedProfiles[0].profileId);
                }
            } else {
                setCurrentProfileList(AlterBiddingProfilesToKeepDefaultFirst(combinedProfiles))
                setCurrentProfile('No Profiles')

                handleChangeProfile(undefined);
            }


        }
    }, [bidProfiles])

    useEffect(() => {
        let currentSessionStorageProfile = sessionStorage.getItem("current_user")
        if (currentProfileList instanceof Array && currentProfileList.length >= 1 && currentSessionStorageProfile) {
            let currentSessionStorageProfileObject = JSON.parse(currentSessionStorageProfile)
            if (currentSessionStorageProfileObject.currentProfileId !== "") {
                let currentProfileObject = currentProfileList.filter(obj => {
                    return obj.profileId === currentSessionStorageProfileObject.currentProfileId
                })

                if (currentProfileObject[0] !== undefined) {
                    setCurrentProfile(currentProfilePrefix + currentProfileObject[0].profileName)
                    handleChangeProfile(currentProfileObject[0].profileId);
                }
            }

        }
    }, [currentProfileList])

    useEffect(() => {
        if (logoutSuccess) {
            setOpenMenu(null);
            clearNotificationPanelState_d();
        }
    }, [logoutSuccess]);

    useEffect(() => {
        if (profileId) refreshNotificationPanelCount(profileId, (response) => { });
    }, [profileId])

    const onSetAsDefault = (id: string, profileName: string) => {
        confirmationDialog?.showConfirmationDialog({
            title: "Set as default profile",
            description: `Are you sure you want to set "${profileName}" as your default profile?`,
        }, {
            onOkPress() {
                setProfileAsDefault({ profileId: id })
                setAnchorEl2(null);
            },
        });
    }

    const handleChangeProfile = (profileId) => {
        handleChangeProfile_d(profileId);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleNotificationsClick = (event) => {
        showNotificationPanel();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClose = () => {
        setAnchorEl2(null);
    };

    const handleProfileMenuClick = (profileId: string, profileName: string) => {
        handleChangeProfile(profileId)

        setCurrentProfileId(profileId)
        setCurrentProfile(currentProfilePrefix + profileName)
        setAnchorEl2(null);
    };

    const navigateProfiles = (path: string) => {
        setProfileDialogOpen(false);
        history.push(path)
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    }

    const navigateAuctions = () => {
        let path = `/auctions`;
        history.push(path, { state });
        setState((prevState) => ({ ...prevState, drawerOpen: false, reloadAuctions: true }));
    };

    const navigateMyActivity = () => {
        handleClearWishlistBiddingNotification_d(() => {
            let path = `/myActivity`;
            history.push(path, { state });
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        })

        let path = `/myActivity`;
        history.push(path, { state });
        setState((prevState) => ({ ...prevState, drawerOpen: false }));

    };

    const navigateHome = () => {
        let path = `/home`;
        history.push(path);
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const navigateHowItWorks = () => {
        let path = `/howitworks`;
        history.push(path);
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const navigateRegister = () => {
        let path = `/register`;
        history.push(path);
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const navigateLogout = () => {
        handleClose();
        handleChangeProfile('');
        handleLogout_d();

        setCurrentProfileId('')
        setCurrentProfile('No Profiles')
        setCurrentProfileList([])

        if (window.location.pathname === '/individualProfileAdd' ||
            window.location.pathname === '/individualProfileEdit' ||
            window.location.pathname === '/companyProfileAdd' ||
            window.location.pathname === '/companyProfileEdit' ||
            window.location.pathname === '/account' ||
            window.location.pathname === '/biddingProfileList') {
            let path = `/home`;
            history.push(path);
        }
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const navigateProfile = () => {
        handleClose();

        let path = `/account`;
        history.push(path);
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const navigateBiddingProfile = () => {
        handleClose();

        let path = `/biddingProfileList`;
        history.push(path);
        setState((prevState) => ({ ...prevState, drawerOpen: false }));
    };

    const getAvatarInitial = () => {
        if (currentUser?.email)
            return currentUser?.email[0];
    };

    const VendorLogo = (
        <img
            src={settings.VendorLogoUrl}
            className={classes.logoImg}
        />
    );

    const openVendorWebsite = () => {
        window.location.href = "https://" + settings.VendorWebsite;
    }

    const displayDesktop = () => {
        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    {VendorLogo}
                    <div>{getMenuButtons()}</div>
                </Toolbar>
            </div>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () => {
            if (!state.drawerOpen) {
                setState((prevState) => ({ ...prevState, drawerOpen: true }));
            } else {
                setState((prevState) => ({ ...prevState, drawerOpen: false }));
            }
        }

        const handleDrawerClose = () => {
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        }

        return (
            <div>
                <Toolbar>
                    <IconButton
                        {...{
                            edge: "start",
                            color: "inherit",
                            "aria-label": "menu",
                            "aria-haspopup": "true",
                            onClick: handleDrawerOpen,
                            className: "text-black"
                        }}
                    >
                        <MenuIcon style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary }} />
                    </IconButton>

                    <Drawer
                        classes={{ paper: classes.paper }}
                        {...{
                            anchor: "left",
                            open: drawerOpen,
                            onClose: handleDrawerClose,
                        }}
                    >
                        <ConnectionStatusIndicator showText style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: 10
                        }} />
                        <div className={classes.drawerContainer} style={{ background: settings?.Styles?.OverrideNavBarBackgroundColor ? settings?.Styles?.OverrideNavBarBackgroundColor : "#ffffff" }}>
                            <div>{getDrawerChoices()}</div>
                        </div>
                    </Drawer>
                    <div>{VendorLogo}</div>
                </Toolbar>
            </div>
        );
    };

    const getDrawerChoices = () => {
        return (
            <div>
                <Grid>
                    <Grid item xs={12}>
                        <Authorized required={false}>
                            <Button
                                style={{ borderColor: settings?.Styles?.Primary, padding: 10, margin: 10 }}
                                variant="outlined"
                                onClick={handleProfileClick}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                    <h5 className="font-weight-bold text-left p-0 m-0" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none' }}>{currentProfile}
                                    </h5>
                                    <MdArrowDropDown size={28} color={settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000"} />
                                </div>
                            </Button>
                        </Authorized>
                        <Menu anchorEl={anchorEl2}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl2)}
                            classes={{ list: 'p-0' }}

                            onClose={handleProfileClose}>
                            <div className="dropdown-menu-lg p-0">
                                <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                                    <h5 className="text-capitalize pl-1 text-black" >Profiles</h5>
                                    <div>
                                        {isSettingProfileAsDefault && <CircularProgress size={20} style={{ color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />}
                                    </div>
                                </div>
                                <BiddingProfiles />
                            </div>
                        </Menu>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ paddingTop: 10 }}
                            onClick={navigateHome}
                            startIcon={<FaHome className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                        >
                            <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>Home</h5>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ paddingTop: 10 }}
                            onClick={navigateAuctions}
                            startIcon={<FaGavel className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                        >
                            <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>
                                {settings.AuctionName + "s"}
                            </h5>
                        </Button>
                    </Grid>

                    <Authorized required={false}>
                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                onClick={navigateMyActivity}
                                startIcon={<FaBriefcase className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                            >
                                <Badge color="error" badgeContent={""} invisible={(wishlistNotificationFlagObject?.wishlistedActivity || wishlistNotificationFlagObject?.biddingActivityFlag) === true ? false : true}>
                                    <h5 className=" text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', paddingRight: 18, marginBottom: 0 }}>
                                        My Activity
                                    </h5>
                                </Badge>
                            </Button>
                        </Grid>
                    </Authorized>

                    <Grid item xs={12}>
                        <Button
                            style={{ paddingTop: 10 }}
                            onClick={navigateHowItWorks}
                            startIcon={<FaQuestion className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                        >
                            <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>How it Works</h5>
                        </Button>
                    </Grid>

                    <Authorized required={false}>
                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                startIcon={<FaBell className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                                onClick={handleNotificationsClick}>
                                <Badge color="error" badgeContent={unreadNotificationCount}>
                                    <h5 className="text-left" style={unreadNotificationCount > 0 ?
                                        unreadNotificationCount > 9 ?
                                            unreadNotificationCount > 99 ?
                                                { paddingRight: 18, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                                : { paddingRight: 16, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                            : { paddingRight: 12, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                        : { color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>Notifications</h5>
                                </Badge>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                onClick={navigateProfile}
                                startIcon={<MdPerson className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                            >
                                <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>My Account</h5>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                onClick={navigateBiddingProfile}
                                startIcon={<MdPeople className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000" }} />}
                            >
                                <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>My Profiles</h5>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                onClick={navigateLogout}
                                startIcon={<FaLock className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary }} />}
                            >
                                <h5 className="text-left" style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary, textTransform: 'none', marginBottom: 0 }}>Log Out</h5>
                            </Button>
                        </Grid>
                    </Authorized>

                    <Authorized>
                        <Grid item xs={12}>
                            <Button
                                style={{ paddingTop: 10 }}
                                onClick={toggle}
                                startIcon={<FaLockOpen className={classes.largeIcon + " text-left"} style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary }} />}
                            >
                                <h5 className=" text-left" style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary, textTransform: 'none', marginBottom: 0 }}>Log In/Sign Up</h5>
                            </Button>
                        </Grid>
                    </Authorized>
                </Grid>
            </div>
        );
    };

    const getMenuButtons = () => {
        return (
            <div className={classes.navBar + " z-over"}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ConnectionStatusIndicator style={{ paddingBottom: 5 }} />
                    <Button className={classes.linksButton} onClick={navigateHome}>
                        <h5 style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>Home</h5>
                    </Button>

                    <Button className={classes.linksButton} onClick={navigateAuctions}>
                        <h5 style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>{settings.AuctionName + "s"}</h5>
                    </Button>

                    <Authorized required={false}>
                        <Button className={classes.linksButton} onClick={navigateMyActivity}>
                            <Badge color="error" badgeContent={""} invisible={(wishlistNotificationFlagObject?.wishlistedActivity || wishlistNotificationFlagObject?.biddingActivityFlag) === true ? false : true}>
                                <h5 style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', paddingRight: 12, marginBottom: 0 }}>My Activity </h5>
                            </Badge>
                        </Button>
                    </Authorized>

                    <Button className={classes.linksButton} onClick={navigateHowItWorks}>
                        <h5 style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>How it Works</h5>
                    </Button>

                    <Authorized required={false}>
                        <Button className={classes.linksButton}
                            onClick={handleNotificationsClick}>
                            <Badge color="error" badgeContent={unreadNotificationCount}>
                                <h5 style={
                                    unreadNotificationCount > 0 ?
                                        unreadNotificationCount > 9 ?
                                            unreadNotificationCount > 99 ?
                                                { paddingRight: 18, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                                : { paddingRight: 16, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                            : { paddingRight: 12, color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }
                                        : { color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none', marginBottom: 0 }}>Notifications</h5>
                            </Badge>
                        </Button>
                    </Authorized>

                    <Authorized required={false}>
                        <Button
                            style={{ borderColor: settings?.Styles?.Primary, padding: 10, margin: 10 }}
                            variant="outlined"
                            onClick={handleProfileClick}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                <h5 className="font-weight-bold text-left p-0 m-0" style={{ color: settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000", textTransform: 'none' }}>{currentProfile}
                                </h5>
                                <MdArrowDropDown size={28} color={settings?.Styles?.OverrideNavBarTextColor ? settings?.Styles?.OverrideNavBarTextColor : "#000000"} />
                            </div>
                        </Button>
                    </Authorized>

                    <Authorized required={false}>
                        <Menu anchorEl={anchorEl2}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl2)}
                            classes={{
                                list: 'p-0',
                                root: 'text-primary'
                            }}
                            onClose={handleProfileClose}  >
                            <div className="dropdown-menu-lg p-0">
                                <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                                    <h5 className="text-capitalize pl-1  text-black">
                                        <span>
                                            Profiles
                                        </span>
                                    </h5>
                                </div>
                                <BiddingProfiles />
                            </div>
                        </Menu>
                    </Authorized>

                    <Authorized>
                        <Button className={classes.linksButton} onClick={toggle}>
                            <h5 style={{ color: settings?.Styles?.OverrideNavBarCallToActionColor ? settings?.Styles?.OverrideNavBarCallToActionColor : settings?.Styles?.Primary, textTransform: 'none', marginBottom: 0 }}>Log In/Sign Up</h5>
                        </Button>
                    </Authorized>

                    <Authorized required={false}>
                        <div>
                            <Button
                                variant="text"
                                onClick={handleClick}
                                className="ml-2 btn-transition-none text-left p-0 bg-transparent d-flex align-items-center"
                                disableRipple>
                                <div className="d-block p-2 avatar-icon-wrapper">

                                    <div className="rounded">
                                        <Avatar style={{ background: settings?.Styles?.OverrideNavBarAvatarBackgroundColor ? settings?.Styles?.OverrideNavBarAvatarBackgroundColor : settings?.Styles?.Primary, color: settings?.Styles?.OverrideNavBarAvatarTextColor ? settings?.Styles?.OverrideNavBarAvatarTextColor : "#ffffff" }} alt="avatar">{getAvatarInitial()}</Avatar>
                                    </div>

                                </div>
                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                open={Boolean(anchorEl)}
                                classes={{ list: "p-0" }}
                                onClose={handleClose}>
                                <div className="dropdown-menu-lg overflow-hidden p-0">
                                    <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                                        <h5 className="text-capitalize pl-1  text-black">
                                            <span>Manage</span>
                                        </h5>
                                        <div className="font-size-xs pr-1"></div>
                                    </div>
                                    <List
                                        component="div"
                                        className="nav-neutral-primary text-left d-block px-3 pb-3"
                                    >
                                        <ListItem
                                            button
                                            className="d-block text-left"
                                            onClick={navigateProfile}
                                        >
                                            My Account
                                        </ListItem>
                                        <ListItem
                                            button
                                            className="d-block text-left"
                                            onClick={navigateBiddingProfile}
                                        >
                                            My Profiles
                                        </ListItem>
                                    </List>
                                    <Divider className="w-100" />
                                    <List
                                        component="div"
                                        className="nav-neutral-primary text-left d-block px-3 pb-3"
                                    >
                                        <ListItem
                                            button
                                            className="d-block text-left"
                                            onClick={navigateLogout}
                                        >
                                            Log Out
                                        </ListItem>
                                    </List>
                                </div>
                            </Menu>
                        </div>
                    </Authorized>
                </div>
            </div>
        );
    };

    function BiddingProfiles() {
        return (
            <div style={{ maxHeight: 400, overflowY: 'auto', overflowX: 'hidden' }}>
                <List
                >
                    {
                        currentProfileList.map((profile) => {
                            return <ListItem
                                key={profile.profileId}
                                button
                                className="d-block text-left"
                                style={{ width: '100%' }}
                                onClick={() => { handleProfileMenuClick(profile.profileId, profile.profileName) }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div style={{ overflowWrap: 'anywhere', paddingRight: 10 }}>{profile.profileName}</div>

                                    {!profile.isDefaultProfile && <div style={{ width: 30 }}>
                                        <Tooltip arrow title={'Set as default'} PopperProps={{ style: { zIndex: 0 } }}>
                                            <Button onClick={(event) => {
                                                event.stopPropagation();
                                                onSetAsDefault(profile.profileId, profile.profileName);
                                            }} size="small" className="text-white btn-neutral-primary rounded-sm shadow-none hover-scale-sm d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2B2B2B' }}>
                                                <FaUserTag />
                                            </Button>
                                        </Tooltip>
                                    </div>}
                                </div>
                            </ListItem>
                        })
                    }

                </List>
            </div>
        );
    }

    function Topbar() {
        if (settings?.Styles?.OverrideTopBarBackgroundColor) {
            if (settings?.VendorWebsite) {
                return <h5 className={"pt-3 pr-3 pb-3 " + classes.toolbarWebsite} style={{ background: settings?.Styles?.OverrideTopBarBackgroundColor, color: settings?.Styles?.OverrideTopBarTextColor ? settings?.Styles?.OverrideTopBarTextColor : "#ffffff" }}>
                    Back to
                    <a onClick={openVendorWebsite} className={"font-weight-normal ml-1 " + classes.link} style={{ textDecoration: 'underline' }}>{settings.VendorWebsite}</a>
                </h5>
            } else {
                return <h5 className={"pt-3 pr-3 pb-5 bg-primary" + classes.toolbarWebsite} style={{ background: settings?.Styles?.OverrideTopBarBackgroundColor }}>
                    <a className={"font-weight-normal ml-1 " + classes.link} style={{ textDecoration: 'underline' }}></a>
                </h5>
            }
        } else {
            if (settings?.VendorWebsite) {
                return <h5 className={"pt-3 pr-3 pb-3 bg-primary " + classes.toolbarWebsite} style={{ color: settings?.Styles?.OverrideTopBarTextColor ? settings?.Styles?.OverrideTopBarTextColor : "#ffffff" }}>
                    Back to
                    <a onClick={openVendorWebsite} className={"font-weight-normal ml-1 " + classes.link} style={{ textDecoration: 'underline' }}>{settings.VendorWebsite}</a>
                </h5>
            } else {
                return <h5 className={"pt-3 pr-3 pb-5 bg-primary " + classes.toolbarWebsite}>
                    <a className={"font-weight-normal ml-1 " + classes.link} style={{ textDecoration: 'underline' }}></a>
                </h5>
            }
        }
    }

    return (
        <div className="z-over">
            <div className={'fixed-top'}>
                {/*<NotificationValidationNavbarComponent />*/}

                <div style={{ height: "48px" }}>
                    <Topbar />
                </div>

                <div className={classes.header} style={{ background: settings?.Styles?.OverrideNavBarBackgroundColor ? settings?.Styles?.OverrideNavBarBackgroundColor : "#ffffff" }}>
                    {mobileView ? displayMobile() : displayDesktop()}
                </div>
            </div>
            <LoginRegisterModal isShown={isShown} hide={toggle} isMobile={mobileView} />

            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={profileDialogOpen}>
                <DialogTitle id="simple-dialog-title">Please Setup a Profile</DialogTitle>
                <List>

                    <ListItem button onClick={() => navigateProfiles('/companyProfileAdd')} key={BiddingProfileEnums.Company}>
                        <ListItemAvatar>
                            <Avatar>
                                <BusinessIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Company" />
                    </ListItem>

                    <ListItem button onClick={() => navigateProfiles('/individualProfileAdd')} key={BiddingProfileEnums.Individual}>
                        <ListItemAvatar>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Individual" />
                    </ListItem>

                </List>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    loginSuccess: state.loginForm.loginSuccess,
    logoutSuccess: state.loginForm.logoutSuccess,
    settings: state.settings.settings,
    bidProfiles: state.bidProfile.bidProfiles,
    unreadNotificationCount: state.notificationPanel.unreadNotificationCount,
    isShown: state.loginForm.showLoginRegisterModal,
    profileId: state.loginForm.changeProfileId,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLogout_d: () => dispatch(handleLogout()),
        handleChangeProfile_d: (profileId: string) => dispatch(handleChangeProfile(profileId)),
        showNotificationPanel: () => dispatch(toggleNotificationPanel(true)),
        toggle: () => dispatch(toggleLoginRegisterModal()),
        handleBidProfileListFetch_d: () => dispatch(handleBidProfilesFetch()),
        refreshNotificationPanelCount: (profileId: string, onCompletedCallback: (response: any) => void) => dispatch(retrieveNotificationCount(profileId, false, onCompletedCallback)),
        handleClearWishlistBiddingNotification_d: (onCompletedCallback?: () => void) => dispatch(handleClearWishlistBiddingNotification(onCompletedCallback)),
        clearNotificationPanelState_d: () => dispatch(clearNotificationPanelState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
