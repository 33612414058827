import React from "react";
import { connect } from "react-redux";
import useStyles from './DeleteAccountCardStyles';
import {
    Container,
} from '@mui/material';
import useHistory from "../../lib/hooks/useHistory";

interface Props {
    settings: any,
}

const DeleteAccountCard: React.FC<Props> = props => {

    const styles = useStyles()
    const history = useHistory();

    const {
        settings,
    } = props;

    const navigateHome = () => {
        let path = '/home';
        history.push(path);
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={styles.classes.paper} style={{ marginLeft: "29" }}>
                <img className={styles.classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                <h3 className="text-black text-center mb-4">We are sad to see you go!</h3>
            </div>

            <h5 className="mb-3">Please check your email and confirm your request to delete your account.</h5>
            <h5 className="mb-3">Please note, if you do not receive an email within a few minutes then proceed to check your <h5 className="text-info">SPAM FOLDER.</h5></h5>
            <h6 className="text-black">Return to <a onClick={navigateHome} className={styles.classes.link + " text-info"} style={{ textDecoration: 'underline' }}>Home</a></h6>
        </Container>
    )

}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(DeleteAccountCard);
