import { FC, memo, useReducer } from "react";
import { CircularProgress, Grid, ListItem } from '@mui/material';
import { connect } from "react-redux";
import { downloadFile } from "./store/ViewDocumentItems";
import { Skeleton } from "../../dumb/Skeleton";

type Dictionary = {
    documentTemplateName: string;
    documentUrl: string;
};

export interface ViewDocumentItemsProps {
    documents: Dictionary[];
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
    showLoading?: boolean;
}

const ViewDocumentItems: FC<ViewDocumentItemsProps> = ({ documents, downloadFile_d, showLoading = false }) => {

    const [loading, updateLoading] = useReducer((state: any[], action: { type: string; id: any; }) => {
        if (action.type === 'add') {
            return [...state, action.id]
        } else if (action.type === 'remove') {
            return state.filter((id: any) => id !== action.id)
        }

        return state;
    }, [])

    const generateDocumentsList = (docs: any[], loading: any[]) => {
        let elements: any[] = [];

        docs.forEach((document, index) => {
            elements.push(<>
                <Grid item xs={10} key={index}>
                    <div key={"documentId" + index}>
                        <h5
                            className="mb-2 text-black"
                            style={{ whiteSpace: 'pre-wrap' }}>
                            {document?.documentTemplateName}
                        </h5>
                    </div>
                </Grid>
                <Grid item xs={2} key={index}>
                    <div key={"documentId" + index}>
                        <h5>
                            {
                                loading.includes(document.documentUrl) ?
                                    <CircularProgress size={20}
                                        className="text-primary text-center center" />
                                    :
                                    <a
                                        className="text-info"
                                        href={undefined}
                                        onClick={() => {
                                            updateLoading({ type: 'add', id: document.documentUrl })
                                            setTimeout(() => {
                                                handleViewDocument(document.documentUrl, () => {
                                                    updateLoading({ type: 'remove', id: document.documentUrl })
                                                })
                                            }, 50)
                                        }}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                        View
                                    </a>
                            }
                        </h5>
                    </div>
                </Grid>
            </>);
        })

        return elements;
    }

    const handleViewDocument = (url: string, onCompletedCallback?: () => void) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then((blob: Blob | MediaSource) => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
            onCompletedCallback?.();
        })
    }

    return <>
        {
            showLoading ? (
                <>
                    <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="100%" />
                </>
            ) : (
                <>
                    {
                        documents instanceof Array && documents?.length > 0 ?
                            <Grid container spacing={2}>
                                {generateDocumentsList(documents, loading)}
                            </Grid>
                            : (
                                <h5
                                    className="mb-2 text-black"
                                    style={{ whiteSpace: 'pre-wrap' }}>
                                    {"No Documents"}
                                </h5>
                            )
                    }
                </>
            )
        }
    </>
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

export default connect(null, mapDispatchToProps)(memo(ViewDocumentItems));