import React, { useEffect } from "react";
import ReactReadMoreReadLess from "@/components/ReadMoreReadLess/ReadMoreReadLess";
import { Card, Button, Tooltip, List, ListItem } from "@mui/material";
import { zeroPad } from "react-countdown";
import { Countdown } from "@/components/library/Countdown";
import { AuctionTypeEnum } from "@/lib/utils/AuctionTypeEnum";
import { FaInfoCircle } from "react-icons/fa";
import { AuctionLotStatusEnum } from "@/lib/utils/AuctionLotStatusEnum";
import { CurrencyLabel } from "../composite/CurrencyLabel";
import { formatDateTime } from "../../lib/utils/General";
import useHistory from "../../lib/hooks/useHistory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers"
import { clearFilterObjectData, clearLotViewType } from "../../pages/Lots/store/Lots";
import { GeneralPopper } from "../composite/popper/GeneralPopper";

interface Props {
    imgSrc: string;
    header: string;
    description: string;
    startDateTimeAt: any;
    endDateTimeAt: any;
    auctionEndDateTimeAt?: any,

    successBadgeText: number;
    dangerBadgeText?: string;

    auctionType: string;
    hasExtensions: boolean;
    hasBadge?: boolean;
    hasDeposit?: boolean;
    settings: any;

    infoBadgeText?: string,

    btnText?: string;
    btnSrc?: string;
}

const AuctionStackedCard: React.FC<Props> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        imgSrc,
        header,
        description,
        startDateTimeAt,
        endDateTimeAt,
        auctionEndDateTimeAt,

        successBadgeText,
        dangerBadgeText,

        auctionType,
        hasExtensions,
        hasBadge = false,
        hasDeposit = false,
        settings,
        infoBadgeText,

        btnText,
        btnSrc,
    } = props;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const [started, setStarted] = React.useState<any>(false);
    const [ended, setEnded] = React.useState<any>(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const now = getNewDateWithOffset(currentDateTimeOffset);
    const start = new Date(startDateTimeAt);
    const end = new Date(endDateTimeAt);

    const startDateTimeAtText = formatDateTime(startDateTimeAt, "dd MMMM yyyy") + " " + formatDateTime(startDateTimeAt, "hh:mm a");
    const endDateTimeAtText = formatDateTime(auctionEndDateTimeAt, "dd MMMM yyyy") + " " + formatDateTime(auctionEndDateTimeAt, "hh:mm a");

    const StartTimeCountdownRenderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed && !started) {
            setStarted(true);
        }

        return (
            <div>
                <h5>
                    <span>
                        {zeroPad(days)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        dd:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(hours)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        hh:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(minutes)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        mm:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(seconds)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        ss
                    </span>
                </h5>
            </div>
        );
    };

    const EndTimeCountdownRenderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed && !ended) {
            setEnded(true);
        }

        return (
            <div>
                <h5>
                    <span>
                        {zeroPad(days)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        dd:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(hours)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        hh:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(minutes)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        mm:
                    </span>{" "}
                    <span>
                        {" "}
                        {zeroPad(seconds)}
                    </span>
                    <span className="text-white" style={{ fontSize: "10px" }}>
                        {" "}
                        ss
                    </span>
                </h5>
            </div>
        );
    };

    const btnRoute = () => {
        if (btnSrc) {
            dispatch(clearLotViewType(settings.DefaultLotView));
            dispatch(clearFilterObjectData());
            history.push(btnSrc);
        }
    }

    const CountdownTimerComponent = () => {
        if (startDateTimeAt === undefined || endDateTimeAt === undefined) {
            return <React.Fragment />;
        }

        if (!started) {
            return (
                <React.Fragment>
                    <div
                        className="z-over p-1"
                        style={{
                            zIndex: 2,
                            position: "absolute",
                            background: "#00000070",
                            left: "10px",
                            marginTop: "10px",
                            width: "200px",
                            borderRadius: "5px",
                        }}
                    >
                        <div className="ml-1">
                            <small className="text-white">Starting in</small>
                            <small className="text-white">
                                <Countdown
                                    date={startDateTimeAt}
                                    renderer={StartTimeCountdownRenderer}
                                    now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                />
                            </small>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (started && !ended) {
            return (
                <React.Fragment>
                    <div
                        className="z-over p-1"
                        style={{
                            zIndex: 2,
                            position: "absolute",
                            background: "#00000070",
                            left: "10px",
                            marginTop: "10px",
                            width: "200px",
                            borderRadius: "5px",
                        }}
                    >
                        <div className="ml-1">
                            <small className="text-white">Closing in</small>
                            <small className="text-white">
                                <Countdown
                                    date={endDateTimeAt}
                                    renderer={EndTimeCountdownRenderer}
                                    now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                />
                            </small>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (started && ended) {
            return <React.Fragment />;
        }

        return <React.Fragment />;
    };

    // All the code added
    const handleInfoPopperClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleInfoPopperClose = () => {
        setAnchorEl(null);
    };

    const getInfoElement = () => {
        const placeholderText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tender" : "bid";
        const placeholderActioningText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tendering" : "bidding";

        let auctionStatus = AuctionLotStatusEnum.Awaiting;

        if (now < start) auctionStatus = AuctionLotStatusEnum.Awaiting;

        if (now > end) auctionStatus = AuctionLotStatusEnum.Completed;

        if (start < now && now < end) {
            if (hasExtensions) {
                auctionStatus = AuctionLotStatusEnum.Extended;
            } else {
                auctionStatus = AuctionLotStatusEnum.Open;
            }
        }

        /* if (now < start) auctionStatus = AuctionLotStatusEnum.Awaiting;
     
        if (now > end) auctionStatus = AuctionLotStatusEnum.Completed;
      
        if (start < now && now < end) {
          if (hasExtensions) {
            auctionStatus = AuctionLotStatusEnum.Extended;
            alert("Hello")
          } else {
            auctionStatus = AuctionLotStatusEnum.Open;
          }
        } */

        return (
            <List>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        {AuctionLotStatusEnum[auctionStatus.toString()]} -{" "}
                        {auctionStatus === AuctionLotStatusEnum.Awaiting && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.AuctionName.toLowerCase() +
                                    " hasn't opened yet, but you need to register if you want to " +
                                    placeholderText +
                                    " when it does"}
                            </span>
                        )}
                        {(auctionStatus === AuctionLotStatusEnum.Open || auctionStatus === AuctionLotStatusEnum.Extended) && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.AuctionName.toLowerCase() +
                                    " is open for you to " +
                                    placeholderText +
                                    " on " +
                                    settings.LotName.toLowerCase() +
                                    "s if you have registered and been approved"}
                            </span>
                        )}
                        {auctionStatus === AuctionLotStatusEnum.Extended && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.AuctionName.toLowerCase() +
                                    " has been extended due to " +
                                    placeholderActioningText +
                                    " activity, " +
                                    placeholderActioningText +
                                    " is still open on certain " +
                                    settings.LotName.toLowerCase() +
                                    "s"}
                            </span>
                        )}
                        {auctionStatus === AuctionLotStatusEnum.Completed && (
                            <span className="text-black font-weight-normal">
                                {"This " +
                                    settings.AuctionName.toLowerCase() +
                                    " has completed and " +
                                    placeholderActioningText +
                                    " is closed"}
                            </span>
                        )}
                    </h5>
                </ListItem>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        {AuctionTypeEnum[auctionType.toString()]} -{" "}
                        {Number(auctionType) === Number(AuctionTypeEnum.Timed) && (
                            <span className="text-black font-weight-normal">
                                {"Start " +
                                    placeholderActioningText +
                                    " at the opening " +
                                    placeholderText +
                                    " in increments. The highest " +
                                    placeholderText +
                                    " is the winner at the end of the " +
                                    settings.AuctionName.toLowerCase() +
                                    ". Note: This is binding if your " +
                                    placeholderText +
                                    " is accepted"}
                            </span>
                        )}
                        {Number(auctionType) === Number(AuctionTypeEnum.Streamed) && (
                            <span className="text-black font-weight-normal">
                                {"Allowing you to watch a live stream of an onsite " +
                                    settings.AuctionName.toLowerCase()}
                            </span>
                        )}
                        {Number(auctionType) === Number(AuctionTypeEnum.Tender) && (
                            <span className="text-black font-weight-normal">
                                {
                                    "Submit a single confidential Tender. Note: This is binding if your tender is accepted"
                                }
                            </span>
                        )}
                    </h5>
                </ListItem>
                <ListItem>
                    <h5 className="text-primary font-weight-bold">
                        Registration Deposit -{" "}
                        <span className="text-black font-weight-normal">
                            {"The amount held as a deposit to register to " +
                                placeholderText +
                                ". The deposit is "}
                            <span className="text-danger font-weight-bold">
                                FULLY REFUNDABLE{" "}
                            </span>
                            {"if you are unsuccessful"}
                        </span>
                    </h5>
                </ListItem>
            </List>
        );
    };

    return (
        <Card raised={true}>
            <div style={{ maxHeight: '232px', aspectRatio: '1.78' }}>
                <CountdownTimerComponent />
                <Card className="card-img-top img-fit-container">
                    <img
                        src={imgSrc}
                        alt="Missing"
                        style={{ height: "100%", width: "100%", cursor: "pointer", objectFit: 'scale-down', background: '#000000' }}
                        onClick={btnRoute}
                        />
                </Card>
            </div>

            {now < start && <div className="badge badge-pill badge-dark mt-2 ml-4 text-capitalize" style={{ fontSize: "10px" }}>Awaiting</div>}
            {start < now && now < end && hasExtensions && <div className="badge ml-4 badge-pill badge-success mt-2 text-capitalize " style={{ fontSize: "10px" }}>Open</div>}
            {start < now && now < end && !hasExtensions && <div className="badge ml-4 badge-pill badge-success mt-2 text-capitalize " style={{ fontSize: "10px" }}>Open</div>}


            <div className="badge badge-pill badge-dark mt-3 text-capitalize ml-1 badge-primary" style={{ fontSize: "10px", background: "#2b2b2b" }}>
                {AuctionTypeEnum[successBadgeText]}
            </div>

            {hasDeposit && (<div className="badge badge-pill badge-info mt-2 ml-1 text-capitalize" style={{ fontSize: "10px" }}>
                <CurrencyLabel value={infoBadgeText} prefixString='Registration Deposit ' textColour={"text-primary"} />
            </div>
            )}

            {hasBadge && (
                <Tooltip arrow title="Click for Info" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        size="large"
                        className="btn-white d-30 btn-pill p-0 btn-icon btn-animated-icon align-items-right"
                        onClick={handleInfoPopperClick}
                    >
                        {anchorEl && (
                            <GeneralPopper
                                headerText={"Info"}
                                anchorEl={anchorEl}
                                element={getInfoElement()}
                                handleGeneralPopperClose={handleInfoPopperClose}
                            />
                        )}
                        <FaInfoCircle style={{ color: '#2b2b2b' }} color={'#2b2b2b'} />
                    </Button>
                </Tooltip>
            )}

            <div className="p-4">
                <h5 className="text-black font-weight-bold">{header}</h5>
                <h5 className="text-black-75 mt-4" style={{ color: "#5a5a5a" }}>
                    {startDateTimeAtText !== undefined
                        ? "Start: " +
                        new Date(startDateTimeAtText).toDateString() +
                        " " +
                        formatDateTime(startDateTimeAtText, "hh:mm a")
                        : ""}
                </h5>
                <h5 className="text-black-75 mb-4" style={{ color: "#5a5a5a" }}>
                    {endDateTimeAtText !== undefined
                        ? "End: " +
                        new Date(endDateTimeAtText).toDateString() +
                        " " +
                        formatDateTime(endDateTimeAtText, "hh:mm a")
                        : ""}
                </h5>
                <h6 className="text-black" style={{ whiteSpace: "pre-wrap" }}>
                    <ReactReadMoreReadLess
                        charLimit={30}
                        ellipsis={"..."}
                        readMoreText={"read more"}
                        readLessText={"read less"}
                        readMoreClassName="read-more-less--more"
                        readLessClassName="read-more-less--less"
                        readMoreStyle={{ textDecoration: "underline", cursor: "pointer" }}
                        readLessStyle={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        {description}
                    </ReactReadMoreReadLess>
                </h6>

                <div className="divider w-100 mx-auto" />
                <div className="py-3">
                    {btnText && (
                        <Button
                            fullWidth
                            variant="contained"
                            className="btn-primary p-3 text-white text-capitalize"
                            onClick={btnRoute}
                        >
                            {btnText}
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default AuctionStackedCard;
