import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleSubmit, confirmAccountResetState } from './store/ConfirmAccountCard';
import useStyles from './ConfirmAccountCardStyles';
import ns from '@/lib/utils/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from '@/lib/utils/ProgressSpinnerService';
import useHistory from '../../lib/hooks/useHistory';

interface Props {
    handleSubmit_d: (userId: string, tokenId: string) => void,
    confirmAccountResetState_d: () => void,
    error: boolean,
    errorText: string,
    success: boolean,
    loading: boolean
}

const ConfirmAccountCard: React.FC<Props> = props => {
    const history = useHistory();
    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const {
        handleSubmit_d,
        confirmAccountResetState_d,
        error,
        errorText,
        success,
        loading
    } = props;

    const { classes } = useStyles()

    useEffect(() => {
        showProgressSpinner({ description: 'Confirming Account...' });
        handleSubmit_d(userId, tokenId)
    }, []);

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            redirect();
        }
        confirmAccountResetState_d();

    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) {
            ns.error('', errorText);
            redirectHome();
        }

    }, [error]);

    const redirect = () => {
        let path = '/ConfirmAccountConfirmation';
        history.push(path);
    };

    const redirectHome = () => {
        let path = '/Home';
        history.replace(path);
    };

    return (<>
        {!loading && (<>
        </>)}
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (userId: string, tokenId: string) => dispatch(handleSubmit(userId, tokenId)),
        confirmAccountResetState_d: () => dispatch(confirmAccountResetState())
    }
}

const mapStateToProps = (state: any) => ({
    error: state.confirmAccountCard.error,
    errorText: state.confirmAccountCard.errorText,
    success: state.confirmAccountCard.success,
    token: state.confirmAccountCard.token,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountCard)
