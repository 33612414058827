import { makeStyles } from "tss-react/mui";

export const useImageCarouselStyles = makeStyles()((theme) => ({
    image: {
        boxShadow: '0 0 2px #ffffff',
        marginLeft: 2,
        marginRight: 1
    },
    arrows: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5) !important',
        border: 'none !important',
        cursor: 'pointer',
        zIndex: 2,
        fontSize: '2.5rem !important',
        color: '#ffffff !important',
        borderRadius: '10% !important',
        padding: '10px !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        width: '50px !important',
        height: '50px !important',
    },
    leftArrow: {
        left: '5px'
    },
    rightArrow: {
        right: '5px'
    },
    thumbnail: {
        height: '100%',
        objectFit: 'scale-down',
        borderRadius: 3,
        padding: 1,
    },
    imageSlide: {
        height: '100%',
        objectFit: 'scale-down',
    },
}));