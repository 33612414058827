import { FC, memo } from 'react';

interface SoldFlagBannerProps {
    backgroundColor: string;
    bannerText: string;
    fontSize?: string | number
}

const SoldFlagBanner: FC<SoldFlagBannerProps> = (props) => {
    const { backgroundColor, bannerText, fontSize = '1.75rem' } = props;

    return (
        <div
            style={{
                backgroundColor: backgroundColor,
                borderRadius: 50,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <h3 style={{ fontWeight: 'bold', textAlign: 'center', fontSize }} className='text-white'>{bannerText}</h3>
        </div>
    );
};

export default memo(SoldFlagBanner);