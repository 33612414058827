import React, { useEffect, useState } from 'react';
import { connect, } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { Container, Grid, Card } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from '@/lib/utils/ProgressSpinnerService';
import { determineAuctionForLot } from './store/LotDetailsRedirect';
import useHistory from '../../lib/hooks/useHistory';
import useStyles from '../LotDetailsRedirect/LotDetailsRedirectStyles';
import { HeaderBlock } from '../../components/composite/HeaderBlock';

interface Props {
    determineAuctionForLot_d: (lotId: string, onCompletedCallback?: (res: any) => void) => void,
    loading: boolean,
    settings: any
}

const LotDetailsRedirect: React.FC<Props> = (props) => {

    const { lotId }: any = useParams();
    const history = useHistory();
    const { classes } = useStyles();
    const [lotNotAvailable, setLotNotAvailable] = useState<boolean>(false);

    const {
        loading,
        settings,
        determineAuctionForLot_d
    } = props;

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    useEffect(() => {
        if (lotId) {
            showProgressSpinner({ description: "Retrieving " + settings.LotName + " Info...", keepOpen: true });
            determineAuctionForLot_d(lotId, (res: any) => {
                if (res.success) {
                    var data = res.response;
                    if (data.auctionId) {
                        hideProgressSpinner(undefined, true);
                        history.push(generatePath("/LotBidAndInfo/:auctionId/:lotId", { auctionId: data.auctionId, lotId: lotId }));
                    }
                    else {
                        setLotNotAvailable(true);
                        hideProgressSpinner(500, true);
                    }
                } else {
                    setLotNotAvailable(true);
                    hideProgressSpinner(500, true);
                }
            })
        }
    }, [lotId])

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <div>
            <HeaderBlock imageSrc={settings.VendorBannerUrl} />
            <Container className={`pb-5 mt-5 ${classes.containerWidth}`}>
                {lotNotAvailable && <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={3}>
                    <Grid item md={12}>
                        <div className="mb-2 p-3">
                            <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>{settings.LotName} no longer available</h3>
                            <h6 className="font-size-sm" style={{ whiteSpace: 'pre-wrap', color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                The {settings.LotName.toLowerCase()} you are looking for is no longer available.
                            </h6>
                        </div>
                    </Grid>
                </Grid>}
            </Container>
        </div>
    </div>)
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        determineAuctionForLot_d: (lotId: string, onCompletedCallback?: (result: any) => void) => dispatch(determineAuctionForLot(lotId, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotDetailsRedirect);
