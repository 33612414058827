import { FC, memo } from 'react';
import { useBiddingTableStyles } from '../BiddingTable.styles';
import { Skeleton } from '../../../dumb/Skeleton';

type PaddleNumberProps = {
    showLoading?: boolean;
    paddleNumber: string;
}

const PaddleNumber: FC<PaddleNumberProps> = ({ showLoading = false, paddleNumber }) => {

    const { classes: { leftRightTextContainer } } = useBiddingTableStyles();

    return <>
        {
            showLoading ? (
                <div className={leftRightTextContainer}>
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                </div>
            ) : (<>
                {
                    paddleNumber?.length > 0 &&
                    <div className={leftRightTextContainer}>
                        <h5 className="text-danger text-left">You</h5>
                        <h5 className="text-danger text-right">{paddleNumber}</h5>
                    </div>
                }
            </>
            )
        }
    </>
}

export default memo(PaddleNumber);