import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ns from "@/lib/utils/NotificationService";
import {
    handleCompanyProfileCreate,
    handleCompanyProfileUpdate,
    handleCompanyProfileFetch,
    clearApiResponses,
    clearCompanyProfile,
} from "./store/CompanyProfile";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import {
    Button,
    Card,
    List,
    ListItem,
    TextField,
    Grid,
    Container,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    CircularProgress,
    FormControlLabel,
    Switch,
    Alert
} from "@mui/material";
import { FaSave, FaTimes } from "react-icons/fa";
import {
    hideProgressSpinner,
    showProgressSpinner,
} from "@/lib/utils/ProgressSpinnerService";
import {
    handleDocumentDelete,
    retrieveProfileDocuments,
} from "@/pages/Documents/store/Documents";
import { DocumentTypeEnum } from "@/lib/utils/DocumentTypeEnum";
import { isValidEmail, isValidPhoneNumber } from "../../lib/utils/General";
import SetObjectValue from "lodash/set";
import { forEach } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { uploadRegistrationProcessDocuments } from "../../components/RegistrationProcess/store/RegistrationModal";
import { handleBidProfilesFetch } from "./store/BiddingProfile";
import { downloadFile } from "@/pages/Documents/store/Documents";
import useHistory from "../../lib/hooks/useHistory";
import { FileUploader } from "../../components/composite/FileUploader";

type FormFields = {
    id: string;
    companyName: string;
    tradingName: string;
    registrationNumber: string;
    vatNumber: string;
    contactPersonFirstName: string;
    contactPersonLastName: string;
    email: string;
    phoneNumber: string;
    isDefaultProfile: null | boolean;
    address: {
        addressId: string,
        addressLine1: string,
        addressLine2: string,
        country: string,
        state: string,
        city: string,
        zip: string,
        longitude: number,
        latitude: number,
    };
}

interface Props {
    handleCompanyProfileCreate_d: (formFields: any) => void;
    handleCompanyProfileUpdate_d: (formFields: any) => void;
    handleCompanyProfileFetch_d: (id: string) => void;
    handleDocumentDelete_d: (documentId: string) => void;
    clearApiResponses_d: () => void;
    retrieveProfileDocuments: (
        profileId: string,
        onCompletedCallback: (res: any) => void
    ) => void;
    uploadProfileDocuments: (
        data: any,
        onCompletedCallback: (res: any) => void
    ) => void;
    clearCompanyProfile_d: () => void;
    error: boolean;
    errorText: string;
    reload: boolean;
    success: boolean;
    profile: any;
    profileId: any;
    loading?: boolean;
    documents: any;
    settings: any;
    renderSpaceAtTop?: boolean;
    overrideButtonSection?: (
        onClose?: () => void,
        onBack?: () => void,
        onSubmit?: () => void
    ) => JSX.Element;
    onCloseCallback?: () => void;
    onBackCallback?: () => void;
    onSubmitCallback?: (data?: any) => void;
    handleBidProfileListFetch_d: () => void;
    scrollToTop?: boolean;
    registrationProcess?: boolean;
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
}

const CompanyProfileAddEdit: React.FC<Props> = (props) => {
    const history = useHistory();

    const {
        handleCompanyProfileCreate_d,
        handleCompanyProfileUpdate_d,
        handleCompanyProfileFetch_d,
        handleDocumentDelete_d,
        clearApiResponses_d,
        uploadProfileDocuments,
        retrieveProfileDocuments,
        clearCompanyProfile_d,
        error,
        errorText,
        profile,
        profileId,
        success,
        loading,
        settings,
        renderSpaceAtTop = true,
        overrideButtonSection,
        onCloseCallback,
        onBackCallback,
        onSubmitCallback,
        handleBidProfileListFetch_d,
        scrollToTop = true,
        registrationProcess = false,
        downloadFile_d,
    } = props;
    const [formFields, setFormFields] = React.useState<FormFields>({
        id: "",
        companyName: "",
        tradingName: "",
        registrationNumber: "",
        vatNumber: "",
        contactPersonFirstName: "",
        contactPersonLastName: "",
        email: "",
        phoneNumber: "",
        isDefaultProfile: null,
        address: {
            addressId: "",
            addressLine1: "",
            addressLine2: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            longitude: 0,
            latitude: 0,
        },
    });
    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] =
        React.useState<any>({
            companyName: false,
            tradingName: false,
            registrationNumber: false,
            contactPersonFirstName: false,
            contactPersonLastName: false,
            email: false,
            phoneNumber: false,
            address: {
                addressLine1: false,
                country: false,
                state: false,
                city: false,
                zip: false,
            },
        });

    const intialValuesErrors = {
        phoneNumber: "",
        email: "",
    };
    const [formErrors, setFormErrors] = React.useState(intialValuesErrors);
    const [companyProfileDocuments, setCompanyProfileDocuments] =
        React.useState<any>([] as any);
    const [tabErrorsShow, setTabErrorsShow] = React.useState<any>({
        companyInfo: false,
        addressInfo: false,
        documentInfo: false,
    });
    const [activeTab, setActiveTab] = React.useState("0");

    const [profileDocuments, setProfileDocuments] = useState<any[]>([]);
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

    useEffect(() => {
        if (history.location) {
            const profileId = new URLSearchParams(history.location.search).get("profileId");
            if (profileId) {
                showProgressSpinner({ description: "Retrieving Company Profile..." });
                handleCompanyProfileFetch_d(profileId);
                setLoadingDocuments(true);
                retrieveProfileDocuments(profileId, (res: any) => {
                    if (res.success) setCompanyProfileDocuments(res.response);
                    setLoadingDocuments(false);
                });
            } else {
                clearCompanyProfile_d();
                setLoadingDocuments(true);
                retrieveProfileDocuments(
                    "00000000-0000-0000-0000-000000000000",
                    (res: any) => {
                        if (res.success) setCompanyProfileDocuments(res.response);
                        setLoadingDocuments(false);
                    }
                );
            }
        }
        scrollToTop && window.scroll({ top: 0, left: 0, behavior: "auto" });
    }, []);

    useEffect(() => {
        if (companyProfileDocuments) {
            let newDocList: any[] = [];
            forEach(companyProfileDocuments, (item: any) => {
                if (item.documentResult.documents?.length > 0)
                    forEach(item.documentResult.documents, (document) => {
                        newDocList.push({
                            id: document.id,
                            documentUrl: document.documentUrl,
                            documentTemplateId: item.documentResult.documentTemplate.id,
                            documentTemplateName: item.documentResult.documentTemplate.name,
                            isUploadMandatory:
                                item.documentResult.documentTemplate.dataCaptured.companyProfile
                                    .isUploadMandatory,
                            isAccessDriven:
                                item.documentResult.documentTemplate.dataCaptured.companyProfile
                                    .isAccessDriven,
                            documentBase64String: "",
                            replaceOnExist: true,
                        });
                    });
                else {
                    newDocList.push({
                        id: uuidv4(),
                        documentUrl: "",
                        documentTemplateId: item.documentResult.documentTemplate.id,
                        documentTemplateName: item.documentResult.documentTemplate.name,
                        isUploadMandatory:
                            item.documentResult.documentTemplate.dataCaptured.companyProfile
                                .isUploadMandatory,
                        isAccessDriven:
                            item.documentResult.documentTemplate.dataCaptured.companyProfile
                                .isAccessDriven,
                        documentBase64String: "",
                        replaceOnExist: true,
                    });
                }
            });
            const sortedList = newDocList.sort((a: any, b: any) =>
                b.isUploadMandatory
                    .toString()
                    .localeCompare(a.isUploadMandatory.toString())
            );
            setProfileDocuments(sortedList);
        }
    }, [companyProfileDocuments]);

    useEffect(() => {
        if (success) {
            clearApiResponses_d();
            ns.success("", "Company profile successfully saved");

            let currentProfileId = profileId;
            if (!currentProfileId) {
                currentProfileId = new URLSearchParams(history.location.search).get(
                    "profileId"
                );
            }

            PrembidLocalStorage.upsertProfileToCurrentUser(
                currentProfileId,
                formFields.companyName,
                formFields.email,
                formFields.phoneNumber,
                formFields.isDefaultProfile
            );

            const documentsPayload = {
                profileId: currentProfileId,
                profileDocuments:
                    profileDocuments.filter((x) => x.documentBase64String?.length > 0) ??
                    [],
            };

            if (documentsPayload.profileDocuments.length > 0) {
                showProgressSpinner({ description: "Uploading documents..." });
                uploadProfileDocuments(documentsPayload, (res) => {
                    hideProgressSpinner();
                    if (res.success) {
                        if (onSubmitCallback) {
                            handleBidProfileListFetch_d();
                            onSubmitCallback({ id: currentProfileId });
                        } else history.push("/biddingProfileList");
                    } else {
                        ns.error("", "Failed to upload a document.");
                    }
                });
            } else {
                hideProgressSpinner();
                if (onSubmitCallback) {
                    handleBidProfileListFetch_d();
                    onSubmitCallback({ id: currentProfileId });
                } else history.push("/biddingProfileList");
            }
        }
    }, [success]);

    useEffect(() => {
        if (profile) {
            hideProgressSpinner();

            const newProfile = {
                id: profile.id,
                companyName: profile.companyName,
                tradingName: profile.tradingName,
                registrationNumber: profile.registrationNumber,
                vatNumber: profile.vatNumber,
                contactPersonFirstName: profile.contactPersonFirstName,
                contactPersonLastName: profile.contactPersonLastName,
                email: profile.email,
                phoneNumber: profile.phoneNumber,
                isDefaultProfile: profile.isDefaultProfile,
                address: profile.address,
            };

            setFormFields(newProfile);
        } else {
            setFormFields({
                id: "",
                companyName: "",
                tradingName: "",
                registrationNumber: "",
                vatNumber: "",
                contactPersonFirstName: "",
                contactPersonLastName: "",
                email: "",
                phoneNumber: "",
                isDefaultProfile:false,
                address: {
                    addressId: "",
                    addressLine1: "",
                    addressLine2: "",
                    country: "",
                    state: "",
                    city: "",
                    zip: "",
                    longitude: 0,
                    latitude: 0,
                },
            });
            hideProgressSpinner();
        }
    }, [profile]);

    useEffect(() => {
        if (error) {
            ns.error("", errorText);
            clearApiResponses_d();
            hideProgressSpinner();
        }
    }, [error]);

    const onKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };


    const onChange = (e: any) => {
        const props = e.target.id.split(".");

        if (props[0] === "address") {
            if (e.target.id.includes("zip")) {
                if (!/[0-9]/.test(e.target.value) && e.target.value.length > 0) {
                    e.preventDefault();
                } else {
                    setFormFields({
                        ...formFields,
                        address: { ...formFields.address, [props[1]]: e.target.value },
                    });
                }
            } else {
                setFormFields({
                    ...formFields,
                    address: { ...formFields.address, [props[1]]: e.target.value },
                });
            }

            return;
        }

        if (props[0] === "isDefaultProfile"){
            setFormFields({ ...formFields, [props[0]]: e.target.checked });

            return;
        }
        if (props[0] === "phoneNumber") {
            if (!/[0-9]/.test(e.target.value) && e.target.value.length > 0) {
                e.preventDefault();
            } else {
                setFormFields({ ...formFields, [props[0]]: e.target.value });
            }
        } else {
            setFormFields({ ...formFields, [props[0]]: e.target.value });
        }

       
    };

    const validateRegex = () => {
        let isValid = true;
        if (!isValidEmail(formFields.email.replace(" ", ""))) {
            isValid = false;
            formErrors.email = "Please enter a valid email address.";
            setTabErrorsShow({
                companyInfo: true,
                addressInfo: false,
                documentInfo: false,
            });
        }

        if (!isValidPhoneNumber(formFields.phoneNumber)) {
            isValid = false;
            formErrors.phoneNumber = "Please enter a valid phone number.";
            setTabErrorsShow({
                companyInfo: true,
                addressInfo: false,
                documentInfo: false,
            });
        }
        return isValid;
    };

    const validateAll = () => {
        let isAllValid = false;

        let isCompanyInfoValid = true;
        let isAddressValid = true;
        let isDocumentValid = true;

        let newState: any = {
            companyName: false,
            tradingName: false,
            registrationNumber: false,
            contactPersonFirstName: false,
            contactPersonLastName: false,
            email: false,
            phoneNumber: false,
            address: {
                addressLine1: false,
                country: false,
                state: false,
                city: false,
                zip: false,
            },
        };

        //Validate all properties not in address
        for (const [key, value] of Object.entries(formFieldsValidationShowErrors)) {
            const formFieldsvalue = formFields[key];
            if (!formFieldsvalue || formFieldsvalue === "") {
                isCompanyInfoValid = false;
                SetObjectValue(newState, key, true);
            } else {
                SetObjectValue(newState, key, false);
            }
        }

        for (const [key, value] of Object.entries(
            formFieldsValidationShowErrors.address
        )) {
            const formFieldsvalue = formFields.address[key];
            if (!formFieldsvalue || formFieldsvalue === "") {
                isAddressValid = false;
                SetObjectValue(newState, `address.${key}`, true);
            } else {
                SetObjectValue(newState, `address.${key}`, false);
            }
        }

        {/*removed as per Reece's' request*/ }
        //forEach(profileDocuments, (document) => {
        //    if (
        //        document.isUploadMandatory &&
        //        document.documentBase64String.length === 0 &&
        //        (document.documentUrl?.length ?? 0) === 0
        //    ) {
        //        isDocumentValid = false;
        //    }
        //});

        if (isAddressValid && isCompanyInfoValid) {
            isAllValid = true;
        }

        setFormFieldsValidationShowErrors(newState);
        setTabErrorsShow({
            companyInfo: !isCompanyInfoValid,
            addressInfo: !isAddressValid,
            documentInfo: !isDocumentValid,
        });
        return isAllValid;
    };

    const validateField = (e: any) => {
        const props = e.target.id.split(".");
        if (props[0] === "address") {
            let showError = false;
            if (e.target.value === "") showError = true;

            //setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, address: { [props[1]]: showError } })
        } else {
            let showError = false;
            if (e.target.value === "") showError = true;

            if (e.target.id !== undefined)
                setFormFieldsValidationShowErrors({
                    ...formFieldsValidationShowErrors,
                    [e.target.id]: showError,
                });
            else
                setFormFieldsValidationShowErrors({
                    ...formFieldsValidationShowErrors,
                    [e.target.name]: showError,
                });
        }
    };

    const onCancel = () => {
        if (onCloseCallback) onCloseCallback();
        else history.push(`/biddingProfileList`);
    };

    const onSubmit = () => {
        const isValid = validateAll();

        if (isValid) {
            const isValidRegex = validateRegex();

            if (isValidRegex) {
                showProgressSpinner({ description: "Saving Company Profile..." });
                if (profile) {
                    handleCompanyProfileUpdate_d(formFields);
                } else {
                    handleCompanyProfileCreate_d(formFields);
                }
            } else {
                hideProgressSpinner();
            }
        } else {
            hideProgressSpinner();
            ns.error("", "Please fill in all required fields");
        }
    };

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleFileUpload = (item, file) => {
        let existingDocIndex = profileDocuments.findIndex((x) => x.id === item.id);

        let tempDoc: any = {
            ...(existingDocIndex > -1 ? profileDocuments[existingDocIndex] : {}),
            documentBase64String: file ? file.base64 : "",
        };

        let tempList = [...profileDocuments];

        if (existingDocIndex > -1) {
            tempList.splice(existingDocIndex, 1, tempDoc);
        } else tempList.push(tempDoc);
        setProfileDocuments(tempList);
    };

    const handleViewDocument = (url: string) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
        })
    }

    return (
        <div
            style={{
                background: !registrationProcess && settings?.Styles?.OverridePageBackgroundColor
                    ? settings?.Styles?.OverridePageBackgroundColor
                    : "#ffffff",
            }}
        >
            {renderSpaceAtTop && <div className="mb-5 pt-5 pl-5 pr-5 pb-4 mt-5" />}
            <Container className={renderSpaceAtTop ? "pb-5" : "p-0"}>
                <Card
                    className={
                        "shadow-xxl mr-2 ml-2" + renderSpaceAtTop ? " pt-3 " : " p-3 "
                    }
                    raised={true}
                >
                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "0"}
                            onClick={() => {
                                toggle("0");
                            }}
                        >
                            Company Information
                            {tabErrorsShow.companyInfo && (
                                <div className="badge badge-danger m-1 badge-circle">
                                    Danger
                                </div>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "1"}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            Billing Address Information
                            {tabErrorsShow.addressInfo && (
                                <div className="badge badge-danger m-1 badge-circle">
                                    Danger
                                </div>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "2"}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            Document Information
                            {/*removed as per Reece's' request*/}
                            {/*{tabErrorsShow.documentInfo && (*/}
                            {/*    <div className="badge badge-danger m-1 badge-circle">*/}
                            {/*        Danger*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </ListItem>
                    </List>

                    <div className="p-3">
                        <div
                            className={
                                "tab-item-wrapper " + (activeTab === "0" ? "active" : "")
                            }
                            key={0}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0}
                            >
                                <Grid item lg={12} className="pr-3">
                                    <TextField
                                        value={formFields.companyName}
                                        onChange={onChange}
                                        id="companyName"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Company Name"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.companyName}
                                    />
                                    <TextField
                                        value={formFields.tradingName}
                                        onChange={onChange}
                                        id="tradingName"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Trading Name"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.tradingName}
                                    />
                                    <TextField
                                        value={formFields.contactPersonFirstName}
                                        onChange={onChange}
                                        id="contactPersonFirstName"
                                        fullWidth
                                        variant="outlined"
                                        label="Contact Person First Name"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        required
                                        onBlur={validateField}
                                        error={
                                            formFieldsValidationShowErrors.contactPersonFirstName
                                        }
                                    />
                                    <TextField
                                        value={formFields.contactPersonLastName}
                                        onChange={onChange}
                                        id="contactPersonLastName"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Contact Person Last Name"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.contactPersonLastName}
                                    />
                                    <TextField
                                        value={formFields.email}
                                        onChange={onChange}
                                        id="email"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Email"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.email}
                                        onKeyDown={(e) => e.key === " " && e.preventDefault()}
                                    />
                                    {formErrors.email && (
                                        <Alert className="ml-2" severity="error">
                                            {formErrors.email}
                                        </Alert>
                                    )}
                                    <TextField
                                        value={formFields.phoneNumber}
                                        onKeyPress={onKeyPress}
                                        onChange={onChange}
                                        id="phoneNumber"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Phone Number"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.phoneNumber}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    />
                                    {formErrors.phoneNumber && (
                                        <Alert className="ml-2" severity="error">
                                            {formErrors.phoneNumber}
                                        </Alert>
                                    )}
                                    <TextField
                                        value={formFields.registrationNumber}
                                        onChange={onChange}
                                        id="registrationNumber"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Registration Number"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.registrationNumber}
                                    />
                                    <TextField
                                        value={formFields.vatNumber}
                                        onChange={onChange}
                                        id="vatNumber"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Vat Number"
                                    />
                                    {
                                        formFields.isDefaultProfile!==null && (
                                            <FormControlLabel
                                                style={{marginLeft:2}}
                                                control={
                                                    <Switch
                                                        id='isDefaultProfile'
                                                        onChange={onChange}
                                                        className="m-2 p-2 switch-medium toggle-switch-success"
                                                        checked={formFields.isDefaultProfile ?? false}
                                                    />
                                                }
                                                label="Is Default Profile"
                                            />

                                        )
                                    }
                                </Grid>
                            </Grid>
                        </div>

                        <div
                            className={
                                "tab-item-wrapper " + (activeTab === "1" ? "active" : "")
                            }
                            key={1}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0}
                            >
                                <Grid item lg={12} className="pr-3">
                                    <TextField
                                        value={formFields.address.addressLine1}
                                        onChange={onChange}
                                        id="address.addressLine1"
                                        fullWidth
                                        variant="outlined"
                                        label="Billing Address Line 1"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.address.addressLine1}
                                    />
                                    <TextField
                                        value={formFields.address.addressLine2}
                                        onChange={onChange}
                                        id="address.addressLine2"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Billing Address Line 2"
                                    />
                                </Grid>

                                <Grid item lg={6} className="pr-3">
                                    <TextField
                                        value={formFields.address.country}
                                        onChange={onChange}
                                        id="address.country"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Country"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.address.country}
                                    />
                                </Grid>
                                <Grid item lg={6} className="pr-3">
                                    <TextField
                                        value={formFields.address.state}
                                        onChange={onChange}
                                        id="address.state"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="State / Province"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.address.state}
                                    />
                                </Grid>
                                <Grid item lg={6} className="pr-3">
                                    <TextField
                                        value={formFields.address.city}
                                        onChange={onChange}
                                        id="address.city"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="City"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.address.city}
                                    />
                                </Grid>
                                <Grid item lg={6} className="pr-3">
                                    <TextField
                                        value={formFields.address.zip}
                                        onKeyPress={onKeyPress}
                                        onChange={onChange}
                                        id="address.zip"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className='m-1 mt-2'
                                        label="Zip/Postal Code"
                                        required
                                        onBlur={validateField}
                                        error={formFieldsValidationShowErrors.address.zip}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div
                            className={
                                "tab-item-wrapper " + (activeTab === "2" ? "active" : "")
                            }
                            key={2}
                        >
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableCell>Document Name</TableCell>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                        </TableHead>
                                        <TableBody>
                                            {loadingDocuments && (
                                                <TableCell
                                                    scope="row"
                                                    colSpan={4}
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <p>Loading profile documents...</p>
                                                    <div
                                                        className="d-flex flex-direction-column align-items-center justify-content-center"
                                                        style={{ paddingBottom: 20 }}
                                                    >
                                                        <CircularProgress
                                                            className="text-center"
                                                            style={{
                                                                margin: "auto",
                                                                color: settings?.Styles
                                                                    ?.OverrideLoadingSpinnerColor
                                                                    ? settings?.Styles
                                                                        ?.OverrideLoadingSpinnerColor
                                                                    : settings?.Styles?.Primary,
                                                            }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            )}
                                            {!loadingDocuments &&
                                                profileDocuments.map((row, index) => (
                                                    <TableRow key={index} 
                                                        className='m-1 mt-2'>
                                                        <TableCell hidden component="th" scope="row">
                                                            {row.documentId}
                                                        </TableCell>
                                                        <TableCell hidden component="th" scope="row">
                                                            {row.documentTemplateId}
                                                        </TableCell>
                                                        <TableCell scope="row">
                                                            {row.documentTemplateName}
                                                        </TableCell>
                                                        <TableCell scope="row">
                                                            {row.documentUrl &&
                                                                row.documentUrl.length > 0 && (
                                                                    <a
                                                                        className="text-info"
                                                                        href={undefined}
                                                                        onClick={() => handleViewDocument(row.documentUrl)}
                                                                        style={{
                                                                            textDecoration: 'underline', cursor: 'pointer'
                                                                        }}
                                                                    >View</a>
                                                                )}
                                                        </TableCell>
                                                        <TableCell scope="row">
                                                            <FileUploader
                                                                uploadedFile={row.documentBase64String}
                                                                buttonFileUploadedText={"Attached"}
                                                                acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                                                                onFileUpload={(file) => handleFileUpload(row, file)}
                                                                maxImageSize={10240}
                                                                maxFileSize={10240}
                                                                buttonSize={120}
                                                                buttonText={"Upload"}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </Card>
                {overrideButtonSection ? (
                    <div style={{ marginTop: 10 }}>
                        {overrideButtonSection(onCancel, onBackCallback, onSubmit)}
                    </div>
                ) : (
                    <div className="text-right mt-2 mr-2 ml-2">
                        <Button
                            onClick={onCancel}
                            variant="contained"
                            className="btn-danger p-3 text-white m-1 text-capitalize"
                            size="large"
                        >
                            <span className="btn-wrapper--icon">
                                <FaTimes />
                            </span>
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>

                        <Button
                            onClick={onSubmit}
                            variant="contained"
                            className="btn-primary p-3 text-white m-1 text-capitalize"
                            size="large"
                        >
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                            <span className="btn-wrapper--label">Save</span>
                        </Button>
                    </div>
                )}

                {error && <Alert severity="error">{errorText}</Alert>}
            </Container>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleBidProfileListFetch_d: () => dispatch(handleBidProfilesFetch()),
        handleCompanyProfileCreate_d: (formFields: any) =>
            dispatch(handleCompanyProfileCreate(formFields)),
        handleCompanyProfileUpdate_d: (formFields: any) =>
            dispatch(handleCompanyProfileUpdate(formFields)),
        handleCompanyProfileFetch_d: (id: string) =>
            dispatch(handleCompanyProfileFetch(id)),
        handleDocumentDelete_d: (documentId: string) =>
            dispatch(handleDocumentDelete(documentId)),
        clearApiResponses_d: () => dispatch(clearApiResponses()),
        clearCompanyProfile_d: () => dispatch(clearCompanyProfile()),
        retrieveProfileDocuments: (
            profileId: string,
            onCompletedCallback: (res: any) => void
        ) =>
            dispatch(
                retrieveProfileDocuments(
                    profileId,
                    "companyProfile",
                    onCompletedCallback
                )
            ),
        uploadProfileDocuments: (
            data: any,
            onCompletedCallback: (res: any) => void
        ) =>
            dispatch(uploadRegistrationProcessDocuments(data, onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    error: state.companyProfile.error,
    errorText: state.companyProfile.errorText,
    reload: state.companyProfile.reload,
    success: state.companyProfile.success,
    profile: state.companyProfile.profile,
    profileId: state.companyProfile.profileId,
    documents: state.documents.documents,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyProfileAddEdit);
