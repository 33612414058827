import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';

//Action
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_ERROR = 'CONFIRM_ACCOUNT_ERROR';
export const CONFIRM_ACCOUNT_RESET_STATE = 'CONFIRM_ACCOUNT_RESET_STATE';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONFIRM_ACCOUNT_SUCCESS: return { ...state, success: true, error: false }
        case CONFIRM_ACCOUNT_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        case CONFIRM_ACCOUNT_RESET_STATE: return { ...state }
        default: return state;
    }
}

//Action creator

export const confirmAccountSuccess = (token: any) => {
    return { type: CONFIRM_ACCOUNT_SUCCESS, payload: token }
}

export const confirmAccountError = (errorText: string) => {
    return { type: CONFIRM_ACCOUNT_ERROR, payload: errorText }
}

export const confirmAccountResetState = () => {
    return { type: CONFIRM_ACCOUNT_RESET_STATE, payload: CONFIRM_ACCOUNT_RESET_STATE }
}

//Thunks
export const handleSubmit = (userId: string, tokenId: string) => {

    let userInfo = {
        userId: userId,
        tokenId: tokenId,
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.confirmAccount, userInfo)
            .then(function (response) {
                if (response.success) {
                    dispatch(confirmAccountSuccess('Success'))
                }
                else {
                    dispatch(confirmAccountError(response.error))
                }
            })
    }

}