import React, { FC, memo, CSSProperties } from "react";

import { useCardBadgeRowStyles } from "./CardBadgeRow.styles";
import { Button, Tooltip } from "@mui/material";
import { getSTCStatus } from "@/lib/utils/General";

import { TooltipInfoContent } from "@/components/library/TooltipInfoContent";

import { FaInfoCircle } from "react-icons/fa";
import { AuctionTypeEnum } from "@/lib/utils/AuctionTypeEnum";
import { MyActivityWishlist } from "../../containers/MyActivity";
import { GeneralPopper } from "../../composite/popper/GeneralPopper";

export interface CardBadgeRowProps {
    containerStyle?: CSSProperties;
    isActive: boolean;
    stcStatus: string;
    now: Date;
    start: Date;
    end: Date;
    hasExtensions: boolean;
    commission: number;
    wishListStatus: boolean;
    lotId: string;
    hasWishListIcon: boolean;
    auctionType: AuctionTypeEnum;
    settings: any;
    validating: boolean;
}

const CardBadgeRow: FC<CardBadgeRowProps> = ({
    isActive,
    stcStatus,
    now,
    start,
    end,
    hasExtensions,
    commission,
    wishListStatus,
    lotId,
    hasWishListIcon,
    auctionType,
    settings,
    validating
}) => {
    const { classes: { cardBadgeRow, badgeSection, controlsSection, badgeItem } } =
        useCardBadgeRowStyles();

    const iconSize = 17;

    const [headerAnchorEl, setHeaderAnchorEl] =
        React.useState<null | HTMLElement>(null);

    return (
        <div className={cardBadgeRow}>
            <div className={badgeSection}>
                {!isActive ? (
                    <div
                        className={`${badgeItem} badge badge-pill badge-dark text-capitalize`}
                    >
                        Unavailable
                    </div>
                ) : validating ? (
                    <div className={`${badgeItem} badge badge-pill badge-dark text-capitalize`}>
                        Validating
                    </div>
                ) : (
                    <>
                        {now < start && (
                            <div
                                className={`${badgeItem} badge badge-pill badge-dark text-capitalize`}
                            >
                                Awaiting
                            </div>
                        )}
                        {start < now && now < end && hasExtensions && (
                            <div
                                className={`${badgeItem} badge badge-pill badge-warning text-capitalize `}
                            >
                                Extended
                            </div>
                        )}
                        {start < now && now < end && !hasExtensions && (
                            <div
                                className={`${badgeItem} badge badge-pill badge-success text-capitalize`}
                            >
                                Open
                            </div>
                        )}
                        {now > end && (
                            <div
                                className={`${badgeItem} badge badge-pill badge-danger text-capitalize`}
                            >
                                Completed
                            </div>
                        )}
                    </>
                )}
                <div
                    className={`${badgeItem} badge badge-pill badge-dark text-capitalize`}
                    style={{ background: "#2B2B2B" }}
                >
                    {getSTCStatus(stcStatus)}
                </div>
                <div
                    className={`${badgeItem} badge badge-pill badge-primary text-capitalize`}
                >
                    {`BC ${commission}%`}
                </div>
            </div>

            <div className={controlsSection}>
                <Tooltip arrow title="Click for Info" style={{ zIndex: 0 }}>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        size="large"
                        className="btn-white btn-pill p-0 btn-icon btn-animated-icon align-items-right"
                        onClick={(event) => setHeaderAnchorEl(event.currentTarget)}
                    >
                        {headerAnchorEl && (
                            <GeneralPopper
                                popperStyle={{ zIndex: 11 }}
                                headerText={"Info"}
                                anchorEl={headerAnchorEl}
                                element={
                                    <TooltipInfoContent
                                        isActive={isActive}
                                        now={now}
                                        start={start}
                                        end={end}
                                        hasExtensions={hasExtensions}
                                        auctionType={auctionType}
                                        settings={settings}
                                        validating={validating}
                                    />
                                }
                                handleGeneralPopperClose={() => setHeaderAnchorEl(null)}
                            />
                        )}
                        <FaInfoCircle size={iconSize} style={{ color: '#2b2b2b' }} color={'#2b2b2b'} />
                    </Button>
                </Tooltip>

                {
                    isActive &&
                    <MyActivityWishlist
                        lotId={lotId}
                        isOnWishlist={wishListStatus}
                        utilityClasses="btn-white btn-pill p-0 btn-icon btn-animated-icon align-items-right"
                        size={iconSize}
                    />
                }
            </div>
        </div>
    );
};

export default memo(CardBadgeRow);
