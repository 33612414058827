import { CircularProgress, Divider, Grid, TextField } from "@mui/material";
import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux';
import { retrieveRegistrationProcessStatus, updateProfileAddress, updateState } from "../store/RegistrationModal";
import FooterButtons from "./FooterButtons";
import ns from '@/lib/utils/NotificationService';


interface AddressCaptureProps {
    onClose: () => void;
    onNextStep: () => void;
    onBackStep: () => void;
    settings: any,
    state: any,
    updateState: (data: any) => void,
    updateProfileAddress: (data: any, onCompletedCallback?: (res?: any) => void) => void,
    retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => void
}

export const AddressCapture: React.FC<AddressCaptureProps> = props => {
    const { onClose, onNextStep, onBackStep, state, updateState, settings, updateProfileAddress, retrieveRegistrationProcessStatus } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const [addressFormFields, setAddressFormFields] = useReducer((state, payload) => {
        return { ...state, ...payload }
    }, {
        addressLine1: state.registrationProcessStatus?.profileAddressData?.addressLine1 ?? '',
        addressLine2: state.registrationProcessStatus?.profileAddressData?.addressLine2 ?? '',
        country: state.registrationProcessStatus?.profileAddressData?.country ?? '',
        state: state.registrationProcessStatus?.profileAddressData?.state ?? '',
        city: state.registrationProcessStatus?.profileAddressData?.city ?? '',
        zip: state.registrationProcessStatus?.profileAddressData?.zip ?? '',
    });

    const [addressFormFieldsValidationShowErrors, setAddressFormFieldsValidationShowErrors] = useState<any>({
        addressLine1: false,
        country: false,
        state: false,
        city: false,
        zip: false,
    });

    useEffect(() => {
        retrieveRegistrationProcessStatus(state.selectedProfileId, state.auctionId, state.profileType, (res: any) => {
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        if (!loading && state.registrationProcessStatus && state.registrationProcessStatus.profile) {
            onNextStep();
        }
    }, [loading, state.registrationProcessStatus])

    const handleOnClose = () => {
        //Custom logic
        onClose();
    }

    const handleBack = () => {
        //Custom logic
        onBackStep();
    }


    const validateAddress = () => {
        let isAddressValid = true;

        let newState = {
            addressLine1: false,
            country: false,
            state: false,
            city: false,
            zip: false,
        }

        for (const [key] of Object.entries(addressFormFieldsValidationShowErrors)) {
            const addressFormFieldsvalue = addressFormFields[key];
            if (addressFormFieldsvalue === '') {
                isAddressValid = false;
                newState[key] = true;
            }
            else {
                newState[key] = false;
            }
        }

        setAddressFormFieldsValidationShowErrors(newState)

        return isAddressValid
    }

    const handleSubmit = () => {
        setSubmitted(true);
        if (!validateAddress()) return;

        updateProfileAddress({ profileId: state.selectedProfileId, ...addressFormFields }, (res: any) => {
            if (res.success) {
                onNextStep();
            }
            else {
                ns.error('', 'Failed to update your profile address.');
            }
        })
    }

    const onAddressChange = (e: any) => {
        setAddressFormFields({ [e.target.id]: e.target.value });
    }

    return <div style={{ width: 500 }}>
        <h5 style={{ marginTop: 10 }}>Billing Address Capture</h5>
        <Divider style={{ marginBottom: 10 }} />
        {loading && <><p>Loading profile...</p>
            <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ paddingBottom: 20 }}>
                <CircularProgress className="text-center" style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
            </div>
        </>}
        {!loading && <>
            <p>Please capture your billing address details below in order to proceed to the next step. All mandatory fields are marked with an asterisk (*).</p>
            <Grid
                container
                direction="row"
                spacing={1}
                style={{ paddingBottom: 20 }}
            >
                <Grid item xs={12}>
                    <TextField
                        value={addressFormFields.addressLine1}
                        onChange={onAddressChange}
                        id="addressLine1"
                        fullWidth
                        variant="outlined"
                        label="Billing Address Line 1"
                        size="small"
                        onBlur={submitted ? () => validateAddress() : undefined}
                        required
                        error={addressFormFieldsValidationShowErrors.addressLine1}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={addressFormFields.addressLine2}
                        onChange={onAddressChange}
                        id="addressLine2"
                        fullWidth
                        variant="outlined"
                        label="Billing Address Line 2"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={addressFormFields.country}
                        onChange={onAddressChange}
                        id="country"
                        fullWidth
                        variant="outlined"
                        label="Country"
                        size="small"
                        required
                        error={addressFormFieldsValidationShowErrors.country}
                        onBlur={submitted ? () => validateAddress() : undefined}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={addressFormFields.state}
                        onChange={onAddressChange}
                        id="state"
                        fullWidth
                        variant="outlined"
                        label="State / Province"
                        size="small"
                        required
                        error={addressFormFieldsValidationShowErrors.state}
                        onBlur={submitted ? () => validateAddress() : undefined}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={addressFormFields.city}
                        onChange={onAddressChange}
                        id="city"
                        fullWidth
                        variant="outlined"
                        label="City"
                        size="small"
                        required
                        error={addressFormFieldsValidationShowErrors.city}
                        onBlur={submitted ? () => validateAddress() : undefined}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        value={addressFormFields.zip}
                        onChange={onAddressChange}
                        id="zip"
                        fullWidth
                        variant="outlined"
                        label="Zip/Postal Code"
                        size="small"
                        required
                        error={addressFormFieldsValidationShowErrors.zip}
                        onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()}
                        onBlur={submitted ? () => validateAddress() : undefined}
                    />
                </Grid>
            </Grid>
        </>}
        <FooterButtons onClose={handleOnClose} onSubmit={handleSubmit} />
    </div>
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})


const mapDispatchToProps = (dispatch: any) => {
    return {
        retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => dispatch(retrieveRegistrationProcessStatus(profileId, auctionId, profileType, onCompletedCallback)),
        updateState: (data: any) => dispatch(updateState(data)),
        updateProfileAddress: (data: any, onCompletedCallback?: (res?: any) => void) => dispatch(updateProfileAddress(data, onCompletedCallback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressCapture)