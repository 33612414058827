import { ScreenWrapper } from '@/components/wrappers/ScreenWrapper';
import { LotBidAndInfoSection } from '../../components/containers/LotBidAndInfoSection';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';
import { Container, Grid } from '@mui/material';
import { useLotBidAndInfoStyles } from './LotBidAndInfo.styles';
import { BiddingTable } from '../../components/containers/BiddingTable';
import { LotClosingValidation } from 'prembid-shared-library-npm/types';
import { useParams } from 'react-router';
import { LotContactList } from '../../components/containers/LotContactList';
import { FC } from 'react';
import { HeaderBlock } from '../../components/composite/HeaderBlock';
import { EnquiryForm } from '../../components/composite/EnquiryForm';
import { SectionTitle } from '../../components/dumb/SectionTitle';

const LotBidAndInfo: FC = () => {

    const { classes: { containerWidth } } = useLotBidAndInfoStyles();
    const { auctionId, lotId }: any = useParams();

    const { settings } = useSelector((state: RootState) => state.settings);

    return (
        <ScreenWrapper>
            <div className="mb-5">
                <HeaderBlock imageSrc={settings.VendorBannerUrl} />
            </div>

            <div className="text-center mb-5">
                <SectionTitle
                    title={"Own this exquisite " + settings?.LotName?.toLowerCase() + " by taking part in the " + settings?.AuctionName?.toLowerCase()} />
            </div>

            <Container maxWidth={false} className={containerWidth}>
                <Grid container className="mb-5" spacing={5}>
                    <Grid item xs={12} md={7}>
                        <LotBidAndInfoSection auctionId={auctionId} lotId={lotId} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div className="mb-4">
                            <BiddingTable auctionId={auctionId} lotId={lotId} showCompactView={false} />
                        </div>

                        <div className="mb-4">
                            <LotContactList lotId={lotId} />
                        </div>

                        <EnquiryForm lotId={lotId} />
                    </Grid>
                </Grid>
            </Container>
        </ScreenWrapper>
    )
};

export default LotBidAndInfo;