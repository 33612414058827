import React from "react";
import { NumericFormat } from 'react-number-format';
import { CurrencyComponent } from "../../dumb/currency/CurrencyComponent";

interface Props {
    value: string | number | undefined
    prefixString?: string,
    suffixString?: string,
    textColour?: string,
    style?: any;
}

const CurrencyLabel: React.FC<Props> = (props) => {
    const { value, prefixString, suffixString, textColour, style } = props;
    return (
        <NumericFormat
            value={value}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            thousandSeparator=","
            style={style}
            renderText={(value) => <CurrencyComponent style={style} priceWithSymbol={value} prefixString={prefixString} suffixString={suffixString} textColour={textColour} />} />
    );
};

export default CurrencyLabel;