import React, { useEffect, useState, useRef } from 'react';
import { connect, } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearAuctionData, handleAuctionByIdFetch, } from '@/pages/Auctions/store/Auctions';
import { clearResponses, clearLotFilteredResponses, handleAllLotTypesLookupsFetch, handleLotsFilteredDataFetch, clear, handleSetLotViewType, handleClearLotTypeView, handleSetFilterObjectData, handleClearFilterObject } from './store/Lots';
import LotDetailCard from '@/components/Cards/LotDetailCard';
import ns from '@/lib/utils/NotificationService';
import {
    Container,
    Grid,
    Divider,
    Select,
    MenuItem,
    InputAdornment,
    TextField,
    Checkbox,
    Card,
    Button,
    Tooltip,
    CircularProgress,
    Box
} from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from '@/lib/utils/ProgressSpinnerService';
import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage';
import ParticipateButton from "@/components/Buttons/ParticipateButton";
import ReactReadMoreReadLess from "@/components/ReadMoreReadLess/ReadMoreReadLess";
import { MdDateRange } from "react-icons/md";
import { ImListNumbered } from "react-icons/im";
import { FaGavel, FaMoneyBillAlt, FaSearch, FaUndo } from "react-icons/fa";
import { AuctionTypeEnum } from '@/lib/utils/AuctionTypeEnum';
import { handleRegisteredUsersValidate } from '@/components/Buttons/store/Participate';
import clsx from 'clsx';
import StickyBox from "react-sticky-box";
import { View, ViewModeToggle } from "@/components/library/ViewModeToggle";
import { CompactLotCard } from '@/components/library/CompactLotCard';
import { formatDateTime, replaceUrlParams } from '../../lib/utils/General';
import useHistory from '../../lib/hooks/useHistory';
import { useSignalRConnectionStatus } from 'prembid-shared-library-npm/signalr';
import useStyles from './LotsStyles'
import AuctionDetailCardDocumentSelector from '../../components/Cards/AuctionDetailCardDocumentSelector';
import { HeaderBlock } from '../../components/composite/HeaderBlock';
import { VideoCarousel } from '../../components/composite/VideoCarousel';
import { Pagination } from '../../components/composite/Pagination';
import { CurrencyLabel } from '../../components/composite/CurrencyLabel';

interface Props {
    handleLotsFilteredDataFetch_d: (auctionId: any, lotTypeIds: any[], searchString: string, lotNumber: string, sortBy: string, resetToPageOne: boolean, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    handleAllLotTypesLookupsFetch_d: (auctionId: any, onCompletedCallback: (res: any) => void) => void,
    handleRegisteredUsersValidate_d: (auctionId: string, profileId: string) => void,
    clearResponses_d: () => void,
    clearLotFilteredResponses_d: () => void,
    clear_d: () => void,
    clearAuctionData_d: () => void,
    handleSetLotViewType_d: (viewType: any) => void,
    handleClearLotTypeView_d: (viewType: any, onCompletedCallback: () => void) => void,
    handleSetFilterObjectData_d: (filterObjectData: any, onCompletedCallback?: () => void) => void,
    handleClearFilterObject_d: (onCompletedCallback?: () => void) => void,
    auctionData: any,
    errorText: any,
    error: any,
    success: any
    priceWithSymbol?: any,
    pageLoading: boolean,
    settings: any,
    registrationStatus: any[],
    lotsFiltered: any,
    pagination?: any,
    changeProfileId: any,
    logoutSuccess: any,
    viewType: any;
    filterDataObject: any,
}

const Lots: React.FC<Props> = props => {

    const {
        handleAuctionByIdFetch_d,
        handleLotsFilteredDataFetch_d,
        handleAllLotTypesLookupsFetch_d,
        handleRegisteredUsersValidate_d,
        clearResponses_d,
        clear_d,
        clearAuctionData_d,
        handleSetLotViewType_d,
        handleSetFilterObjectData_d,
        handleClearFilterObject_d,
        auctionData,
        error,
        errorText,
        pageLoading,
        settings,
        registrationStatus,
        lotsFiltered,
        pagination,
        changeProfileId,
        logoutSuccess,
        viewType = settings.DefaultLotView,
        filterDataObject,
        clearLotFilteredResponses_d
    } = props;


    const { classes: { compactLotCardsContainer, containerWidth } } = useStyles()
    const initialScreenLoad = useRef(true);
    const { auctionId }: any = useParams();

    const history = useHistory();

    let sortBy: string[] = ["Number: Ascending", "Number: Descending", "Opening Bid: Low to High", "Opening Bid: High to Low", "Time: Remaining"];
    const initialFilters = {
        searchText: "",
        lotTypeIds: [],
        lotNumber: "",
        orderBy: sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")],
    };
    const [allLotTypes, setAllLotTypes] = React.useState<any>([])
    const [gridLoading, setGridLoading] = React.useState<boolean>(false);
    const [lastTop, setLastTop] = useState<any>(0);
    const [searchbarOffset, setSearchbarOffset] = useState<number>(140);
    const [searchFiltersOffset, setSearchFiltersOffset] = useState<number>(240);
    const [filterFields, setFilterFields] = useState<any>(filterDataObject ?? initialFilters);
    const [resetFilters, setResetFilters] = useState(false);
    const urlSearchParams: any = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());



    useEffect(() => {
        if (!initialScreenLoad.current) {
            if (auctionId === auctionData?.id) {
                if (logoutSuccess) loadFilteredLotData(false, false, false, null, pagination ?? params);
                else if (resetFilters) loadFilteredLotData(true, true, true, settings.DefaultLotOrdering ? settings.DefaultLotOrdering : "Number: Ascending", pagination ?? params);
                else loadFilteredLotData(true, false, false, null, pagination ?? params);
            }
        }
    }, [auctionId, auctionData, resetFilters, logoutSuccess, viewType, changeProfileId])

    useEffect(() => {
        handleSetFilterObjectData_d(filterFields, function () { })
    }, [filterFields])

    useEffect(() => {
        if (initialScreenLoad.current) {
            showProgressSpinner({ description: "Retrieving " + settings.LotName + "s..." });
            window.scroll({ top: 0, left: 0, behavior: 'auto' })
            initialScreenLoad.current = false;
        }

        setLastTop(params?.top ?? 0);

        clear_d();

        if (auctionId) {
            loadAuction();
            loadLotTypes();
        }
    }, [auctionId, changeProfileId, clear_d, settings.LotName]);

    useEffect(() => {
        if (!initialScreenLoad.current) {
            if (!lastTop && pagination && ((params?.currentPage && params.currentPage !== pagination.currentPage) || pagination.currentPage > 1)) {
                const { total, ...rest } = pagination;
                replaceUrlParams(rest);
            }
        }
    }, [pagination, lastTop])

    useEffect(() => {
        if (!pageLoading && lastTop) {
            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(0)
        }
    }, [pageLoading])

    useEffect(() => {
        if (auctionId && changeProfileId) {
            handleRegisteredUsersValidate_d(auctionId, changeProfileId);
        }
    }, [changeProfileId])

    useEffect(() => {
        if (error) {
            ns.error('', errorText);
            clearResponses_d();
        }
    }, [error]);

    const scrollToTop = () => {
        window.scroll({ top: 750, left: 0, behavior: 'auto' })
    }

    const loadAuction = () => {
        handleAuctionByIdFetch_d(auctionId, (res: any) => {
            if (!res.success) {
                ns.error('', res.error);
            }
            hideProgressSpinner();
        });
    }

    const loadLotTypes = () => {
        let lotTypesArr: any[] = [];

        handleAllLotTypesLookupsFetch_d(auctionId, (res) => {
            if (res.success) {
                res.response.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        lotTypesArr.push({ ...x, key: x.name });
                });
                setAllLotTypes(lotTypesArr);
            } else {
                ns.error('', res.error);
            }
        })
    }

    const loadFilteredLotData = (showSpinner: boolean, mustScrollToTop: boolean, resetToPageOne: boolean, sortValueParam?: string | null, pagination?: any) => {
        if (showSpinner) {
            setGridLoading(true);
        }
        let sortBy;
        if (sortValueParam) {
            sortBy = sortValueParam
        } else if (filterDataObject?.orderBy) {
            sortBy = filterDataObject?.orderBy;
        } else {
            sortBy = settings.DefaultLotOrdering ? settings.DefaultLotOrdering : "Number: Ascending";
        }
        handleLotsFilteredDataFetch_d(auctionId, filterDataObject?.lotTypeIds, filterDataObject?.searchText, filterDataObject?.lotNumber, sortBy, resetToPageOne, pagination, (res: any) => {
            setGridLoading(false);
            setResetFilters(false);

            if (mustScrollToTop) scrollToTop();
        });
    }

    const onApplyClicked = () => {
        handleSetFilterObjectData_d(filterFields, function () {
            loadFilteredLotData(true, true, true, null, pagination ?? params);
        })
    }

    const onHandlePageChanged = (pagination?: any) => {
        loadFilteredLotData(true, true, false, null, pagination ?? params);
    }

    const onChangeCheckbox = (id: any) => {
        let selectedLotTypes: any[] = [...filterFields?.lotTypeIds];

        if (selectedLotTypes.includes(id)) {
            const indexOfItem = selectedLotTypes.indexOf(id);
            selectedLotTypes.splice(indexOfItem, 1);
        } else {
            selectedLotTypes.push(id);
        }
        setFilterFields({ ...filterFields, lotTypeIds: selectedLotTypes });
    }

    const handleKeyPressEvent = (e: any) => {
        if (e.key === "Enter") {
            loadFilteredLotData(true, true, true, null, pagination ?? params);
        }
    }

    const handleSortByValueChanged = (e: any) => {
        let index = sortBy.findIndex((item) => item === e.target.value)
        setFilterFields({ ...filterFields, orderBy: sortBy[index] });
        loadFilteredLotData(true, true, true, sortBy[index], pagination ?? params);
    }

    function getLotFeatures(lot: any) {
        let featuresArr: any[] = [];

        if (lot?.dataCaptured?.additionalProperties) {
            lot?.dataCaptured?.additionalProperties.map(x => {
                if (x.name !== '' && x.value !== '' && !x.hidden)
                    featuresArr.push({ ...x, key: x.name })
            });

            featuresArr.sort((a, b) => a.order - b.order);
        }

        return featuresArr;
    }

    function VideoComponent() {
        if (Number(auctionData?.auctionType) === Number(AuctionTypeEnum.Streamed.toString())) {
            return <>
                <div className="mb-5">
                    <div className="text-center">
                        <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>{"Please Join us for Our Live Streamed " + settings.AuctionName + "!"}</h3>
                        <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{"Our live stream will be available below on the start date and at the time indicated."}</h4>
                        <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{"You are welcome to join us at the physical " + settings.AuctionName.toLowerCase() + ", please contact our general enquiries for further information."}</h4>
                    </div>
                    {
                        auctionData?.streamUrl ?
                            <VideoCarousel videos={[auctionData?.streamUrl]} showThumbnails={false} />
                            : <img style={{ width: '100%' }} src="https://storage.googleapis.com/prembid/LiveStreamUnavailable.png" />
                    }
                </div>
            </>
        } else {
            return <></>
        }
    }

    const btnCatalogueRoute = () => {
        const win: any = window.open('/Catalog/' + auctionId, "_blank");
        win.focus();
    }

    useSignalRConnectionStatus({
        onConnect: () => {
            loadAuction();
            loadFilteredLotData(true, false, false, null, pagination ?? params);
        },
    });

    return (
        <div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
            <HeaderBlock imageSrc={settings?.VendorBannerUrl} />
            <Box className="pt-5 pb-5" style={{ background: settings?.Styles?.OverrideHeaderSectionBackgroundColor ? settings?.Styles?.OverrideHeaderSectionBackgroundColor : "#2b2b2b", position: 'relative', zIndex: 1 }}>
                <Container maxWidth={false} className={containerWidth}>
                    <div className="text-center mb-5">
                        {
                            auctionData &&
                            <h3 className="font-weight-bold" style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{auctionData?.name}</h3>
                        }
                    </div>

                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        className="mb-5">
                        <Grid item md={3} xs={6}>
                            <div className="d-flex flex-direction-row">
                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center" style={{ background: settings?.Styles?.OverrideHeaderSectionIconBackground ? settings?.Styles?.OverrideHeaderSectionIconBackground : settings?.Styles?.Primary }}>
                                    <FaGavel className="font-size-lg center" style={{ color: settings?.Styles?.OverrideHeaderSectionIconText ? settings?.Styles?.OverrideHeaderSectionIconText : "#ffffff" }} />
                                </div>
                                <div className="mt-0">
                                    <h5 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{settings.AuctionName + " Type"}</h5>
                                    {auctionData && <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionSubTextColor ? settings?.Styles?.OverrideHeaderSectionSubTextColor : "#ffffff" }}>
                                        {AuctionTypeEnum[auctionData?.auctionType]}
                                    </h6>}
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={3} xs={6}>
                            <div className="d-flex flex-direction-row">
                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center" style={{ background: settings?.Styles?.OverrideHeaderSectionIconBackground ? settings?.Styles?.OverrideHeaderSectionIconBackground : settings?.Styles?.Primary }}>
                                    <FaMoneyBillAlt className="font-size-lg center" style={{ color: settings?.Styles?.OverrideHeaderSectionIconText ? settings?.Styles?.OverrideHeaderSectionIconText : "#ffffff" }} />
                                </div>
                                <div className="mt-0">
                                    <h5 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{"Registration Deposit"}</h5>
                                    {auctionData && <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionSubTextColor ? settings?.Styles?.OverrideHeaderSectionSubTextColor : "#ffffff" }}>
                                        <CurrencyLabel value={auctionData?.depositAmount} prefixString='' textColour={"text-white"} />
                                    </h6>}
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={3} xs={6}>
                            <div className="d-flex flex-direction-row">
                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center" style={{ background: settings?.Styles?.OverrideHeaderSectionIconBackground ? settings?.Styles?.OverrideHeaderSectionIconBackground : settings?.Styles?.Primary }}>
                                    <ImListNumbered className="font-size-lg" style={{ color: settings?.Styles?.OverrideHeaderSectionIconText ? settings?.Styles?.OverrideHeaderSectionIconText : "#ffffff" }} />
                                </div>
                                <div className="mt-0">
                                    <h5 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{"No. of " + settings.LotName + "s"}</h5>
                                    <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionSubTextColor ? settings?.Styles?.OverrideHeaderSectionSubTextColor : "#ffffff" }}>
                                        {pagination?.total ?? 0}
                                    </h6>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={3} xs={6}>
                            <div className="d-flex flex-direction-row">
                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center" style={{ background: settings?.Styles?.OverrideHeaderSectionIconBackground ? settings?.Styles?.OverrideHeaderSectionIconBackground : settings?.Styles?.Primary }}>
                                    <MdDateRange className="font-size-lg center" style={{ color: settings?.Styles?.OverrideHeaderSectionIconText ? settings?.Styles?.OverrideHeaderSectionIconText : "#ffffff" }} />
                                </div>
                                <div className="mt-0">
                                    {
                                        auctionData &&
                                        <>
                                            <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionSubTextColor ? settings?.Styles?.OverrideHeaderSectionSubTextColor : "#ffffff" }}>{"Start: " + new Date(auctionData?.startDateTimeAt).toDateString() + " " + formatDateTime(auctionData?.startDateTimeAt, "hh:mm a")}</h6>
                                            <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionSubTextColor ? settings?.Styles?.OverrideHeaderSectionSubTextColor : "#ffffff" }}>{"End: " + new Date(auctionData?.endDateTimeAt).toDateString() + " " + formatDateTime(auctionData?.endDateTimeAt, "hh:mm a")}</h6>
                                        </>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        className="mb-5"
                        spacing={3}>
                        <Grid item sm={6}>
                            <Grid container
                                spacing={0}>
                                <Grid item sm={1} style={{ marginRight: -25 }}>
                                    <Divider style={{ width: 1, background: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }} orientation="vertical" />
                                </Grid>
                                <Grid item sm={11}>
                                    {auctionData && <div style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>
                                        <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{settings.VendorContactNumber}</h6>
                                        <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{settings.VendorEmailAddress}</h6>
                                        <h6 style={{ color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>{settings.VendorAddress}</h6>
                                    </div>}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={6}>
                            <Grid container
                                spacing={0}>
                                <Grid item sm={1} style={{ marginRight: -25 }}>
                                    <Divider style={{ width: 1, background: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }} orientation="vertical" />
                                </Grid>
                                <Grid item sm={11}>
                                    {auctionData &&
                                        <h6 style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', color: settings?.Styles?.OverrideHeaderSectionTextColor ? settings?.Styles?.OverrideHeaderSectionTextColor : "#ffffff" }}>
                                            <ReactReadMoreReadLess
                                                charLimit={100}
                                                ellipsis={"..."}
                                                readMoreText={"read more"}
                                                readLessText={"read less"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                                readMoreStyle={{ textDecoration: 'underline' }}
                                                readLessStyle={{ textDecoration: 'underline' }}>
                                                {auctionData?.description}
                                            </ReactReadMoreReadLess>
                                        </h6>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={1}>
                        <Grid item sm={3}>
                            <div>
                                <ParticipateButton auctionId={auctionId} auctionType={auctionData?.auctionType} />
                            </div>
                        </Grid>
                        <Grid item sm={3}>
                            <Button onClick={btnCatalogueRoute} fullWidth className="btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize" style={{ background: '#2b2b2b' }}>
                                <span className="btn-wrapper--label">
                                    Catalogue
                                </span>
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <div>
                                <AuctionDetailCardDocumentSelector auctionId={auctionId} type='button' />
                            </div>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <StickyBox offsetTop={searchbarOffset} style={{ zIndex: 23 }} >
                <div className="py-2" style={{ background: settings?.Styles?.OverrideSearchBarBackgroundColor ? settings?.Styles?.OverrideSearchBarBackgroundColor : settings?.Styles?.Primary }}>
                    <Container maxWidth={false} className={containerWidth}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <Grid item md={5} xs={6}>
                                <TextField margin="dense" fullWidth className='m-1' id="searchString" value={filterFields?.searchText} onChange={(val) => setFilterFields({ ...filterFields, searchText: val.target.value })} onKeyPress={handleKeyPressEvent} style={{ backgroundColor: '#ffffff', borderRadius: 5 }} variant="outlined" placeholder={"Search Name, Description..."}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaSearch />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item md={3} xs={6}>
                                <TextField id="lotNumber" margin="dense" fullWidth className='m-1' value={filterFields?.lotNumber} onChange={(val) => { setFilterFields({ ...filterFields, lotNumber: val.target.value }) }} onKeyPress={handleKeyPressEvent} style={{ backgroundColor: '#ffffff', borderRadius: 5 }} variant="outlined" placeholder={"Search " + settings.LotName + " Number..."}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FaSearch />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {
                                settings &&
                                <Grid item md={3} xs={10}>
                                    <Select
                                        margin='dense'
                                        value={filterFields?.orderBy}
                                        onChange={handleSortByValueChanged}
                                        fullWidth
                                        defaultValue={sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]}
                                        variant="outlined"
                                        style={{ background: '#ffffff' }} >
                                        {
                                            sortBy?.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </Grid>
                            }

                            <Grid item md={1} xs={2}>
                                <Tooltip title="Reset filters" placement="bottom" PopperProps={{ style: { zIndex: 1000 } }}>
                                    <Button onClick={() => {
                                        handleClearFilterObject_d(function () {
                                            setFilterFields(initialFilters)
                                            setResetFilters(true)
                                        })

                                    }} variant="text" className={clsx("btn-outline-light d-flex align-items-flex-end justify-content-center d-40 p-0 rounded-pill text-capitalize")} style={{ background: settings?.Styles?.OverrideSearchBarTextColor ? settings?.Styles?.OverrideSearchBarTextColor : "#transparent" }}>
                                        <FaUndo />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </StickyBox>

            <div className="pt-5 pb-5">
                <Container maxWidth={false} className={containerWidth}>
                    <VideoComponent />
                    <Grid
                        container
                        justifyContent="flex-start"
                        spacing={2}>
                        <Grid item md={3}>
                            <StickyBox offsetTop={searchFiltersOffset}>
                                <Card className="p-1" raised={true}>
                                    <Grid
                                        container
                                        spacing={0}>
                                        <Grid item md={2}>
                                            <ViewModeToggle selectedView={viewType ?? View.COMPACT} setSelectedView={handleSetLotViewType_d} />
                                        </Grid>

                                        <Grid item md={10} className="mt-2">
                                            <Pagination
                                                onHandleChangePage={onHandlePageChanged}
                                                pagination={pagination}
                                                loading={gridLoading}
                                            />
                                        </Grid>
                                    </Grid>

                                    <div className="divider border-1 d-md-block rounded-circle border-light opacity-5 mx-auto mx-xl-0 mb-3 w-75" />

                                    <h5 className="text-black font-weight-bold m-3">{settings.LotName + " Types"}</h5>

                                    <Grid
                                        container
                                        spacing={0}
                                        style={{ maxHeight: 250, overflowX: 'hidden', overflowY: 'scroll', zIndex: 0 }}>
                                        {
                                            allLotTypes?.map((item, index) => (
                                                <Grid item md={12}>
                                                    <div className="d-flex flex-direction-row">
                                                        <Checkbox
                                                            id={item.id}
                                                            size="small"
                                                            className="text-primary"
                                                            style={{ marginTop: "-16px" }}
                                                            checked={filterFields?.lotTypeIds?.includes(item.id)}
                                                            onChange={() => onChangeCheckbox(item.id)} />
                                                        <p onClick={() => onChangeCheckbox(item.id)} style={{ cursor: 'pointer' }}>{item.name}</p>
                                                    </div>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>

                                    <Button onClick={onApplyClicked} variant="contained" className="btn-primary p-3 mt-3 ml-3 mb-3 text-white text-capitalize">
                                        Apply
                                    </Button>

                                    {
                                        filterFields?.orderBy === "Time: Remaining" &&
                                        <Button style={{ background: '#2b2b2b' }} onClick={onApplyClicked} variant="contained" className="btn-primary p-3 mt-3 ml-3 mb-3 text-white text-capitalize">
                                            Reorder {settings.LotName}{lotsFiltered?.length > 1 ? "s" : ""}
                                        </Button>
                                    }
                                </Card>
                            </StickyBox>
                        </Grid>

                        <Grid item md={9}>
                            <div>
                                {gridLoading && (
                                    <div style={{ position: 'absolute', top: '20%', left: 'calc(50% - 50px)', zIndex: 1 }}>
                                        <CircularProgress
                                            className="text-center"
                                            style={{
                                                margin: "auto",
                                                color: settings?.Styles?.OverrideLoadingSpinnerColor
                                                    ? settings?.Styles?.OverrideLoadingSpinnerColor
                                                    : settings?.Styles?.Primary,
                                            }}
                                            size={80}
                                        />
                                    </div>
                                )}
                                {lotsFiltered && lotsFiltered?.length < 1 && (
                                    <div className="text-center mt-5" style={{ opacity: gridLoading ? 0.5 : 1 }}>
                                        <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{"No " + settings.LotName + "s to be displayed"}</h4>
                                    </div>
                                )}
                                {auctionData && lotsFiltered && (
                                    <>
                                        {viewType === View.COMPACT && (
                                            <Box style={{ flexGrow: 1, opacity: gridLoading ? 0.5 : 1 }} className={compactLotCardsContainer}>
                                                {lotsFiltered.map((lot: any) => (
                                                    <CompactLotCard
                                                        key={lot.id}
                                                        lot={lot}
                                                        startDateTimeAt={auctionData?.startDateTimeAt}
                                                        hasExtensions={lot?.lotBidExtensionCount > 0 ? true : false}
                                                        commission={lot?.commission > 0 ? lot?.commission?.toFixed(2) : 0}
                                                        lotFeatures={getLotFeatures(lot)}
                                                        auctionType={auctionData?.auctionType}
                                                        overrideGuidePriceRulesAndAlwaysHide={false}
                                                        registrationStatus={registrationStatus.find((x) => x.auctionId === auctionId)?.status}
                                                        overrideShowBidButtonForCompletedLots={false}
                                                        auctionId={auctionId}
                                                        wishListStatus={lot?.isOnWishlist ?? false}
                                                        variant={""}
                                                        highestBid={lot?.winningRegisteredProfileAmount}
                                                        highestBidProfileId={lot?.winningProfileId}
                                                        settings={settings}
                                                        extendedBy={lot?.extendedBy}
                                                    />)
                                                )}
                                            </Box>
                                        )}
                                        {viewType === View.DETAIL && <Grid
                                            container
                                            spacing={3}
                                            style={{ flexGrow: 1, opacity: gridLoading ? 0.5 : 1 }}>
                                            {lotsFiltered.map((lot: any) => (
                                                <LotDetailCard
                                                    key={lot.id}
                                                    imgSrc={lot?.imageUrl}
                                                    displayFullLengthImage={false}
                                                    header={lot?.name}
                                                    description={lot?.description}
                                                    auctionId={auctionId}
                                                    auctionType={auctionData?.auctionType}
                                                    lotId={lot?.id}
                                                    lotNumber={lot?.number}
                                                    startDateTimeAt={auctionData?.startDateTimeAt}
                                                    endDateTimeAt={lot?.endDateTimeAt}
                                                    successBadgeText={lot?.stcStatus?.toString()}
                                                    startingPrice={lot?.startingPrice}
                                                    guidePrice={lot?.guidePrice?.toString()}
                                                    dangerBadgeText={lot?.number}
                                                    lotFeatures={getLotFeatures(lot)}
                                                    hasExtensions={lot?.lotBidExtensionCount > 0 ? true : false}
                                                    isActive={lot?.isActive}
                                                    commission={lot?.commission > 0 ? lot?.commission?.toFixed(2) : 0}
                                                    registrationStatus={registrationStatus.find(x => x.auctionId === auctionId)?.status}
                                                    documentCount={(PrembidLocalStorage?.currentUser && changeProfileId && registrationStatus.find(x => x.auctionId === auctionId)?.status === 1) ? (lot?.publicDocumentCount + lot?.privateDocumentCount) : lot?.publicDocumentCount}
                                                    wishListStatus={lot?.isOnWishlist ?? false}
                                                    overrideGuidePriceRulesAndAlwaysHide={false}
                                                    overrideShowBidButtonForCompletedLots={false}
                                                    showReserve={lot?.showReserve}
                                                    highestBid={lot?.winningRegisteredProfileAmount}
                                                    highestBidProfileId={lot?.winningProfileId}
                                                    extendedBy={lot?.extendedBy}
                                                    lot={lot}
                                                />)
                                            )}
                                        </Grid>}
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => ({
    lotsFiltered: state.lots.lotsFiltered,
    error: state.lots.error,
    errorText: state.lots.errorText,
    success: state.lots.success,
    auctionData: state.auctions.auction,
    pageLoading: state.progressSpinner.loading,
    settings: state.settings.settings,
    registrationStatus: state.participateButton.registrationStatus,
    pagination: state.lots.pagination,
    changeProfileId: state.loginForm.changeProfileId,
    logoutSuccess: state.loginForm.logoutSuccess,
    viewType: state.lots.viewTypeState,
    filterDataObject: state.lots.filterDataObject,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionByIdFetch(auctionId, onCompletedCallback)),
        handleLotsFilteredDataFetch_d: (auctionId: any, lotTypeIds: any[], searchString: string, lotNumber: string, sortBy: string, resetToPageOne: boolean, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleLotsFilteredDataFetch(auctionId, lotTypeIds, searchString, lotNumber, sortBy, resetToPageOne, pagination, onCompletedCallback)),
        handleAllLotTypesLookupsFetch_d: (auctionId: any, onCompletedCallback: (res: any) => void) => dispatch(handleAllLotTypesLookupsFetch(auctionId, onCompletedCallback)),
        handleRegisteredUsersValidate_d: (auctionId: string, profileId: string, onCompletedCallback?: (result: any) => void) => dispatch(handleRegisteredUsersValidate([auctionId], profileId, onCompletedCallback)),
        clearResponses_d: () => dispatch(clearResponses()),
        clearLotFilteredResponses_d: () => dispatch(clearLotFilteredResponses()),
        clear_d: () => dispatch(clear()),
        clearAuctionData_d: () => dispatch(clearAuctionData()),
        handleSetLotViewType_d: (viewType: any) => dispatch(handleSetLotViewType(viewType)),
        handleClearLotTypeView_d: (viewType: any, onCompletedCallback: () => void) => dispatch(handleClearLotTypeView(viewType, onCompletedCallback)),
        handleSetFilterObjectData_d: (filterObjectData: any, onCompletedCallback?: () => void) => dispatch(handleSetFilterObjectData(filterObjectData, onCompletedCallback)),
        handleClearFilterObject_d: (onCompletedCallback?: () => void) => dispatch(handleClearFilterObject(onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lots);