import React, { FC, memo, CSSProperties, useMemo, useState, useEffect, useReducer } from "react";

import { useCompactLotCardStyles } from "./CompactLotCard.styles";
import {
    Card,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { PricingDisplay } from "@/components/library/PricingDisplay";
import { ReserveMetStatus } from "@/components/library/ReserveMetStatus";
import { LotFeatures } from "@/components/library/LotFeatures";

import { CardActionButtons } from "@/components/library/CardActionButtons";
import { useNavigator } from "@/lib/hooks/useNavigator";
import { CardBadgeRow } from "@/components/library/CardBadgeRow";
import { zeroPad } from "react-countdown";
import { Countdown } from "@/components/library/Countdown";
import { getHighestBidColour, getHighestBidSuffix } from '@/lib/utils/General';
import { LotStatus } from "../../../lib/utils/LotStatusEnum";
import { useConfigureSoldBanner } from "@/lib/hooks/useConfigureSoldBanner";
import { getNewDateWithOffset, } from "prembid-shared-library-npm/helpers";
import { onReceiveMessage, clearOnReceiveMessage } from "prembid-shared-library-npm/signalr";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { MessageSubject } from "prembid-shared-library-npm/types";
import { useFinalizing, useConfigureCounter, useParticipateStatus } from "prembid-shared-library-npm/hooks";
import { SoldFlagBanner } from "../../dumb/SoldFlagBanner";
import { Loading } from "../../composite/Loading";
import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage'

export interface CompactLotCardProps {
    containerStyle?: CSSProperties;
    lot: any;
    startDateTimeAt: any;
    hasExtensions: boolean;
    commission: number;
    lotFeatures?: any;
    auctionType: number;
    overrideGuidePriceRulesAndAlwaysHide: boolean;
    registrationStatus: any;
    overrideShowBidButtonForCompletedLots: boolean;
    auctionId: string;
    wishListStatus: boolean;
    //TODO: extend to be an enum once we know the card variants
    variant: any;
    settings: any;
    highestBid: number;
    highestBidProfileId: string | null;
    extendedBy: number;
}

const CompactLotCard: FC<CompactLotCardProps> = ({
    lot: {
        imageUrl,
        id: lotId,
        endDateTimeAt,
        stcStatus,
        name: header,
        showReserve,
        isActive,
        guidePrice,
        startingPrice,
        number: lotNumber,
    },
    startDateTimeAt,
    hasExtensions,
    commission,
    lotFeatures,
    auctionType,
    overrideGuidePriceRulesAndAlwaysHide,
    registrationStatus,
    overrideShowBidButtonForCompletedLots,
    auctionId,
    wishListStatus,
    settings,
    highestBid,
    highestBidProfileId,
    extendedBy,
    lot
}) => {
    const { classes: {
        compactLotCard,
        contentSection,
        imageContainer,
        image,
        rightHandSection,
        headerContainer,
        reserveMetContainer,
        headerContent,
        cardFooterContainer,
        emptyPricingContainer,
        countdownTimer,
        countDownRenderDisplayText,
        countDownRenderer,
        countDownRendererUnitItem,
        countDownRendererUnitSuffix,
        countDownRendererUnitValue,
    } } = useCompactLotCardStyles();

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);
    const profileId = useSelector((state: RootState) => state.loginForm.changeProfileId);
    const { participateStatus, invalidateParticipateStatus, loading } = useParticipateStatus({
        profileId,
        auctionId
    });

    const { btnViewLotDetailsRoute } = useNavigator({
        lotId,
        auctionId,
    });

    const now = getNewDateWithOffset(currentDateTimeOffset);
    const start = useMemo(() => new Date(startDateTimeAt), [startDateTimeAt]);
    const theme = useTheme();

    const soldBannerSize = useMediaQuery(theme.breakpoints.between(959, 1150))
        ? "1rem"
        : "1.4rem";

    const [end, setEnd] = useState<Date>();
    const [aspectRatio, setAspectRatio] = useState(0);

    const reserveMet = (reservePrice: number, highestBid: number) => {
        const tempReservePrice = reservePrice ? Number(reservePrice) : 0;
        return tempReservePrice > 0 ? Number(highestBid) >= tempReservePrice : true;
    };

    const [liveUpdates, setLiveUpdates] = useState({
        highestBid: highestBid,
        highestBidProfileId: lot?.winningProfileId,
        isExtended: hasExtensions,
        reserveMet: reserveMet(lot?.reservePrice, lot?.overrideWinningBidAmount ?? lot?.winningRegisteredProfileAmount),
        reservePrice: lot?.reservePrice,
        startingPrice,
        extendedBy: extendedBy,
    });

    useEffect(() => {
        setLiveUpdates((current) => ({
            ...current,
            highestBid: highestBid,
            highestBidProfileId: lot?.winningProfileId,
            reserveMet: reserveMet(lot?.reservePrice, lot?.overrideWinningBidAmount ?? lot?.winningRegisteredProfileAmount),
            isExtended: hasExtensions,
            reservePrice: lot?.reservePrice,
            startingPrice,
            extendedBy: extendedBy
        }));
    }, [hasExtensions, highestBid, lot?.winningProfileId, highestBidProfileId, lot?.overrideWinningBidAmount, lot?.reservePrice, lot?.winningRegisteredProfileAmount, startingPrice, extendedBy])

    useEffect(() => {
        if (!endDateTimeAt) return;
        const endTime = new Date(endDateTimeAt)
        if (liveUpdates?.extendedBy) endTime.setTime(endTime.getTime() + ((liveUpdates.extendedBy ?? extendedBy ?? 0) * 1000))
        setEnd(new Date(endTime));
    }, [endDateTimeAt, liveUpdates?.extendedBy]);

    const { countdownDate, countdownHeader, status, previousStatus } = useConfigureCounter({
        start,
        end,
    });

    const { soldBanner, handleShowSoldBanner } = useConfigureSoldBanner(
        lot,
        liveUpdates
    );

    useEffect(() => {
        handleShowSoldBanner(lot, liveUpdates, status);
    }, [handleShowSoldBanner, liveUpdates, lot, status]);

    useEffect(() => {
        const callbackId = onReceiveMessage((message: string) => {
            if (message) {
                var payload = JSON.parse(message);

                if (payload?.data) {
                    const messageSubject = payload.data['messageSubject'];
                    const messageAuctionId = payload.data['auctionId'];
                    const messageLotId = payload.data['lotId'];
                    const messageAmount = payload.data['amount'];
                    const messageProfileId = payload.data['profileId'];
                    const messageExtendedBy = payload.data['extendedBy'];

                    if (messageSubject === MessageSubject.UpdateRegisteredProfile && messageAuctionId === auctionId && messageProfileId === PrembidLocalStorage.currentProfile.toString()) {
                        invalidateParticipateStatus();
                    }

                    if (messageLotId === lotId) {
                        if (messageSubject === MessageSubject.BidUpdated) {
                            const winningRegisteredProfileAmount = payload.data['winningRegisteredProfileAmount'];
                            const winningProfileId = payload.data['winningProfileId'];

                            setLiveUpdates(current => ({
                                ...current,
                                highestBid: winningRegisteredProfileAmount,
                                highestBidProfileId: winningProfileId,
                                reserveMet: reserveMet(current?.reservePrice, winningRegisteredProfileAmount),
                            }));
                        }
                    }

                    if (messageAuctionId === auctionId && messageLotId === lotId) {
                        if (messageSubject === MessageSubject.CreateBid) {
                            setLiveUpdates(current => ({
                                ...current,
                                highestBid: messageAmount,
                                highestBidProfileId: messageProfileId,
                                reserveMet: reserveMet(current?.reservePrice, messageAmount),
                            }));

                            if (messageExtendedBy !== "" && messageExtendedBy !== undefined) {
                                setLiveUpdates(current => ({
                                    ...current,
                                    isExtended: true,
                                    extendedBy: Number(messageExtendedBy),
                                }));

                                //const endTime = new Date(end)
                                //endTime.setTime(endTime.getTime() + (messageExtendedBy * 1000))
                                //setEnd(new Date(endTime));
                            }
                        }

                        if (messageSubject === MessageSubject.UpdatedStartPrice) {
                            setLiveUpdates((current) => ({
                                ...current,
                                startingPrice: payload.data['startingPrice'],
                            }));
                        }

                        if (messageSubject === MessageSubject.UpdatedReservePrice) {
                            setLiveUpdates((current) => ({
                                ...current,
                                reservePrice: Number(payload.data["reservePrice"]),
                                reserveMet: reserveMet(payload.data["reservePrice"], current?.highestBid),
                            }));
                        }

                        if (messageSubject === MessageSubject.ManuallyExtendedLot) {
                            setLiveUpdates((current) => ({
                                ...current,
                                isExtended: true,
                                extendedBy: Number(messageExtendedBy),
                            }));

                            //const endTime = new Date(end)
                            //endTime.setTime(endTime.getTime() + (messageExtendedBy * 1000))
                            //setEnd(new Date(endTime));
                        }
                    }
                }
            }
        });
        return () => clearOnReceiveMessage(callbackId);
    }, [])

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div>
                <small className={`${countDownRenderDisplayText} text-white`}>
                    {countdownHeader}
                </small>
                <div className={countDownRenderer}>
                    <div className={countDownRendererUnitItem}>
                        <div className={countDownRendererUnitValue}>
                            {completed ? "00" : zeroPad(days)}:
                        </div>
                        <div className={`${countDownRendererUnitSuffix} text-white`}>
                            DD
                        </div>
                    </div>
                    <div className={countDownRendererUnitItem}>
                        <div className={countDownRendererUnitValue}>
                            {completed ? "00" : zeroPad(hours)}:
                        </div>
                        <div className={`${countDownRendererUnitSuffix} text-white`}>
                            HH
                        </div>
                    </div>
                    <div className={countDownRendererUnitItem}>
                        <div className={countDownRendererUnitValue}>
                            {completed ? "00" : zeroPad(minutes)}:
                        </div>
                        <div className={`${countDownRendererUnitSuffix} text-white`}>
                            MM
                        </div>
                    </div>
                    <div className={countDownRendererUnitItem}>
                        <div className={countDownRendererUnitValue}>
                            {completed ? "00" : zeroPad(seconds)}
                        </div>
                        <div className={`${countDownRendererUnitSuffix} text-white`}>
                            SS
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const CountdownTimerComponent = () => {
        return (
            <>
                {countdownDate && <div className={countdownTimer}>
                    <div className="ml-1">
                        <small className="text-white">
                            <Countdown
                                date={countdownDate}
                                now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                renderer={CountdownRenderer}
                            />
                        </small>
                    </div>
                </div>}
            </>
        );
    };

    useEffect(() => {
        function getImageDimensions(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        }

        getImageDimensions(imageUrl)
            .then((res: any) => {
                setAspectRatio(res.width / res.height);
            })

    }, [imageUrl])

    const { finalizingText, setValidating, validating } = useFinalizing({
        auctionType,
        lotLocale: settings.LotName,
    });

    useEffect(() => {
        if (previousStatus !== undefined && status === LotStatus.COMPLETED && !lot?.sold) {
            setValidating(true);
            setTimeout(() => setValidating(false), 3000);
        }
    }, [status]);


    return (
        <Card className={compactLotCard} raised={true}>
            <div className={contentSection}>
                <Card className={imageContainer}>
                    {
                        validating &&
                        <div style={{ position: 'absolute', width: '100%', top: 'calc(50% - 30px)', zIndex: 10 }}>
                            <Loading loading={true} loadingText={finalizingText} size={28} loadingTextStyle={{ fontSize: 12, color: 'white' }} />
                        </div>
                    }

                    {!validating && soldBanner && soldBanner.showBanner === true && (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <SoldFlagBanner
                                backgroundColor="#ff0000"
                                bannerText={soldBanner?.bannerText}
                                fontSize={soldBannerSize}
                            />
                        </div>
                    )}
                    <img
                        src={imageUrl}
                        style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            objectFit: 'scale-down',
                            opacity: validating ? 0.3 : 1,
                        }}
                        alt="Missing"
                        className={`img-fit-container rounded`}
                        onClick={btnViewLotDetailsRoute}
                    />
                </Card>
                <div className={rightHandSection}>
                    {!!end && <CardBadgeRow
                        isActive={isActive}
                        stcStatus={stcStatus}
                        now={now}
                        start={start}
                        end={end}
                        hasExtensions={liveUpdates?.isExtended}
                        commission={commission}
                        wishListStatus={wishListStatus}
                        lotId={lotId}
                        hasWishListIcon={true}
                        auctionType={auctionType}
                        settings={settings}
                        validating={validating}
                    />}


                    <div className={headerContainer}>
                        {lotNumber && header && (
                            <div className={`${headerContent} text-black`}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ color: "#5a5a5a", display: "inline" }}
                                >
                                    {`${lotNumber} | `}
                                </Typography>
                                <Typography variant="subtitle2" style={{ display: "inline" }}>
                                    {header}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <CountdownTimerComponent />

                    <div className={reserveMetContainer}>
                        {!!end && <ReserveMetStatus
                            reserveMet={liveUpdates?.reserveMet}
                            showReserve={showReserve}
                            isActive={isActive}
                            fontSize={10}
                            now={now}
                            end={end} />}
                    </div>
                </div>
            </div>
            <LotFeatures lotFeatures={lotFeatures} />
            <div className={cardFooterContainer}>
                {isActive ? (
                    <PricingDisplay
                        auctionType={auctionType}
                        guidePrice={guidePrice?.toString()}
                        overrideGuidePriceRulesAndAlwaysHide={
                            overrideGuidePriceRulesAndAlwaysHide
                        }
                        registrationStatus={participateStatus?.status ?? registrationStatus}
                        openingBid={liveUpdates?.startingPrice?.toString()}
                        highestBid={liveUpdates?.highestBid}
                        containerStyle={{
                            padding: 8,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "100%",
                            minHeight: 45,
                            flex: 1,
                        }}
                        fontSize={10}
                        highestBidColour={getHighestBidColour(liveUpdates?.highestBidProfileId)}
                        highestBidSuffix={getHighestBidSuffix(liveUpdates?.highestBidProfileId)}
                    />
                ) : (
                    <div className={emptyPricingContainer} />
                )}

                <CardActionButtons
                    containerStyle={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: 2,
                        flexDirection: "row",
                    }}
                    isActive={isActive}
                    auctionType={auctionType}
                    auctionId={auctionId}
                    lotId={lotId}
                    overrideShowBidButtonForCompletedLots={
                        overrideShowBidButtonForCompletedLots
                    }
                    status={status}
                />
            </div>
        </Card>
    );
};

export default memo(CompactLotCard);
