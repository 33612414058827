import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";

//Action
//TODO
const LOTS_FETCH_SUCCESS = "LOTS_FETCH_SUCCESS";
const LOTS_FETCH_ERROR = "LOTS_FETCH_ERROR";
const LOTS_TYPE_FETCH_SUCCESS = "LOTS_TYPE_FETCH_SUCCESS";
const LOTS_TYPE_FETCH_ERROR = "LOTS_TYPE_FETCH_ERROR";
const CLEAR_RESPONSES = "CLEAR_RESPONSES";
const CLEAR_LOT_TYPE_RESPONSES = "CLEAR_LOT_TYPE_RESPONSES";

const LOTS_FILTERED_SUCCESS = "LOTS_FILTERED_SUCCESS";
const LOTS_FILTERED_ERROR = "LOTS_FILTERED_ERROR";
const CLEAR_FILTERED_RESPONSES = "CLEAR_FILTERED_RESPONSES";
const CLEAR = "CLEAR_LOTS";
const TOGGLE_WISHLIST = "TOGGLE_WISHLIST";

const SET_VIEW_TYPE = "SET_VIEW_TYPE";
const CLEAR_VIEW_TYPE = "CLEAR_VIEW_TYPE";

const SET_LOT_FILTERES = "SET_LOT_FILTERES";
const CLEAR_LOT_FILTERS = "CLEAR_LOT_FILTERS";

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    lotsData: undefined,
    lotTypes: undefined,
    lotsFiltered: undefined,
    pagination: undefined,
    viewTypeState: undefined,
    filterDataObject: null
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case LOTS_FETCH_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotsData: action.payload }
        case LOTS_FETCH_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_RESPONSES: return { ...state, error: false, errorText: null, success: false, enquirySent: false, lotsData: undefined }
        case LOTS_TYPE_FETCH_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotTypes: action.payload }
        case LOTS_FILTERED_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotsFiltered: action.payload.list, pagination: action.payload.pagination }
        case LOTS_TYPE_FETCH_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case LOTS_FILTERED_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_LOT_TYPE_RESPONSES: return { ...state, error: false, errorText: null, success: false, enquirySent: false, lotTypes: undefined }
        case CLEAR_FILTERED_RESPONSES: return { ...state, error: false, errorText: null, success: false, enquirySent: false, lotsFiltered: undefined }
        case TOGGLE_WISHLIST:
            const temp: any[] = [...(state.lotsFiltered ?? [])]
            const lotIndex = temp.findIndex((lot: any) => lot.id === action.lotId)

            if (lotIndex > -1) {
                const tempItem = {
                    ...temp[lotIndex]
                }
                tempItem.isOnWishlist = !tempItem.isOnWishlist;
                temp.splice(lotIndex, 1, tempItem);
            }
            return { ...state, lotsFiltered: temp }
        case CLEAR: return { ...state, error: initialState.error, errorText: initialState.errorText, reload: initialState.reload, }
        case SET_VIEW_TYPE:
            return { ...state, viewTypeState: action.payload };
        case CLEAR_VIEW_TYPE:
            return { ...state, viewTypeState: action.payload };
        case SET_LOT_FILTERES:
            return { ...state, filterDataObject: { ...action.payload }};
        case CLEAR_LOT_FILTERS:
            return { ...state, filterDataObject: initialState.filterDataObject, pagination: undefined };
        default: return state
    }
}

//Action creator
//TODO

export const lotsFetchSuccess = (lotsData: any) => {
    return { type: LOTS_FETCH_SUCCESS, payload: lotsData }
}

export const lotsFetchError = (errorText: string) => {
    return { type: LOTS_FETCH_ERROR, payload: errorText }
}

export const clearResponses = () => {
    return { type: CLEAR_RESPONSES, payload: CLEAR_RESPONSES }
}
export const lotsTypeAllFetchSuccess = (lotTypes: any) => {
    return { type: LOTS_TYPE_FETCH_SUCCESS, payload: lotTypes }
}

export const lotsTypesAllFetchError = (errorText: string) => {
    return { type: LOTS_TYPE_FETCH_ERROR, payload: errorText }
}

export const clearLotTypeAllResponses = () => {
    return { type: CLEAR_LOT_TYPE_RESPONSES, payload: CLEAR_LOT_TYPE_RESPONSES }
}

export const lotsFilteredSuccess = (payload: any) => {
    return { type: LOTS_FILTERED_SUCCESS, payload: payload }
}
export const lotsFilteredError = (errorText: string) => {
    return { type: LOTS_FILTERED_ERROR, payload: errorText }
}

export const clearLotFilteredResponses = () => {
    return { type: CLEAR_FILTERED_RESPONSES, payload: CLEAR_FILTERED_RESPONSES }
}

export const clear = () => {
    return { type: CLEAR }
}

export const toggleWishlist = (lotId: string) => {
    return { type: TOGGLE_WISHLIST, lotId }
}

export const setLotViewType = (viewType) => {
    return { type: SET_VIEW_TYPE, payload: viewType };
};

export const clearLotViewType = (viewType) => {
    return { type: CLEAR_VIEW_TYPE, payload: viewType };
};

export const setFilterObjectData = (filterObject) => {
    return { type: SET_LOT_FILTERES, payload: filterObject };
};

export const clearFilterObjectData = () => {
    return { type: CLEAR_LOT_FILTERS, payload: initialState.filterDataObject };
};

export const handleSetFilterObjectData = (filterObject, onCompletedCallback?: () => void) => {
    return (dispatch: any) => {
        dispatch(setFilterObjectData(filterObject));
        if (onCompletedCallback) onCompletedCallback()
    };
};

export const handleClearFilterObject = (onCompletedCallback?: () => void) => {
    return (dispatch: any) => {
        dispatch(clearFilterObjectData())
        if (onCompletedCallback) onCompletedCallback()
    }
}

export const handleSetLotViewType = (viewType) => {
    return (dispatch: any) => {
      
        dispatch(setLotViewType(viewType));
    };
};

export const handleClearLotTypeView = (viewType, onCompletedCallback?: () => void) => {
    return (dispatch: any) => {
        dispatch(clearLotViewType(viewType));
        if (onCompletedCallback) onCompletedCallback()
    };
}


export const handleLotsDataFetchByAuctionId = (auctionId: any, onCompletedCallback?: (res: any) => void) => {

    let requestLotPageData = {
        auctionId: auctionId
    }

    return function (dispatch: any) {
        return requestClient.get(apiPaths.lots.retrieveLotPageData, null, requestLotPageData)
            .then(function (res) {
                if (res.success) {
                    let lotsData = res.response;
                    dispatch(lotsFetchSuccess(lotsData))
                } else {
                    dispatch(lotsFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotsDataFetch = (auctionId: any, lotTypeId?: any, onCompletedCallback?: (res: any) => void) => {

    let requestLotPageData = {
        auctionId: auctionId,
        lotTypeId: lotTypeId
    }

    return function (dispatch: any) {
        return requestClient.get(apiPaths.lots.retrieveLotPageData, null, requestLotPageData)
            .then(function (res) {
                if (res.success) {
                    let lotsData = res.response;
                    dispatch(lotsFetchSuccess(lotsData))
                } else {
                    dispatch(lotsFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleAllLotTypesLookupsFetch = (auctionId: any, onCompletedCallback?: (res: any) => void) => {

    return function (dispatch: any) {
        return requestClient.get(apiPaths.lots.RetrieveAllLotTypesLookups, { auctionId }, null)
            .then(function (res) {

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}
export const handleLotsFilteredDataFetch = (auctionId: any, lotTypeIds: any[], searchString: string, lotNumber: string, sortBy: string, resetToPageOne: boolean, pagination?: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };
    const finalPagination = resetToPageOne ? { currentPage: 1, pageSize: pagination.pageSize, total: pagination.total } : pagination

    let requestFilteredLotPageData = {
        auctionId: auctionId,
        lotTypeIds: lotTypeIds,
        searchString: searchString,
        lotNumber: lotNumber,
        sortBy: sortBy,
        pagination: finalPagination,
        profileId: PrembidLocalStorage.currentProfile ?? null
    }

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lots.RetrieveFilteredLotPageData, requestFilteredLotPageData)
            .then(function (res) {
                if (res.success) {
                    let lotsFiltered = res.response;

                    dispatch(clearLotFilteredResponses());
                    dispatch(lotsFilteredSuccess(lotsFiltered))
                } else {
                    dispatch(lotsFilteredError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}