import { makeStyles } from "tss-react/mui";

export const useVideoCarouselStyles = makeStyles()((theme) => ({
    video: {
        boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
            boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
        }
    },
    arrows: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'rgba(0, 0, 0, 0.5) !important',
        border: 'none !important',
        cursor: 'pointer',
        zIndex: 2,
        fontSize: '2.5rem !important',
        color: '#ffffff !important',
        borderRadius: '10% !important',
        padding: '10px !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        width: '50px !important',
        height: '50px !important',
    },
    leftArrow: {
        left: '5px'
    },
    rightArrow: {
        right: '5px'
    },
    thumbnail: {
        height: '100%',
        objectFit: 'scale-down',
        borderRadius: 3,
        padding: 1,
    },
    videoSlide: {
        height: '100%',
        width: "auto%",
    }
}));