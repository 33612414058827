import { CircularProgress, Divider, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { forEach, sortBy } from "lodash";
import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { retrieveRegistrationDocuments, retrieveRegistrationProcessDocuments, updateState, uploadRegistrationProcessDocuments } from "../store/RegistrationModal";
import FooterButtons from "./FooterButtons";
import ns from '@/lib/utils/NotificationService';
import { downloadFile } from "@/pages/Documents/store/Documents";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";
import { RootState } from "../../../store/Store";

interface DocumentAcceptanceProps {
    onClose: () => void,
    onNextStep: () => void,
    onBackStep: () => void,
    settings: any,
    state: any,
    updateState: (data: any) => void,
    retrieveRegistrationProcessDocuments: (profileId: string, auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    retrieveRegistrationDocuments: (auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    uploadRegistrationProcessDocuments: (data: any, onCompletedCallback?: (res: any) => void) => void,
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
}

const DocumentAcceptance: React.FC<DocumentAcceptanceProps> = props => {
    const { onClose, onNextStep, onBackStep, settings, state, updateState, retrieveRegistrationProcessDocuments, retrieveRegistrationDocuments, uploadRegistrationProcessDocuments, downloadFile_d } = props;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const [loading, setLoading] = useState<boolean>(true);
    const [updatingDocuments, setUpdatingDocuments] = useState<boolean>(false);

    const [acceptanceDocuments, setAcceptanceDocuments] = useState<any[]>([]);

    const [downloading, updateDownloading] = useReducer((state, action) => {
        if (action.type === 'add') {
            return [...state, action.id]
        } else if (action.type === 'remove') {
            return state.filter((id) => id !== action.id)
        }

        return state;
    }, [])

    useEffect(() => {
        retrieveRegistrationProcessDocuments(state.selectedProfileId, state.auctionId, () => {
            retrieveRegistrationDocuments(state.auctionId, () => {
                setLoading(false);
            });
        });
    }, [])

    useEffect(() => {
        if (!loading && state.registrationProcessDocuments) {
            if (state.registrationProcessDocuments.filter(x => x.type === 'Auction Acceptance Document' && x.status === "Awaiting Acceptance").length === 0) onNextStep();
        }
    }, [loading, state.registrationProcessDocuments])

    const validate = () => {
        let valid = true;

        if (state.registrationProcessDocuments?.filter(x => x.type === 'Auction Acceptance Document' && x.status === "Awaiting Acceptance").length !== acceptanceDocuments.length) return false;

        forEach(acceptanceDocuments, (doc: any) => {
            if (doc.acceptedAt === null || doc.acceptedAt === undefined) {
                valid = false;
            }
        });

        return valid;
    }

    const handleOnClose = () => {
        //Custom logic
        onClose();
    }

    const handleBack = () => {
        //Custom logic
        onBackStep();
    }

    const handleSubmit = () => {
        let valid = validate();

        if (!valid) {
            ns.error("", "Please accept all \"Acceptance\" documents to proceed")
            return;
        }

        setUpdatingDocuments(true);
        const payload = {
            profileId: state.selectedProfileId,
            auctionId: state.auctionId,
            registrationProcessDocuments: acceptanceDocuments ?? []
        }

        uploadRegistrationProcessDocuments(payload, (res) => {
            setUpdatingDocuments(false)
            if (res.success) {
                onNextStep();
            }
            else {
                ns.error('', 'Failed to upload a document.')
            }
        });
    }

    const handleAuctionAcceptanceChange = (item) => {
        let existingDocIndex = acceptanceDocuments.findIndex(x => x.id === item.id);

        let tempDoc: any = {
            id: item.id,
            documentTemplateId: item.documentTemplate.id,
            acceptedAt: getNewDateWithOffset(currentDateTimeOffset).toISOString(),
            documentUrl: item?.documentUrl
        }

        let tempList = [...acceptanceDocuments];

        if (existingDocIndex > -1) {
            tempList.splice(existingDocIndex, 1);
        }
        else tempList.push(tempDoc);

        setAcceptanceDocuments(tempList)
    };

    const handleViewDocument = (url: string, onCompletedCallback?: () => void) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
            onCompletedCallback?.();
        })
    }

    const renderAcceptanceDocuments = (documents: any[]) => {
        let docs = documents?.filter(x => x.type === 'Auction Acceptance Document' && x.status === "Awaiting Acceptance");

        if (!docs) return <></>

        docs = sortBy(docs, ['documentTemplate.name']);

        return <div className="table-responsive-md mt-1">
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        <TableRow key={1}>
                            {docs.map((row, index) => {
                                return <TableRow key={index}>
                                    <TableCell hidden component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell hidden component="th" scope="row">
                                        {row.documentTemplateId}
                                    </TableCell>
                                    <TableCell scope="row" width="80%">
                                        {row.documentTemplate.name}
                                    </TableCell>
                                    <TableCell scope="row" width="22%">
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', overflow: 'hidden' }}>
                                            {row?.documentUrl?.length > 0 && <>
                                                {downloading.includes(row.id) ? <CircularProgress size={20} style={{ marginRight: 20 }} className="text-primary text-center center" /> : <a
                                                    className="text-info"
                                                    href={undefined}
                                                    onClick={() => {
                                                        updateDownloading({ type: 'add', id: row.id })
                                                        setTimeout(() => handleViewDocument(row.documentUrl, () => {
                                                            updateDownloading({ type: 'remove', id: row.id })
                                                        }), 50)
                                                    }}
                                                    style={{
                                                        textDecoration: 'underline', cursor: 'pointer', marginRight: 10
                                                    }}
                                                >View</a>}
                                            </>}
                                            <Switch id={index.toString()} onChange={(e) => handleAuctionAcceptanceChange(row)} className="m-2 p-2 switch-medium toggle-switch-success remove-left" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }


    return <div style={{ width: 500 }}>
        <h5 style={{ marginTop: 10 }}>Document Acceptance</h5>
        <Divider style={{ marginBottom: 10 }} />
        {loading && <><p>Loading acceptance documents...</p>
            <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ paddingBottom: 20 }}>
                <CircularProgress className="text-center" style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
            </div>
        </>}
        {!loading && <>
            <p>In order to proceed you will need to accept all of our "Acceptance" documents.</p>
            {renderAcceptanceDocuments(state.registrationProcessDocuments)}
        </>}
        <FooterButtons onClose={handleOnClose} onSubmit={loading ? undefined : handleSubmit} loading={loading || updatingDocuments} />
    </div>
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})


const mapDispatchToProps = (dispatch: any) => {
    return {
        updateState: (data: any) => dispatch(updateState(data)),
        retrieveRegistrationProcessDocuments: (profileId: string, auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(retrieveRegistrationProcessDocuments(profileId, auctionId, onCompletedCallback)),
        retrieveRegistrationDocuments: (auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(retrieveRegistrationDocuments(auctionId, onCompletedCallback)),
        uploadRegistrationProcessDocuments: (data: any, onCompletedCallback?: (res: any) => void) => dispatch(uploadRegistrationProcessDocuments(data, onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAcceptance)