import { useState, FC, memo } from "react";
import {
    Card,
    TextField,
    Checkbox,
} from "@mui/material";

import ns from "@/lib/utils/NotificationService";

import { useForm, Controller } from "react-hook-form";
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    useSendEnquiry,
} from "prembid-shared-library-npm/networking";
import { useSelector } from "react-redux";
import { RootState } from "@/store/Store";
import { LoadingButton } from "@mui/lab";
import { Skeleton } from "../../dumb/Skeleton";

export interface EnquiryFormContainerProps {
    lotId: string;
}

type EnquiryFormInputs = {
    name: string;
    email: string;
    phoneNumber: string;
    message: string;
    optInForMarketing: boolean;
    acceptedTerms: boolean;
}

const enquirySchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
        .email("Invalid email format")
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Invalid email format"
        )
        .required("Email is required"),
    phoneNumber: Yup.string()
        .matches(/^\d{7,}$/, "Phone number must be 7 to 15 digits")
        .required("Phone number is required"),
    message: Yup.string()
        .min(1, "Message must be at least 1 character")
        .max(500, "Message cannot exceed 500 characters")
        .required("Message is required"),
    acceptedTerms: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("Accepting terms and conditions is required"),
    optInForMarketing: Yup.boolean().required("Marketing opt-in is required"),
});

const EnquiryForm: FC<EnquiryFormContainerProps> = ({
    lotId,
}) => {
    const enquiryFieldDefaults = {
        name: "",
        email: "",
        message: "",
        phoneNumber: "",
        optInForMarketing: false,
        acceptedTerms: false,
    };

    const lotContact = useSelector((state: RootState) => state.lotBidInfoReducer.lotContact);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        reset,
    } = useForm<EnquiryFormInputs>({
        defaultValues: enquiryFieldDefaults,
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(enquirySchema),
    });

    const { mutate: sendEnquiry, isLoading: sendEnquiryIsLoading } =
        useSendEnquiry({
            onSuccess() {
                reset(enquiryFieldDefaults);
                ns.success("", "Email sent successfully");
            },
            onError() {
                ns.error("", "Email failed to send");
            },
        });

    const [privacyPolicyModalState, setPrivacyPolicyModalState] = useState<any>({
        open: false,
        url: undefined,
    });

    const { settings } = useSelector((state: RootState) => state.settings);

    const OnSubmit = ({
        name,
        phoneNumber,
        email,
        message,
        acceptedTerms,
        optInForMarketing,
    }: EnquiryFormInputs) => {
        const enquiry = {
            toEmailAddress: lotContact?.email,
            lotId,
            name,
            email,
            cellNumber: phoneNumber,
            message,
            acceptedTerms,
            optInForMarketing,
        };

        sendEnquiry(enquiry);
    };

    const handlePrivacyLinkClicked = () => {
        setPrivacyPolicyModalState({
            open: true,
            url: settings?.ClientPrivacyPolicy ? "ClientPrivacyPolicy" : undefined,
        });
    };

    const handleChange = (value) => {
        trigger(value);
    };

    return (
        <Card raised={true}>
            {
                !lotContact?.email ? (
                    <Skeleton animation="wave" variant="rectangular" height="685px" width="100%" />
                ) : (
                        <form onSubmit={handleSubmit(OnSubmit)} className="p-3">
                            <h3 className="text-center text-black">Send Enquiry</h3>
                            <h4 className="text-center font-weight text-black mb-1">
                                Ask us any questions and we will get back to you
                            </h4>

                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="name"
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        className="mb-3"
                                        label="Name"
                                        required
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange("name");
                                        }}
                                        error={Boolean(errors.name)}
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="email"
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        className="mb-3"
                                        label="Email"
                                        required
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange("email");
                                        }}
                                        error={Boolean(errors["email"])}
                                    />
                                )}
                            />

                            <Controller
                                name="message"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="message"
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        className="mb-3"
                                        label="Message"
                                        multiline
                                        rows={4}
                                        inputProps={{ maxLength: 500 }}
                                        required
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange("message");
                                        }}
                                        error={Boolean(errors.message)}
                                    />
                                )}
                            />

                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="phoneNumber"
                                        label="Phone Number"
                                        error={Boolean(errors.phoneNumber)}
                                        margin="dense"
                                        fullWidth
                                        variant="outlined"
                                        className="mb-3"
                                        required
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange("phoneNumber");
                                        }}
                                        InputProps={{
                                            inputMode: "numeric",
                                        }}
                                        inputProps={{
                                            pattern: "[0-9]*",
                                            maxLength: 15,
                                            minLength: 7,
                                        }}
                                    />
                                )}
                            />

                            <div className="align-box-row">
                                <Controller
                                    name="acceptedTerms"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value} // Explicitly bind the checked value
                                            className="align-self-center mr-2 text-primary"
                                            id="acceptedTerms"
                                            onChange={(e) => {
                                                field.onChange(e.target.checked); // Ensure the correct value is passed
                                                handleChange("acceptedTerms");
                                            }}
                                        />
                                    )}
                                />
                                <div>
                                    <h6 className="text-black-75 mt-1 mb-0">
                                        I have read and agree to the{" "}
                                        <span
                                            onClick={handlePrivacyLinkClicked}
                                            style={{
                                                cursor: "pointer",
                                                color: settings.Styles.Primary,
                                                marginTop: "-15px",
                                            }}
                                        >
                                            Privacy Policy
                                        </span>
                                    </h6>
                                </div>
                            </div>
                            <div className="align-box-row mb-2">
                                <Controller
                                    name="optInForMarketing"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value} // Explicitly bind the checked value
                                            className="align-self-center mr-2 text-primary"
                                            id="optInForMarketing"
                                            onChange={(e) => {
                                                field.onChange(e.target.checked); // Ensure the correct value is passed
                                                handleChange("optInForMarketing");
                                            }}
                                        />
                                    )}
                                />

                                <div>
                                    <h6 className="text-black-75 mt-1 mb-0">
                                        Send me news, events and exclusive offers (Optional)
                                    </h6>
                                </div>
                            </div>

                            <LoadingButton
                                type="submit"
                                disabled={!isValid}
                                style={{ height: 56 }}
                                fullWidth
                                variant="contained"
                                className="btn-primary mt-2 mb-2 p-3 text-white text-capitalize"
                                loading={sendEnquiryIsLoading}>
                                Submit
                            </LoadingButton>

                            {privacyPolicyModalState.open && (
                                <PrivacyPolicyModal
                                    onSubmit={() => null}
                                    isDialogOpened={privacyPolicyModalState.open}
                                    handleCloseDialog={() =>
                                        setPrivacyPolicyModalState({ open: false, url: undefined })
                                    }
                                    isViewOnlyMode={true}
                                    overrideUrl={privacyPolicyModalState.url}
                                    overrideTitle={privacyPolicyModalState.title}
                                />
                            )}
                        </form>
                )
            }
        </Card>
    );
};

export default memo(EnquiryForm);
