import React from "react";
import { Button } from "@mui/material";
import { FaCheck, FaFileUpload } from "react-icons/fa";
import ns from "@/lib/utils/NotificationService";
import Resizer from "react-image-file-resizer";

const MAX_FILE_SIZE_MB = 10;

const DEFAULT_RESIZE_OPTIONS = {
    maxWidth: 1920,
    maxHeight: 1080,
    quality: 50,
    rotation: 0,
};

interface Props {
    fileAlreadyUploaded?: boolean;
    buttonSize: number;
    uploadedFile: any;
    acceptedFileExtensions: string;
    buttonText: string;
    buttonFileUploadedText: string;
    onFileUpload: (file: File | undefined) => void;
    maxImageSize: number;
    maxFileSize: number;
}

const FileUploader: React.FC<Props> = (props) => {
    const {
        uploadedFile,
        acceptedFileExtensions,
        buttonText,
        buttonFileUploadedText,
        onFileUpload,
        maxImageSize,
        maxFileSize,
        buttonSize
    } = props;

    const hiddenFileInputReference: any = React.useRef<any>(undefined);

    // Added function to compress images before uploading
    const compressImage = async (file: File): Promise<File> => {
        return new Promise((resolve, reject) => {
            if (!file.type.includes("image")) {
                resolve(file);
                return;
            }
            try {
                Resizer.imageFileResizer(
                    file,
                    DEFAULT_RESIZE_OPTIONS.maxWidth,
                    DEFAULT_RESIZE_OPTIONS.maxHeight,
                    "JPEG",
                    DEFAULT_RESIZE_OPTIONS.quality,
                    DEFAULT_RESIZE_OPTIONS.rotation,
                    (uri) => {
                        if (!uri) {
                            reject(new Error("Failed to compress image"));
                            return;
                        }

                        const base64Data = (uri as string).split(",")[1];
                        const blob = b64toBlob(base64Data, "image/jpeg");
                        const newFile = new File([blob], file.name, { type: "image/jpeg" });
                        resolve(newFile);
                    },
                    "base64"
                );
            } catch (err) {
                reject(err);
            }
        });
    };

    // Added function to convert base64 data to blob
    function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays: Uint8Array[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async function handleChange(e) {
        let files = e.target.files;
        let allFiles: any[] = [];

        for (var i = 0; i < files.length; i++) {
            let file = files[i];

            if (isValidFileTypeSelected(file.type)) {
                if (isValidSize(file.type, file.size)) {
                    const compressedFile: any = await compressImage(file);

                    var base64 = await loadImage(compressedFile);

                    let fileInfo: any = {
                        name: file.name,
                        type: file.type,
                        base64: base64,
                        size: compressedFile.size
                    };

                    allFiles.push(fileInfo);

                    if (allFiles.length == files.length) {
                        onDone(allFiles[0]);
                    }
                }
            }
        }
    }

    function loadImage(file: any) {
        return new Promise<any>(resolve => {
            let reader = new FileReader();
            reader.onerror = async () => {
                resolve(undefined);
            };

            reader.onload = async () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }

    function isValidFileTypeSelected(fileType: string) {
        var isValid = false;
        var extensionArray = acceptedFileExtensions.split(",");

        extensionArray.forEach(function (arrFileType) {
            if (fileType.includes(arrFileType.trim().replace(".", ""))) {
                isValid = true;
            }
        });

        if (!isValid) {
            ns.error("", "Incorrect file type uploaded. We only support " + acceptedFileExtensions);
            handleClear();
        }

        return isValid;
    }

    function isValidSize(fileType: string, fileSize: number) {
        // Check if the file is a PDF and if its size exceeds 10MB.
        if (fileType.includes("pdf") && fileSize > (MAX_FILE_SIZE_MB * 1024 * 1024)) {
            ns.error("", "The PDF file being uploaded is too big. A maximum file size of " + MAX_FILE_SIZE_MB + "MB is allowed");
            handleClear();
            return false;
        }

        if (fileSize > (maxImageSize * 1024)) {
            ns.error("", "The file being uploaded is too big. A maximum file size of " + Math.floor((fileType.includes("image") ? maxImageSize : maxFileSize) / 1000) + "MB is allowed");
            handleClear();
        }
        else {
            return true;
        }

        return false;
    }

    const onDone = (file: File) => {
        if (onFileUpload) {
            onFileUpload(file);
        }
    }

    const handleClick = (event) => {
        hiddenFileInputReference.current.click();
    };

    const handleClear = () => {
        hiddenFileInputReference.current.value = "";
    };

    const UploadIcon = () => {
        if (uploadedFile) {
            return <FaCheck />;
        } else {
            return <FaFileUpload />;
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant="contained"
                className={
                    (uploadedFile && uploadedFile !== ""
                        ? "btn-success"
                        : "btn-primary") +
                    " text-white m-1 text-capitalize shadow-none hover-scale-sm border-0 d-inline-flex align-items-center justify-content-center"
                }
                style={{ padding: '11px', width: buttonSize }}
            >
                <span className="btn-wrapper--icon">
                    <UploadIcon />
                </span>
                <span className="btn-wrapper--label font-size-sm">{uploadedFile && uploadedFile !== "" ? buttonFileUploadedText : buttonText}</span>
            </Button>

            <input
                type="file"
                ref={hiddenFileInputReference}
                onChange={handleChange.bind(this)}
                multiple={false}
                accept={acceptedFileExtensions}
                style={{ display: "none" }}
            />
        </>
    );
};

export default FileUploader;

