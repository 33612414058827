import { FC, memo } from 'react';
import { useBiddingTableStyles } from '../BiddingTable.styles';
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { Skeleton } from '../../../dumb/Skeleton';
import { CurrencyLabel } from '../../../composite/CurrencyLabel';

type GuidePriceProps = {
    showLoading?: boolean;
    guidePrice: number | undefined;
    registrationStatus: any;
}

const GuidePrice: FC<GuidePriceProps> = ({ showLoading = false, guidePrice, registrationStatus }) => {

    const { classes: { leftRightTextContainer } } = useBiddingTableStyles();

    return (
        <>
            {showLoading ? (
                <div className={leftRightTextContainer}>
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                    <Skeleton animation="wave" variant="text" component="h3" width="40%" />
                </div>
            ) : (
                <>
                    {guidePrice && guidePrice > 0 ? (
                        <>
                            {!PrembidLocalStorage.currentUser?.userId ? (
                                <div className={leftRightTextContainer}>
                                    <h5 className="text-black text-left">Guide Price</h5>
                                    <h5 className="text-black text-right">
                                        <CurrencyLabel value={guidePrice} />
                                    </h5>
                                </div>
                            ) : (
                                registrationStatus !== 1 && (
                                    <div className={leftRightTextContainer}>
                                        <h5 className="text-black text-left">Guide Price</h5>
                                        <h5 className="text-black text-right">
                                            <CurrencyLabel value={guidePrice} />
                                        </h5>
                                    </div>
                                )
                            )}
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default memo(GuidePrice);
