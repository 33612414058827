import { FC, memo, CSSProperties, useCallback, useEffect } from "react";

import ConfirmationDialog from "@/components/Modal/confirmationModal";
import { useConfirmationDialogActivatorStyles } from "./ConfirmationDialogActivator.styles";
import { useConfirmationDialogActivatorStore } from "@/store/ConfirmationDialogActivatorStore";

export interface ConfirmationDialogActivatorProps {
	containerStyle?: CSSProperties;
}

const ConfirmationDialogActivator: FC<
	ConfirmationDialogActivatorProps
> = ({ containerStyle }) => {
	const classes = useConfirmationDialogActivatorStyles();
	const { open, payload, description, okPressed, cancelPressed, title, update, reset} =
		useConfirmationDialogActivatorStore();

	const handleCancel = useCallback(() => {
		update({ cancelPressed: true });
	}, [update]);

	const handleOk = useCallback((payload) => {
		update({ okPressed: true });
	}, [update]);

    useEffect(()=>{
        if(okPressed || cancelPressed){
			update({open:false});
        }
    },[cancelPressed, okPressed, reset, update])

	useEffect(()=>{
		if(!open) setTimeout(reset,200);
	},[open, reset])

	return (
		<ConfirmationDialog
			open={open}
			payload={payload}
			okButtonText="Ok"
			cancelButtonText="Cancel"
			description={description}
			title={title}
			onCancel={handleCancel}
			onOk={handleOk}
		/>
	);
};

export default memo(ConfirmationDialogActivator);
