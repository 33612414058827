import { Box } from '@mui/material';
import { FC, memo } from 'react';
import { useBiddingTableStyles } from '../BiddingTable.styles';
import { AuctionTypeEnum } from '../../../../lib/utils/AuctionTypeEnum';
import { getNewDateWithOffset } from 'prembid-shared-library-npm/helpers';
import { CountdownRenderProps, zeroPad } from 'react-countdown';
import { Countdown } from "@/components/library/Countdown";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/Store';
import { Skeleton } from '../../../dumb/Skeleton';

type CountdownTimerContainerProps = {
    showLoading?: boolean;
    auctionType: number | undefined;
    isActive: boolean | undefined;
    validating: boolean;
    isExtended: boolean;
    isWinning: boolean;
    profileId: any;
    start: Date | undefined;
    end: Date | undefined;
    now: Date;
    countDownTimer: any;
}

const CountdownTimerContainer: FC<CountdownTimerContainerProps> = ({ showLoading = false, auctionType, isActive, validating, isExtended, isWinning, profileId, start, end, now, countDownTimer }) => {

    const { classes: { headerContainer, countDownContainer, countDownText } } = useBiddingTableStyles();

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const CountdownRenderer = ({ days, hours, minutes, seconds }: CountdownRenderProps & { countdownHeader: string }) => {
        return (
            <div>
                <h5><span>{zeroPad(days)}</span><span className={countDownText}> dd:</span> <span> {zeroPad(hours)}</span><span className={countDownText}> hh:</span> <span> {zeroPad(minutes)}</span><span className={countDownText}> mm:</span> <span> {zeroPad(seconds)}</span><span className={countDownText}> ss</span></h5>
            </div>
        );
    };

    return <>
        {
            showLoading ? (
                <Skeleton animation="wave" variant="rectangular" height="75px" width="100%" />
            ): (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-evenly"
                            className={headerContainer}>
                            <div style={{ flex: '0 1 auto', marginLeft: 5 }}>
                                {!isActive ? (
                                    <h5 className={"badge badge-pill badge-dark text-capitalize"}>{"Unavailable"}</h5>
                                ) : (
                                    <>
                                        {validating && <h5 className={"badge badge-pill badge-dark text-capitalize"}>{"Validating"}</h5>}
                                        {!validating && (
                                            <>
                                                {!!start && now < start && (
                                                    <h5 className={"badge badge-pill badge-dark text-capitalize"}>{"Awaiting"}</h5>
                                                )}
                                                {!!end && !!start && start < now && now < end && isExtended && (
                                                    <h5 className={"badge badge-pill badge-warning text-capitalize"}>{"Extended"}</h5>
                                                )}
                                                {!!end && !!start && start < now && now < end && !isExtended && (
                                                    <h5 className={"badge badge-pill badge-success text-capitalize"}>{"Open"}</h5>
                                                )}
                                                {!!end && now > end && (
                                                    <h5 className={"badge badge-pill badge-danger text-capitalize"}>{"Completed"}</h5>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                            <div
                                className={countDownContainer}>
                                {!!start && now < start && (
                                    <h5 className="text-center text-white">{"Starting in"}</h5>
                                )}
                                {!!end && !!start && start < now && now < end && isExtended && (
                                    <h5 className="text-center text-white">{"Closing in"}</h5>
                                )}
                                {!!end && !!start && start < now && now < end && !isExtended && (
                                    <h5 className="text-center text-white">{"Closing in"}</h5>
                                )}
                                {!!end && now > end && (
                                    <h5 className="text-center text-white">{"Closing in"}</h5>
                                )}
                                <h5 className="text-center text-white">
                                    <Countdown
                                        now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                        date={countDownTimer?.countdownDate}
                                        renderer={(props) => CountdownRenderer({ ...props, countdownHeader: countDownTimer?.countdownHeader })}
                                    />
                                </h5>
                            </div>

                            <div style={{ marginLeft: 'auto', marginRight: 5 }}>
                                {auctionType !== AuctionTypeEnum.Tender && profileId && <>

                                    {
                                        isWinning ? (
                                            <h5 className="badge badge-pill badge-success text-capitalize">Winning</h5>
                                        ) : (
                                            <h5 className="badge badge-pill badge-danger text-capitalize">Losing</h5>
                                        )
                                    }
                                </>
                                }
                            </div>
                        </Box>
                    </>
            )
        }
    </>
}

export default memo(CountdownTimerContainer);