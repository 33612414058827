import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';
import { ceil } from 'lodash';

const SUBMIT_BID_SUCCESS = 'SUBMIT_BID_SUCCESS';
const SUBMIT_BID_ERROR = 'SUBMIT_BID_ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const CLEAR_BID_ERROR = 'CLEAR_BID_ERROR';
const BID_SUCCESS = 'BID_SUCCESS';
const RETRIEVE_PAGE_DATA_SUCCESS = 'RETRIEVE_PAGE_DATA_SUCCESS';
const CLEAR_BIDDING_DATA = 'CLEAR_BIDDING_DATA';

const initialState = {
    error: false,
    errorText: '',
    success: false,
    token: undefined,
    biddingData: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case SUBMIT_BID_SUCCESS: return { ...state, error: false, errorText: '', success: true, id: action.payload }
        case SUBMIT_BID_ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, error: false, success: false, id: '' }
        case CLEAR_BID_ERROR: return { ...state, error: false, errorText: '', success: false, id: '' }
        case BID_SUCCESS: return { ...state, token: action.payload }
        case RETRIEVE_PAGE_DATA_SUCCESS: return { ...state, biddingData: action.payload }
        case CLEAR_BIDDING_DATA: return { ...state, biddingData: undefined }

        default: return state
    }
}

// Action creators
export const error = (errorText: string) => {
    return { type: SUBMIT_BID_ERROR, payload: errorText }
}

const success = (pageData: any) => {
    return { type: SUBMIT_BID_SUCCESS, payload: pageData }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const clearBidResponses = () => {
    return { type: CLEAR_BID_ERROR, payload: CLEAR_BID_ERROR }
}

export const clearBiddingData = () => {
    return { type: CLEAR_BIDDING_DATA, payload: CLEAR_BIDDING_DATA }
}

export const retrievePageDataSuccess = (pageData: any) => {
    return { type: RETRIEVE_PAGE_DATA_SUCCESS, payload: pageData }
}

export const submitBid = (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        let bid = {
            auctionId,
            lotId,
            amount: ceil(amount, 2),
            profileId,
            isTenderSubmission
        }

        return requestClient.post(apiPaths.bids.create, bid)
            .then(function (res) {
                if (res.success) {
                    dispatch(success(res.success));
                } else {
                    dispatch(error(res.error));
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}
export const retrieveBiddingPageData = (auctionId: string, lotId: string, profileId: string) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.bids.retrieveBiddingPageData, null, { auctionId: auctionId, lotId: lotId, profileId: profileId })
            .then(function (res) {
                if (res.success) {
                    let data = res.response;
                    dispatch(retrievePageDataSuccess(data));
                } else {
                    dispatch(error(res.error));
                }
            })
    }
}

export const submitMaximumBid = (auctionId: string, lotId: string, amount: number, automaticBidAmount: number, profileId: string, onCompletedCallback?: (res: any) => void) => {

    return function (dispatch: any) {
        let maximumBid = {
            auctionId,
            lotId,
            amount,
            automaticBidAmount,
            profileId,
        }

        return requestClient.post(apiPaths.bids.createMaximumBid, maximumBid)
            .then(function (res) {
                if (res.success) {
                    dispatch(success(res.success));
                } else {
                    dispatch(error(res.error));
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}