import { FC, memo } from "react";
import { Box, Chip, Grid, List, ListItem } from "@mui/material";
import { FaVideo, FaImage } from "react-icons/fa";
import { useSTCStatus } from "prembid-shared-library-npm/hooks";
import { ImageCarousel } from "../ImageCarousel";
import { VideoCarousel } from "../VideoCarousel";

export interface ImageAndVideoCarouselProps {
    imageUrls: string[];
    videoUrls: string[];
    stcStatus: string;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    showLoading?: boolean;
}

const ImageAndVideoCarousel: FC<ImageAndVideoCarouselProps> = ({ imageUrls, videoUrls, stcStatus, activeTab, setActiveTab, showLoading = false }) => {

    const STCStatus = useSTCStatus(stcStatus);

    return <>
        <Box sx={{ position: 'relative' }}>
            <List className="nav-tabs nav-tabs-primary d-flex ml-2 mr-2">
                <ListItem
                    button
                    disableRipple
                    selected={activeTab === "0"}
                    onClick={() => setActiveTab("0")}
                >
                    <Grid container alignItems={'center'} spacing={0.5}>
                        <Grid item>
                            <FaImage />
                        </Grid>
                        <Grid item>
                            {"Images"}
                        </Grid>
                    </Grid>
                </ListItem>
                {videoUrls?.length > 0 && (
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "1"}
                        onClick={() => setActiveTab("1")}
                    >
                        <Grid container alignItems={'center'} spacing={0.5}>
                            <Grid item>
                                <FaVideo />
                            </Grid>
                            <Grid item>
                                {"Videos"}
                            </Grid>
                        </Grid>
                    </ListItem>
                )}
            </List>

            {
                STCStatus &&
                <Chip sx={{ position: 'absolute', top: { sm: '78px', xs: '78px', md: '78px', lg: '53px' }, right: '7px', zIndex: 2, borderRadius: 3, padding: 2 }} label={STCStatus} />
            }

            <div
                className={"tab-item-wrapper " + (activeTab === "0" ? "active" : "")}
                key={0}>
                <ImageCarousel images={imageUrls?.map((url) => url)} showLoading={showLoading} />
            </div>

            <div
                className={"tab-item-wrapper " + (activeTab === "1" ? "active" : "")}
                key={1}>
                <VideoCarousel videos={videoUrls?.map((url) => url)} showLoading={showLoading} showThumbnails={true} />
            </div>
        </Box>
    </>
};

export default memo(ImageAndVideoCarousel);
