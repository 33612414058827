import * as React from 'react';
import './bamburgh-theme/assets/base.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import NewVersionModal from './NewVersionModal'
import NotificationServiceWrapper from '@/components/NotificationServiceWrapper/NotificationServiceWrapper';
import { signalRUpdateConnectionStatus } from './store/SignalR';
import { useSignalRNegotiation } from 'prembid-shared-library-npm/signalr';
import { useDispatch } from 'react-redux';
import { CustomDialog } from './components/containers/CustomDialog';
import { ScrollUpButton } from './components/composite/ScrollUpButton';
import { ConfirmationDialogActivator } from './components/containers/ConfirmationDialogActivator';
import { ProgressSpinner } from './components/wrappers/ProgressSpinner';

export default () => {
    const dispatch = useDispatch();
    useSignalRNegotiation({
        onConnectionStatusChange: (connectionStatus) => dispatch(signalRUpdateConnectionStatus(connectionStatus)),
    });

    return <BrowserRouter>
        <React.StrictMode>
            <ScrollUpButton ShowAtPosition={600} StopPosition={500} style={{ width: 35, height: 35, background: "#2b2b2b", borderRadius: "100px", padding: "5px" }} />
            <NotificationServiceWrapper />
            <ProgressSpinner />
            <CustomDialog />

            <Routes />
            <NewVersionModal />
            <ConfirmationDialogActivator />
        </React.StrictMode>
    </BrowserRouter>
}