import React, { FC, ReactNode, memo } from "react";
import { useSelector } from "react-redux";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ns from '@/lib/utils/NotificationService';

import { NetworkRequestContextProvider } from "prembid-shared-library-npm/networking";
import { UserAuth } from "prembid-shared-library-npm/networking/types";

import { queryClient } from "@/networking/queryClient";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { RootState } from "@/store/Store";
import { QueryClientProvider } from "@tanstack/react-query";
import { tokenProvider } from "prembid-shared-library-npm/helpers";

interface NetworkProviderProps {
	children: ReactNode;
}

const NetworkProvider: FC<NetworkProviderProps> = ({ children }) => {
	const userAuth: UserAuth = {};

	//when refreshing page after login
	const currentUserInSession = PrembidLocalStorage.currentUser;

	// on initial login - storing of user in store
	const currentUserInStore = useSelector((state:RootState)=> state.loginForm.user);

	if(currentUserInSession?.jwtToken && currentUserInSession?.userId ){
		userAuth.token = currentUserInSession.jwtToken;
		userAuth.userId = currentUserInSession.userId;

		tokenProvider.setToken(currentUserInSession.jwtToken);
		tokenProvider.setUserId(currentUserInSession.userId);
	}

	if(currentUserInStore?.jwtToken && currentUserInStore?.userId ){
		userAuth.token = currentUserInStore.jwtToken;
		userAuth.userId = currentUserInStore.userId;

		tokenProvider.setToken(currentUserInStore.jwtToken);
		tokenProvider.setUserId(currentUserInStore.userId);
	}
	
	return (
		<NetworkRequestContextProvider
			queryClient={queryClient}
			requestConfig={{
				baseUrl: "",
				userAuth,
				globalErrorCallback({error:{message}}) {
					ns.error('',message);
				},
			}}>
			{children}
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</NetworkRequestContextProvider>
	);
};

export default memo(NetworkProvider);
