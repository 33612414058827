import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';
import { RegisteredUserStatusEnum } from "@/lib/utils/RegisteredUserStatusEnum";
import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage'
import { isArray } from 'lodash';
import { toggleLoginRegisterModal } from "@/components/LoginForm/store/LoginForm";

const PARTICIPATE_ERROR = 'PARTICIPATE_ERROR';
const PARTICIPATE_ERROR_COMPLEX = 'PARTICIPATE_ERROR_COMPLEX';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
const WIZARD_SUCCESS = 'WIZARD_SUCCESS';
const WIZARD_ERROR = 'WIZARD_ERROR';
const CLEAR_WIZARD_RESPONSES = 'CLEAR_WIZARD_RESPONSES';

interface initialStateInterface {
    error: boolean,
    errorText: '',
    reload: boolean,
    validateResult: any,
    success: boolean,
    id: string,
    errorResult: any,
    wizardSuccess: boolean,
    wizardError: boolean,
    registrationStatus: any[]
}

const initialState: initialStateInterface = {
    error: false,
    errorText: '',
    reload: false,
    validateResult: undefined,
    success: false,
    id: '',
    errorResult: undefined,
    wizardSuccess: false,
    wizardError: false,
    registrationStatus: []
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case PARTICIPATE_ERROR:
            return { ...state, error: true, errorText: action.payload };
        case PARTICIPATE_ERROR_COMPLEX:
            return {
                ...state,
                error: true,
                errorText: '',
                errorResult: action.payload,
            };
        case CLEAR_API_RESPONSES:
            return {
                ...state,
                error: false,
                success: false,
                id: '',
                errorText: '',
                errorResult: undefined,
                wizardError: false,
                wizardSuccess: false,
            };
        case CLEAR_WIZARD_RESPONSES:
            return {
                ...state,
                wizardError: false,
                wizardSuccess: false,
            };
        case CREATE_USER_SUCCESS: return { ...state, error: false, errorText: '', success: true, id: action.payload }
        case VALIDATE_USER_SUCCESS:
            let tempList = [...state.registrationStatus];

            if (isArray(action.payload)) {
                action.payload.forEach(newItem => {
                    let index = tempList.findIndex(existingItem => newItem.auctionId === existingItem.auctionId)

                    let replacement = { auctionId: newItem.auctionId, status: newItem.status };

                    if (index === -1)
                        tempList.push(replacement);
                    else {
                        tempList.splice(index, 1, replacement);
                    }
                });
            }
            return { ...state, validateResult: action.payload, registrationStatus: tempList }
        case WIZARD_SUCCESS:
            return { ...state, wizardSuccess: true, wizardError: false };
        case WIZARD_ERROR:
            return { ...state, wizardSuccess: false, wizardError: true };
        default: return state
    }
}

// Action creators
const error = (errorText: string) => {
    return { type: PARTICIPATE_ERROR, payload: errorText };
};

const errorComplex = (errorResponse: any) => {
    return { type: PARTICIPATE_ERROR_COMPLEX, payload: errorResponse };
};

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES };
};

export const clearWizardResponses = () => {
    return { type: CLEAR_WIZARD_RESPONSES, payload: CLEAR_WIZARD_RESPONSES };
};

const regUsersCreateSuccess = (id: string) => {
    return { type: CREATE_USER_SUCCESS, payload: id }
}

const regUsersValidateSuccess = (vaildateResult: any) => {
    return { type: VALIDATE_USER_SUCCESS, payload: vaildateResult }
}

const wizardSuccess = () => {
    return { type: WIZARD_SUCCESS };
};

const wizardError = (errorResult: any) => {
    return { type: WIZARD_ERROR, payload: errorResult };
};

// Thunks
export const handleRegisteredUserCreate = (auctionId: string, profileId: string, onCompletedCallback?: (result: any) => void) => {
    return function (dispatch: any) {

        const currentUser = PrembidLocalStorage.currentUser;
        if (currentUser === null || !currentUser.userId) {
            if (onCompletedCallback) onCompletedCallback({ success: false, dontShowError: true })
            dispatch(toggleLoginRegisterModal(true))
        } else {
            if (profileId.length > 0) {
                const newRegUser = {
                    profileId,
                    auctionId,
                    status: RegisteredUserStatusEnum.Awaiting
                }

                return requestClient.post(apiPaths.profile.create, newRegUser)
                    .then(function (res) {
                        if (res.success) {
                            dispatch(regUsersCreateSuccess(auctionId))
                        } else if (res.success === false && res.response !== null) {
                            dispatch(errorComplex(res.response));
                        } else {
                            dispatch(error(res.error));
                        }

                        if (onCompletedCallback)
                            onCompletedCallback(res)
                    })
            } else {
                if (onCompletedCallback) onCompletedCallback({ success: false, error: "Please create a profile before participating in an auction" })
            }
        }
    }
}

export const handleRegistrationWizardModalDataSubmission = (submitData: any, onCompletedCallback?: (result: any) => void) => {
    return function (dispatch: any) {

        return requestClient.post(apiPaths.profile.submitAuctionRegistrationWizardData, submitData)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            });

    };
};


export const handleRegisteredUsersValidate = (auctionIds: string[], profileId: string, onCompletedCallback?: (result: any) => void) => {
    return function (dispatch: any) {

        if (profileId.length > 0) {

            const validate = {
                profileId,
                auctionIdList: auctionIds
            }

            return requestClient.post(apiPaths.profile.validate, validate)
                .then(function (res) {
                    if (res.success) {
                        const result = res.response;
                        dispatch(regUsersValidateSuccess(result))
                    }
                    if (onCompletedCallback)
                        onCompletedCallback(res)
                })
        }
    }
}