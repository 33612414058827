import { AuctionTypeEnum } from 'prembid-shared-library-npm/types';
import { FC, memo } from 'react';
import { Skeleton } from '../../../dumb/Skeleton';

type HeadingProps = {
    showLoading?: boolean;
    auctionType: number | undefined;
}

const Heading: FC<HeadingProps> = ({ showLoading = false, auctionType }) => {
    return <>
        {
            showLoading ? (
                <Skeleton animation="wave" variant="text" component="h2" width="100%" />
            ) : (
                <h4 className="text-black text-center">{auctionType?.toString() == AuctionTypeEnum.Timed.toString() ? "Bidding" : "Tendering"}</h4>
            )
        }
    </>
}

export default memo(Heading);