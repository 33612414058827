import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';
import { handleChangeProfile } from '@/components/LoginForm/store/LoginForm';
import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage';

//Action
const ERROR = 'ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const CLEAR_INDIVIDUAL_PROFILE = 'CLEAR_INDIVIDUAL_PROFILE';
const CREATE_INDIVIDUAL_PROFILE_SUCCESS = 'CREATE_INDIVIDUAL_PROFILE_SUCCESS';
const RETRIEVE_INDIVIDUAL_PROFILE_SUCCESS = 'RETRIEVE_INDIVIDUAL_PROFILE_SUCCESS';
const UPDATE_INDIVIDUAL_PROFILE_SUCCESS = 'UPDATE_INDIVIDUAL_PROFILE_SUCCESS';
//GL: Not implemented
//const DELETE_INDIVIDUAL_PROFILE_SUCCESS = 'DELETE_INDIVIDUAL_PROFILE_SUCCESS';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    success: false,
    editSuccess: false,
    profileId: undefined,
    profile: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_INDIVIDUAL_PROFILE: return { ...state, profileId: undefined, profile: undefined }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false }
        case CREATE_INDIVIDUAL_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', profileId: action.payload, reload: !state.reload, success: true }
        case UPDATE_INDIVIDUAL_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', success: true }
        case RETRIEVE_INDIVIDUAL_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', profile: action.payload }
        default: return state
    }
}

//Action creator
export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const clearIndividualProfile = () => {
    return { type: CLEAR_INDIVIDUAL_PROFILE, payload: CLEAR_INDIVIDUAL_PROFILE }
}

export const createIndividualProfileSuccess = (id: any) => {
    return { type: CREATE_INDIVIDUAL_PROFILE_SUCCESS, payload: id }
}

export const updateIndividualProfileSuccess = () => {
    return { type: UPDATE_INDIVIDUAL_PROFILE_SUCCESS, payload: UPDATE_INDIVIDUAL_PROFILE_SUCCESS }
}

export const retrieveIndividualProfileSuccess = (profileData: any) => {
    return { type: RETRIEVE_INDIVIDUAL_PROFILE_SUCCESS, payload: profileData }

}

export const handleIndividualProfileCreate = (formFields: any) => {
    return function (dispatch: any) {

        const individualProfileData = {
            FirstName: formFields.firstName,
            LastName: formFields.lastName,
            Email: formFields.email.replace(" ", ""),
            PhoneNumber: formFields.phoneNumber,
            Address: formFields.address,
            DataCapturedDictionary: {},
            IdentityUserId: formFields.identityUserId
        }

        return requestClient.post(apiPaths.profile.individualBiddingProfileCreate, individualProfileData)
            .then(function (res) {
                if (res.success) {
                    dispatch(createIndividualProfileSuccess(res.response))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleIndividualProfileUpdate = (formFields: any) => {
    return function (dispatch: any) {

        const individualProfileData = {
            Id: formFields.id,
            FirstName: formFields.firstName,
            LastName: formFields.lastName,
            Email: formFields.email.replace(" ", ""),
            PhoneNumber: formFields.phoneNumber,
            Address: formFields.address,
            DataCapturedDictionary: {},
            IsDefaultProfile: formFields.isDefaultProfile,
        }

        return requestClient.post(apiPaths.profile.individualBiddingProfileUpdate, individualProfileData)
            .then(function (res) {
                if (res.success) {
                    dispatch(updateIndividualProfileSuccess());

                    //TODO: Discuss with Darryn
                    if(formFields.isDefaultProfile){
                        dispatch(handleChangeProfile(formFields.id));
                    }
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleIndividualProfileFetch = (id: any) => {

    const individualProfileData = {
        profileId: id
    }

    return (dispatch: any) => {
        return requestClient.get(apiPaths.profile.retrieveIndividualBiddingProfile, null, individualProfileData)
            .then(function (resp) {
                if (resp.success) {
                    const jsonData = resp.response;
                    dispatch(retrieveIndividualProfileSuccess(jsonData[0]))
                } else {
                    dispatch(error(resp.error))
                }
            })
    }

}