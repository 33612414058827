import { PrembidLocalStorage } from '@/lib/utils/PrembidLocalStorage';
import { Button, CircularProgress, Container, Divider, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, } from 'react-redux';
import { clearMyActivityWishlistStatus, handleRetrieveAllLotWishlist, handleRetrieveAllMyBiddingActivity, clearApiData } from './store/MyActivity';
import { clearResponses } from '../Lots/store/Lots';
import LotDetailCard from '@/components/Cards/LotDetailCard';
import { handleAuctionByIdFetch } from '../Auctions/store/Auctions';
import ns from '@/lib/utils/NotificationService';
import useHistory from '../../lib/hooks/useHistory';
import useStyles from '../MyActivity/MyActivityStyles';
import { isArray } from 'lodash';
import { useSignalRConnectionStatus } from 'prembid-shared-library-npm/signalr';
import { HeaderBlock } from '../../components/composite/HeaderBlock';
import { Pagination } from '../../components/composite/Pagination';

interface Props {
    handleLotWishlistDataFetch_d: (profileId: string, filters: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleMyBiddingActivityDataFetch_d: (profileId: string, filters: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    clearResponses_d: () => void,
    clearApiData_d: () => void,
    loading: boolean,
    settings: any,
    contact?: any,
    profileId: any,
    error: any,
    errorText: string,
    lotWishlistData: any,
    myBiddingActivityData: any,
    lotWishlistPagination?: any,
    myBiddingActivityPagination?: any,
    onHandleChangePage?: (pagination?: any) => void,
    availableFilters?: any[],
    selectedFilters?: any,
    onFiltersChange?: (filters: any) => void,
    isMyActivityWishlistObject: any,
    clearMyActivityWishlistStatus_d: () => void,
}

const MyActivity: React.FC<Props> = props => {
    const {
        handleLotWishlistDataFetch_d,
        handleMyBiddingActivityDataFetch_d,
        clearApiData_d,
        settings,
        isMyActivityWishlistObject,
        clearMyActivityWishlistStatus_d
    } = props;

    const history = useHistory();
    const { classes } = useStyles();

    let sortBy: string[] = ["Number: Ascending", "Number: Descending", "Opening Bid: Low to High", "Opening Bid: High to Low", "Time: Remaining"];
    const initialFilters = {
        lotType: "open",
        sortBy: sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")],
    };

    const [currentProfileId, setCurrentProfileId] = useState<any>(PrembidLocalStorage.currentProfile);
    const [loading, setLoading] = useState<boolean>(true);

    const [activityData, setActivityData] = useState<any>(null);
    const [wishlistData, setWishlistData] = useState<any>(null);

    const [filterFields, setFilterFields] = useState<any>(initialFilters);
    const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined);

    const [lastTop, setLastTop] = useState<any>(0);

    useEffect(() => {
        const urlSearchParams: any = new URLSearchParams(window.location.search);
        const params: any = Object.fromEntries(urlSearchParams.entries());
        const { type = undefined, filter = undefined, sortBy = undefined, top = undefined, ...tempParams } = params;

        if (selectedTab === undefined) {
            switch (type) {
                case 'wishlist': setSelectedTab(1); break;
                case 'activity':
                default: setSelectedTab(0); break;
            }
            if (filter) setFilterFields({ ...filterFields, lotType: filter });
            if (sortBy) setFilterFields({ ...filterFields, sortBy: sortBy });
            if (top) setLastTop(top);
        }
        else if (selectedTab === 0) {
            refreshMyActivity(filterFields, activityData?.pagination ?? tempParams);
        }
        else if (selectedTab === 1) {
            refreshMyWishlist(filterFields, wishlistData?.pagination ?? tempParams);
        }
    }, [selectedTab, currentProfileId, filterFields]);

    useEffect(() => {
        if (activityData?.pagination) {
            const { total, ...rest } = activityData?.pagination;

            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(undefined)

            let path = `/myActivity?` + new URLSearchParams({ ...rest, type: 'activity', filter: filterFields?.lotType, sortBy: filterFields?.sortBy }).toString();
            history.replace(path);
        }
    }, [activityData?.pagination])

    useEffect(() => {
        if (wishlistData?.pagination) {
            const { total, ...rest } = wishlistData?.pagination;

            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(undefined)

            let path = `/myActivity?` + new URLSearchParams({ ...rest, type: 'wishlist', filter: filterFields?.lotType, sortBy: filterFields?.sortBy }).toString();
            history.replace(path);
        }
    }, [wishlistData?.pagination])

    useEffect(() => {
        if (PrembidLocalStorage.currentProfile !== currentProfileId) {
            setCurrentProfileId(PrembidLocalStorage.currentProfile);
        }
    }, [PrembidLocalStorage.currentProfile]);

    const refreshMyActivity = (filters?: any, pagination?: any) => {
        setLoading(true);
        clearApiData_d();

        handleMyBiddingActivityDataFetch_d(PrembidLocalStorage.currentProfile, filters, pagination, (res) => {
            if (res.success) {
                setActivityData(null);
                setActivityData(res.response);
            } else {
                ns.error('', res.error);
            }

            setLoading(false);
        });
    }

    const refreshMyWishlist = (filters?: any, pagination?: any) => {
        setLoading(true);
        clearApiData_d();

        handleLotWishlistDataFetch_d(PrembidLocalStorage.currentProfile, filters, pagination, (res) => {
            if (res.success) {
                setWishlistData(null);
                var data = res.response;
                const newList = data.list.map((x: any) => {
                    x.lots.isOnWishlist = true;
                    return x;
                });
                setWishlistData({ ...data, list: newList });
            } else {
                ns.error('', res.error);
            }

            setLoading(false);
        });
    }

    useEffect(() => {
        if (isMyActivityWishlistObject?.lotId !== "") {
            if (selectedTab === 0) {
                const index = activityData?.list?.findIndex((e: any) => e.id === isMyActivityWishlistObject?.lotId);
                if (index > -1) {
                    let temp = { ...activityData }
                    let tempObject = temp.list[index];
                    tempObject.isOnWishlist = !(tempObject?.isOnWishlist);
                    temp.list[index] = tempObject;
                    setActivityData(temp);
                }
            }

            if (selectedTab === 1) {
                const index = wishlistData?.list?.findIndex((e: any) => e.lots?.id === isMyActivityWishlistObject?.lotId);
                if (index > -1) {
                    let temp = { ...wishlistData }
                    let tempObject = temp.list[index].lots;
                    if (tempObject.isOnWishlist !== undefined) {
                        tempObject.isOnWishlist = !(tempObject?.isOnWishlist);
                    } else {
                        tempObject.isOnWishlist = false
                    }

                    temp.list[index].lots = tempObject;
                    setWishlistData(temp);
                }
            }

            clearMyActivityWishlistStatus_d();
        }

    }, [isMyActivityWishlistObject])

    function getLotFeatures(lot: any) {
        let featuresArr: any[] = [];

        if (lot?.dataCaptured?.additionalProperties) {
            lot.dataCaptured.additionalProperties.map(x => {
                if (x.name !== '' && x.value !== '' && !x.hidden)
                    featuresArr.push({ ...x, key: x.name })
            });

            featuresArr.sort((a, b) => a.order - b.order);
        }

        return featuresArr;
    }

    const handleWishlistPageChange = (pagination: any) => {
        refreshMyWishlist(filterFields, pagination)
    }

    const handleMyActivityPageChange = (pagination: any) => {
        refreshMyActivity(filterFields, pagination)
    }

    const handleSelectedFilterClick = (lotType: string) => {
        const urlSearchParams: any = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        let path = `/myActivity?` + new URLSearchParams({ ...params, filter: lotType, sortBy: filterFields?.sortBy }).toString();
        history.replace(path);

        setFilterFields({ ...filterFields, lotType: lotType });
    }

    const handleSortByValueChanged = (e: any) => {
        let index = sortBy.findIndex((item) => item === e.target.value)
        setFilterFields({ ...filterFields, sortBy: sortBy[index] });
    }

    const handleApplyClicked = () => {
        if (selectedTab === 0) {
            refreshMyActivity(filterFields, activityData?.pagination);
        }
        else if (selectedTab === 1) {
            refreshMyWishlist(filterFields, wishlistData?.pagination);
        }
    }

    useSignalRConnectionStatus({
        onConnect: () => {
            const urlSearchParams: any = new URLSearchParams(window.location.search);
            const params: any = Object.fromEntries(urlSearchParams.entries());
            const { type = undefined, filter = undefined, sortBy = undefined, top = undefined, ...tempParams } = params;
            if (selectedTab === 0) {
                refreshMyActivity(filterFields, activityData?.pagination ?? tempParams);
            } else if (selectedTab === 1) {
                refreshMyWishlist(filterFields, wishlistData?.pagination ?? tempParams);
            }
        },
    });

    const renderMyActivityTab = () => <>
        <div className="mt-5" >
            <div className="text-center">
                <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>My Current Activity</h3>
            </div>
            <div className="text-center mb-5" >
                <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>Viewing your {filterFields?.lotType} activities!</h4>
            </div>
        </div>
        <div style={{ position: 'relative' }}>
            <Container maxWidth={false} className={`mb-3 ${classes.containerWidth}`}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    <Grid item sm={2}>
                        <Button fullWidth onClick={() => { handleSelectedFilterClick('open') }}
                            className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                            style={{}}>
                            <span className="btn-wrapper--label">
                                Open
                            </span>
                        </Button>
                    </Grid>
                    <Grid item sm={2}>
                        <Button fullWidth onClick={() => { handleSelectedFilterClick('completed') }}
                            className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                            style={{ background: '#2b2b2b' }}>
                            <span className="btn-wrapper--label">
                                Completed
                            </span>
                        </Button>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}>
                    {
                        filterFields?.sortBy === "Time: Remaining" &&
                        <Grid item xs={12} sm={3} md={2} lg={2} xl={2} >
                            <Button fullWidth onClick={() => { handleApplyClicked() }}
                                className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                                style={{ background: '#2b2b2b' }}>
                                <span className="btn-wrapper--label">
                                    Reorder {settings.LotName}{activityData?.list?.length > 1 ? "s" : ""}
                                </span>
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
                        <Select
                            margin='dense'
                            value={filterFields?.sortBy}
                            onChange={handleSortByValueChanged}
                            fullWidth
                            defaultValue={sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]}
                            variant="outlined"
                            style={{ background: '#ffffff', maxHeight: 50 }} >
                            {
                                sortBy?.map((value) => (
                                    <MenuItem
                                        key={value}
                                        value={value}>
                                        {value}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                </Grid>

                <Pagination
                    onHandleChangePage={handleMyActivityPageChange}
                    pagination={activityData?.pagination}
                    loading={loading}
                />
            </Container>
            <Container className="mb-5" style={{ minHeight: 150 }}>
                {loading && <div className="text-center mb-5 z-over" style={{ position: 'absolute', left: 'calc(50% - 50px)', top: '150px' }}>
                    <CircularProgress
                        className="text-center center mt-3"
                        style={{
                            margin: "auto",
                            color: settings?.Styles?.OverrideLoadingSpinnerColor
                                ? settings?.Styles?.OverrideLoadingSpinnerColor
                                : settings?.Styles?.Primary,
                        }}
                        size={80}
                    />
                </div>}
                <div style={{ opacity: loading ? 0.5 : 1, width: '100%' }}>
                    {(activityData?.pagination?.total || 0) === 0 &&
                        <Grid container style={{ flexGrow: 1 }} spacing={3} >
                            <Grid item sm={12}>
                                <h4 className="text-center" style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>
                                    {"No " + settings.LotName + "s to be displayed"}
                                </h4>
                            </Grid>
                        </Grid>
                    }
                    <Grid container style={{ flexGrow: 1 }} spacing={3} >
                        {activityData?.list?.map((lot: any) => {
                            return <LotDetailCard
                                key={lot.id}
                                imgSrc={lot?.imageUrl}
                                displayFullLengthImage={true}
                                header={lot?.name}
                                description={lot?.description}
                                auctionId={lot?.auctionId}
                                auctionType={lot?.auctionType}
                                lotId={lot?.id}
                                lotNumber={lot?.number}
                                startDateTimeAt={lot?.startDateTimeAt}
                                endDateTimeAt={lot?.endDateTimeAt}
                                successBadgeText={lot?.stcStatus?.toString()}
                                startingPrice={lot?.startingPrice}
                                guidePrice={lot?.guidePrice?.toString()}
                                dangerBadgeText={lot?.number}
                                lotFeatures={getLotFeatures(lot)}
                                hasExtensions={lot?.lotBidExtensionCount > 0 ? true : false}
                                isActive={lot?.isActive}
                                commission={lot?.commission > 0 ? lot?.commission?.toFixed(2) : 0}
                                registrationStatus={() => { }}
                                documentCount={(PrembidLocalStorage?.currentUser && PrembidLocalStorage?.currentProfile) ? (lot?.publicDocumentCount + lot?.privateDocumentCount) : lot?.publicDocumentCount}
                                wishListStatus={lot?.isOnWishlist}
                                overrideGuidePriceRulesAndAlwaysHide={true}
                                overrideShowBidButtonForCompletedLots={filterFields?.lotType === "open" ? false : true}
                                showReserve={lot?.showReserve}
                                highestBid={lot?.winningRegisteredProfileAmount}
                                highestBidProfileId={lot?.winningProfileId}
                                extendedBy={lot?.extendedBy}
                                lot={lot}
                            />
                        })}
                    </Grid>
                </div>
            </Container>
        </div>
    </>

    const renderMyWishlistTab = () => <>
        <div className="mt-5" >
            <div className="text-center">
                <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>My Wishlist</h3>
            </div>
            <div className="text-center mb-5" >
                <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }} >Viewing your {filterFields?.lotType} wishlist!</h4>
            </div>
        </div>
        <div style={{ position: 'relative' }}>
            <Container maxWidth={false} className={`mb-3 ${classes.containerWidth}`}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    <Grid item sm={2}>
                        <Button fullWidth onClick={() => { handleSelectedFilterClick('open') }}
                            className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                            style={{}}>
                            <span className="btn-wrapper--label">
                                Open/Upcoming
                            </span>
                        </Button>
                    </Grid>
                    <Grid item sm={2}>
                        <Button fullWidth onClick={() => { handleSelectedFilterClick('completed') }}
                            className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                            style={{ background: '#2b2b2b' }}>
                            <span className="btn-wrapper--label">
                                Completed
                            </span>
                        </Button>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={1}>
                    {
                        filterFields?.sortBy === "Time: Remaining" &&
                        <Grid item xs={12} sm={3} md={2} lg={2} xl={2} >
                            <Button fullWidth onClick={() => { handleApplyClicked() }}
                                className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                                style={{ background: '#2b2b2b' }}>
                                <span className="btn-wrapper--label">
                                    Reorder {settings.LotName}{wishlistData?.list?.length > 1 ? "s" : ""}
                                </span>
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
                        <Select
                            margin='dense'
                            value={filterFields?.sortBy}
                            onChange={handleSortByValueChanged}
                            fullWidth
                            defaultValue={sortBy[sortBy.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]}
                            variant="outlined"
                            style={{ background: '#ffffff', maxHeight: 50 }} >
                            {
                                sortBy?.map((value) => (
                                    <MenuItem
                                        key={value}
                                        value={value}>
                                        {value}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                </Grid>

                <Pagination
                    onHandleChangePage={handleWishlistPageChange}
                    pagination={wishlistData?.pagination}
                    loading={loading}
                />
            </Container>
            <Container className="mb-5" style={{ minHeight: 150 }}>
                {loading && <div className="text-center mb-5 z-over" style={{ position: 'absolute', left: 'calc(50% - 50px)', top: '150px' }}>
                    <CircularProgress
                        className="text-center center mt-3"
                        style={{
                            margin: "auto",
                            color: settings?.Styles?.OverrideLoadingSpinnerColor
                                ? settings?.Styles?.OverrideLoadingSpinnerColor
                                : settings?.Styles?.Primary,
                        }}
                        size={80}
                    />
                </div>}
                <div style={{ opacity: loading ? 0.5 : 1, width: '100%' }}>
                    {(wishlistData?.pagination?.total || 0) === 0 &&
                        <Grid container style={{ flexGrow: 1 }} spacing={3} >
                            <Grid item sm={12}>
                                <h4 className="text-center" style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>
                                    {"No " + settings.LotName + "s to be displayed"}
                                </h4>
                            </Grid>
                        </Grid>
                    }
                    <Grid container style={{ flexGrow: 1 }} spacing={3} >
                        {wishlistData?.list?.map((lots: any) => {
                            return <LotDetailCard
                                key={lots.lots.id}
                                imgSrc={lots?.lots?.imageUrl}
                                displayFullLengthImage={true}
                                header={lots?.lots?.name}
                                description={lots?.lots?.description}
                                auctionId={lots?.lots?.auctionId}
                                auctionType={lots?.lots?.auctionType}
                                lotId={lots?.lots?.id}
                                lotNumber={lots?.lots?.number}
                                startDateTimeAt={lots?.lots?.startDateTimeAt}
                                endDateTimeAt={lots?.lots?.endDateTimeAt}
                                successBadgeText={lots?.lots?.stcStatus?.toString()}
                                startingPrice={lots?.lots?.startingPrice}
                                guidePrice={lots?.lots?.guidePrice?.toString()}
                                dangerBadgeText={lots?.lots?.number}
                                lotFeatures={getLotFeatures(lots?.lots)}
                                hasExtensions={lots?.lots?.lotBidExtensionCount > 0 ? true : false}
                                isActive={lots?.lots?.isActive}
                                commission={lots?.lots?.commission > 0 ? lots?.lots?.commission?.toFixed(2) : 0}
                                registrationStatus={() => { }}
                                documentCount={(PrembidLocalStorage?.currentUser && PrembidLocalStorage?.currentProfile) ? (lots?.lots?.publicDocumentCount + lots?.lots?.privateDocumentCount) : lots?.lots?.publicDocumentCount}
                                wishListStatus={lots?.lots?.isOnWishlist}
                                overrideGuidePriceRulesAndAlwaysHide={true}
                                overrideShowBidButtonForCompletedLots={false}
                                showReserve={lots?.lots?.showReserve}
                                highestBid={lots?.lots?.winningRegisteredProfileAmount}
                                highestBidProfileId={lots?.lots?.winningProfileId}
                                extendedBy={lots?.lots?.extendedBy}
                                lot={lots?.lots}
                            />
                        })}
                    </Grid>
                </div>
            </Container>
        </div>
    </>

    return <div className="pb-5" style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <HeaderBlock imageSrc={settings.VendorBannerUrl} />

        <Container maxWidth={false} className={`${classes.containerWidth}`}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className="mb-4 mt-5">
                <Grid item sm={2}>
                    <Button fullWidth onClick={() => {
                        setSelectedTab(0);
                    }} className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                        style={{}}>
                        <span className="btn-wrapper--label">
                            My Current Activity
                        </span>
                    </Button>
                </Grid>
                <Grid item sm={2}>
                    <Button fullWidth onClick={() => {
                        setSelectedTab(1);
                    }} className={"btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"}
                        style={{ background: '#2b2b2b' }}>
                        <span className="btn-wrapper--label">
                            My Wishlist
                        </span>
                    </Button>
                </Grid>

            </Grid>
        </Container>

        {selectedTab === 0 && renderMyActivityTab()}
        {selectedTab === 1 && renderMyWishlistTab()}
    </div>
}

const mapStateToProps = (state: any) => ({
    lotWishlistData: state.myActivity.lotWishlistData,
    myBiddingActivityData: state.myActivity.myBiddingActivityData,
    lotWishlistPagination: state.myActivity.lotWishlistPagination,
    myBiddingActivityPagination: state.myActivity.myBiddingActivityPagination,
    error: state.myActivity.error,
    errorText: state.myActivity.errorText,
    success: state.myActivity.success,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    registrationStatus: state.participateButton.registrationStatus,
    profileId: state.loginForm.changeProfileId,
    isMyActivityWishlistObject: state.myActivity.isMyActivityWishlistObject,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotWishlistDataFetch_d: (profileId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveAllLotWishlist(profileId, filters, pagination, onCompletedCallback)),
        handleMyBiddingActivityDataFetch_d: (profileId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveAllMyBiddingActivity(profileId, filters, pagination, onCompletedCallback)),
        handleAuctionByIdFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionByIdFetch(auctionId, onCompletedCallback)),
        clearResponses_d: () => dispatch(clearResponses()),
        clearApiData_d: () => dispatch(clearApiData()),
        clearMyActivityWishlistStatus_d: () => dispatch(clearMyActivityWishlistStatus()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);