import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { clearApiResponses, handleBidProfilesFetch, handleBidProfileDelete, handleBidCompanyProfileDelete } from './store/BiddingProfile';
import NestedAccordianGrid, { Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordianGrid/NestedAccordianGrid'
import { Alert, Collapse, IconButton, Container, Tooltip, Button } from '@mui/material';
import { MdClose, MdDomainAdd } from "react-icons/md";
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";
import { PrembidLocalStorage } from "@/lib/utils/PrembidLocalStorage";
import { hideProgressSpinner, showProgressSpinner } from "@/lib/utils/ProgressSpinnerService";
import ns from '@/lib/utils/NotificationService';
import { FaEdit, FaTrashAlt, FaUserTag } from "react-icons/fa";
import { useSetProfileAsDefault } from "prembid-shared-library-npm/networking";
import { handleChangeProfile, handleRefreshProfiles } from "@/components/LoginForm/store/LoginForm";
import useHistory from "../../lib/hooks/useHistory";
import useStyles from '../BiddingProfile/ProfileStyles';
import { useConfirmationDialogActivator } from "../../components/containers/ConfirmationDialogActivator";

interface Props {
    refreshProfiles_d: () => void,
    handleBidProfileListFetch_d: () => void,
    handleBidProfileDelete_d: (id: string, onCompletedCallback: (response: any) => void) => void,
    handleBidCompanyProfileDelete_d: (id: string, onCompletedCallback: (response: any) => void) => void,
    handleChangeProfile_d: (profileId: string ) => void,
    clearResponses_d: () => void,
    error: boolean,
    errorText: string,
    reload: boolean,
    bidProfiles: any,
    loading?: boolean,
    settings: any,
}

export interface BiddingProfileDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: () => void;
}

const BiddingProfileList: React.FC<Props> = (props) => {

    const {
        handleBidProfileListFetch_d,
        handleBidProfileDelete_d,
        handleBidCompanyProfileDelete_d,
        clearResponses_d,
        error,
        errorText,
        reload,
        bidProfiles,
        loading,
        settings,
        handleChangeProfile_d,
    } = props;

    const { classes } = useStyles();
    const [bidProfileDisplayModel, setBidProfileDisplayModel] = React.useState<NestedGridDataModel>()
    const history = useHistory();
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    })

    const {isLoading:isSettingProfileAsDefault, mutate:setProfileAsDefault} = useSetProfileAsDefault({
        onSuccess(data,{profileId}){
            handleChangeProfile_d(profileId);
            PrembidLocalStorage.defaultProfile = profileId;
            ns.success(profileId, 'Profile successfully set as default.');
            showProgressSpinner({ description: "Retrieving Profiles..."});
            handleBidProfileListFetch_d();
        }
    });

    const confirmationDialog = useConfirmationDialogActivator();


    const showDeleteModal = useCallback((open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    },[deleteModal])

    const onDelete = useCallback((id: any) => {
        showDeleteModal(true, id);
    },[showDeleteModal])

    const onEdit = useCallback((id: string) => {

        const isCBiddingProfile = bidProfiles.cBiddingProfiles.filter(result => result.id === id)[0]
        const isIBiddingProfile = bidProfiles.iBiddingProfiles.filter(result => result.id === id)[0]

        if (isCBiddingProfile) {
            history.push(`/companyProfileEdit?profileId=${id}`)
        } else if (isIBiddingProfile) {
            history.push(`/individualProfileEdit?profileId=${id}`)
        }
    },[bidProfiles?.cBiddingProfiles, bidProfiles?.iBiddingProfiles, history])

    const onSetAsDefault = useCallback((id: string, profileName:string) => {
       confirmationDialog?.showConfirmationDialog({
            title:"Set as default profile",
            description: `Are you sure you want to set "${profileName}" as your default profile?`,
        },{
            onOkPress() {
                setProfileAsDefault({profileId:id})
            },
        });
    },[confirmationDialog?.showConfirmationDialog, setProfileAsDefault])


    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, [])

    useEffect(() => {
        showProgressSpinner({ description: "Retrieving Profiles..."});
        handleBidProfileListFetch_d()
    }, [handleBidProfileListFetch_d, reload])

    useEffect(()=>{

        if(isSettingProfileAsDefault) {
           return showProgressSpinner({ description: "Setting profile as default..."})
        };

        hideProgressSpinner();

    },[isSettingProfileAsDefault])

    //Until this screen has been refactored, 
    //Please do not add any other items to the dependency array as it is causing infinite rendering issues - as per Darryn
    useEffect(() => {
        if (bidProfiles) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Profile Name", align: "inherit", canFilter: false },
                { title: "Profile Type", align: "inherit", canFilter: true },
                { title: "Is Default Profile", align: "inherit", canFilter: true },
            ];

            bidProfiles.iBiddingProfiles.forEach((bidProfile) => {

                const fullName = `${ bidProfile.firstName} ${bidProfile.lastName}`;

                const cells: Cell[] = [
                    { text: fullName , align: "inherit" },
                    { text: "Individual", align: "inherit" },
                    {
                        text: bidProfile.isDefaultProfile ? 'Yes' : 'No',
                        element: bidProfile.isDefaultProfile?
                            <div className="badge badge-success badge-pill m-1 text-capitalize">Yes</div> :
                            <div className="badge badge-danger badge-pill m-1 text-capitalize">No</div>,
                        align: "inherit"
                    },
                ];

                rows.push({
                    id: bidProfile.id,
                    cells: cells,
                    actionButtons: [
                        bidProfile.isDefaultProfile ? undefined :
                            <Tooltip arrow title={'Set as default'} PopperProps={{ style: { zIndex: 0 } }}>
                            <Button onClick={()=>onSetAsDefault(bidProfile.id,fullName)} size="small" className="text-white btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2B2B2B' }}>
                                <FaUserTag />
                            </Button>
                        </Tooltip>,
                        <Tooltip arrow title="Edit" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                            <Button onClick={() => onEdit(bidProfile.id)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaEdit />
                            </Button>
                        </Tooltip>
                    ]
                });
            });

            bidProfiles.cBiddingProfiles.forEach(({id,isDefaultProfile,companyName}) => {

                const cells: Cell[] = [
                    { text: companyName, align: "inherit" },
                    { text: "Company", align: "inherit" },
                    {
                        text: isDefaultProfile ? 'Yes' : 'No',
                        element: isDefaultProfile?
                            <div className="badge badge-success badge-pill m-1 text-capitalize">Yes</div> :
                            <div className="badge badge-danger badge-pill m-1 text-capitalize">No</div>,
                        align: "inherit"
                    },
                ];



                rows.push({
                    id,
                    cells: cells,
                    actionButtons: [
                        isDefaultProfile? undefined :
                            <Tooltip arrow title={'Set as default'} PopperProps={{ style: { zIndex: 0 } }}>
                            <Button onClick={()=>onSetAsDefault(id,companyName)} size="small" className="text-white btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2B2B2B' }}>
                                <FaUserTag />
                            </Button>
                        </Tooltip>,
                        <Tooltip arrow title="Edit" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                            <Button onClick={() => onEdit(id)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaEdit />
                            </Button>
                        </Tooltip>,
                        <Tooltip arrow title={`${isDefaultProfile ? "Cannot delete your default profile. Please set another profile as your default and try again." : "Delete"}`} placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                            <span>
                                <Button onClick={() => onDelete(id)} size="small" className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" disabled={isDefaultProfile}>
                                    <FaTrashAlt />
                                </Button>
                            </span>
                        </Tooltip>
                    ]
                });
            });

            model = {
                headers: headers,
                rows: rows
            };
            setBidProfileDisplayModel(model);

            hideProgressSpinner();
        }

    }, [bidProfiles])

    const onAdd = () => {
        history.push('/companyProfileAdd')
    }

    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting profile..."});

        const isCBiddingProfile = bidProfiles.cBiddingProfiles.filter(result => result.id === id)[0]
        const isIBiddingProfile = bidProfiles.iBiddingProfiles.filter(result => result.id === id)[0]

        if (isCBiddingProfile) {
            handleBidCompanyProfileDelete_d(id, (res) => {
                if (res.success) {
                    ns.success('','Profile deleted successfully.')
                    PrembidLocalStorage.removeProfileFromCurrentUser(id, res.response)
                    showProgressSpinner({ description: "Retrieving Profiles..." });
                    handleBidProfileListFetch_d();
                }
                else {
                    ns.error('', res.error ?? 'An error occurred while deleting this profile. Please try again.')
                }
                hideProgressSpinner();
            });
        } else if (isIBiddingProfile) {

            handleBidProfileDelete_d(id, (res) => {
                if (res.success) {
                    ns.success('','Profile deleted successfully.')
                    PrembidLocalStorage.removeProfileFromCurrentUser(id, res.response)
                    showProgressSpinner({ description: "Retrieving Profiles..." });
                    handleBidProfileListFetch_d();
                }
                else {
                    ns.error('', res.error ?? 'An error occurred while deleting this profile. Please try again.')
                }
                hideProgressSpinner();
            });
        }

        showDeleteModal(false, '');
    }

    const renderTableActions = [
        <Tooltip arrow title="Add Company Profile" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
            <Button onClick={() => onAdd()} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                <MdDomainAdd size={20} />
            </Button>
        </Tooltip>
    ];

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <div className="mb-5 pt-5 pl-5 pr-5 pb-4 mt-5" />
        <Container className={`pb-5 ${classes.containerWidth}`} maxWidth={false} >
            {!loading && <>
                <div className="card-box card-header py-3 mb-4">
                    <div className="card-header--title font-size-lg">
                        Profiles
                    </div>
                </div>
                <div className="divider"  />
                <div className="table-responsive-md">
                    <NestedAccordianGrid dataModel={bidProfileDisplayModel} tableActions={renderTableActions} />
                    <ConfirmationDialog
                        open={deleteModal.open}
                        payload={deleteModal.id}
                        okButtonText="Ok"
                        cancelButtonText="Cancel"
                        description="Are you sure you want to delete this profile?"
                        title="Delete Profile"
                        onCancel={onDeleteModalClosed}
                        onOk={onDeleteModalOk}
                    />
                </div>

            </>}

            {error && (
                <Collapse in={error}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    clearResponses_d()
                                }}
                            >
                                <MdClose fontSize="inherit" />
                            </IconButton>
                        }
                        className="mb-4" severity="error">
                        {errorText}
                    </Alert>
                </Collapse>
            )}
        </Container>
    </div>);
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        refreshProfiles_d: () => dispatch(handleRefreshProfiles()),
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleBidProfileListFetch_d: () => dispatch(handleBidProfilesFetch()),
        handleBidProfileDelete_d: (id: string, onCompletedCallback: (response: any) => void) => dispatch(handleBidProfileDelete(id, onCompletedCallback)),
        handleBidCompanyProfileDelete_d: (id: string, onCompletedCallback: (response: any) => void) => dispatch(handleBidCompanyProfileDelete(id, onCompletedCallback)),
        handleChangeProfile_d: (profileId: string) => dispatch(handleChangeProfile(profileId)),
    };
};

const mapStateToProps = (state: any) => ({
    error: state.bidProfile.error,
    errorText: state.bidProfile.errorText,
    reload: state.bidProfile.reload,
    auctions: state.bidProfile.auctions,
    bidProfiles: state.bidProfile.bidProfiles,
    settings: state.settings.settings,
});

export default connect(mapStateToProps, mapDispatchToProps)(BiddingProfileList);
