import { Table } from '@mui/material';
import { FC, memo } from 'react';
import { useBiddingTableStyles } from '../BiddingTable.styles';
import { Skeleton } from '../../../dumb/Skeleton';
import { CurrencyLabel } from '../../../composite/CurrencyLabel';

type TableOfBidsProps = {
    showLoading?: boolean;
    tableRowData: any[];
    showPaddleNumber: boolean;
    paddleNumber: string;
    bidTableRef: any;
}

const TableOfBids: FC<TableOfBidsProps> = ({ showLoading = false, tableRowData, showPaddleNumber, paddleNumber, bidTableRef }) => {

    const { classes: { biddingHistoryTable, biddingHistoryTableRow } } = useBiddingTableStyles();

    return <>
        {
            showLoading ? (
                <Skeleton animation="wave" variant="rectangular" height="200px" width="100%" />
            ) : (
                <Table className={'table table-bordered ' + biddingHistoryTable} ref={bidTableRef}>
                    <thead className="thead-light">
                        <tr>
                            {
                                !showPaddleNumber ? (
                                    <th className={biddingHistoryTableRow} style={{ width: '0.1%' }}></th>) : (
                                    <th className={biddingHistoryTableRow} style={{ width: '100%' }}>Paddle number</th>)
                            }
                            <th className={biddingHistoryTableRow} style={{ width: '100%', textAlign: 'center' }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRowData.map((bid, idx) => {
                            return (
                                <>
                                    {bid?.paddleNumber === paddleNumber ?
                                        <tr key={idx} className={"bg-primary " + biddingHistoryTableRow}>
                                            {!showPaddleNumber && <td className={biddingHistoryTableRow} style={{ width: '1%' }}></td>}
                                            {showPaddleNumber && <td className="text-white" style={{ padding: '4px', height: '30px' }}>{bid?.paddleNumber}</td>}
                                            <td className="text-white" style={{ width: '100%', textAlign: 'end', padding: '4px', height: '30px' }}>
                                                <CurrencyLabel value={bid?.amount} textColour={"text-white"} />
                                            </td>
                                        </tr> :
                                        <tr key={idx} className={biddingHistoryTableRow}>
                                            {!showPaddleNumber && <td className={biddingHistoryTableRow} style={{ width: '1%' }}></td>}
                                            {showPaddleNumber && <td className="text-black" style={{ padding: '4px', height: '30px' }}>{bid?.paddleNumber}</td>}
                                            <td className="text-black" style={{ width: '100%', textAlign: 'end', padding: '4px', height: '30px' }}>
                                                <CurrencyLabel value={bid?.amount} textColour={"text-black"} />
                                            </td>
                                        </tr>}
                                </>
                            )
                        })}
                    </tbody>
                </Table>
            )
        }
    </>
}

export default memo(TableOfBids);