import { FC, useCallback } from "react";
import { Avatar, Box, Card, Divider, Stack, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { isMobile } from 'react-device-detect';
import { LotContact } from "prembid-shared-library-npm/types";
import { useLotContactListStyles } from "../LotContactList.styles";
import { RootState } from "../../../../store/Store";
import { useSelector } from "react-redux";

export type LotContactDisplayProps = LotContact;

const LotContactDisplay: FC<LotContactDisplayProps> = ({ firstName, lastName, email, imageUrl, phoneNumber }) => {
    const { classes } = useLotContactListStyles();

    const { settings } = useSelector((state: RootState) => state.settings);

    const renderPhoneNumber = useCallback(() => {
        return (
            <Stack alignItems={'center'} className={classes.link}>
                <CallIcon className={classes.buttonIcon} style={{ background: settings?.Styles?.Primary }} />
                <Typography
                    variant="body2"
                    className={classes.buttonLabel}
                    style={{
                        fontSize: '14px',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        textAlign: 'center',
                    }}
                >
                    {isMobile ? 'Call' : phoneNumber}
                </Typography>
            </Stack>
        );
    }, [classes.buttonIcon, classes.buttonLabel, classes.link, phoneNumber, settings?.Styles?.Primary]);

    return (
        <Card raised={true}>
            <Stack direction={'row'}>
                <Box className={classes.imageContainer}>
                    <Avatar src={imageUrl} className={classes.image} />
                </Box>
                <Box className={classes.infoContainer}>
                    <Stack>
                        <Typography
                            variant="h6"
                            className={classes.contactHeading}
                            style={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {`${firstName} ${lastName}`}
                        </Typography>
                        <Divider />
                        <Stack direction={'row'} className={classes.contactButtonsContainer}>
                            {isMobile ? (
                                <a href={`tel:${phoneNumber}`}>
                                    {renderPhoneNumber()}
                                </a>
                            ) : (
                                renderPhoneNumber()
                            )}
                            <a href={`mailto:${email}`}>
                                <Stack alignItems={'center'} className={classes.link}>
                                    <MailOutlineIcon className={classes.buttonIcon} style={{ background: settings?.Styles?.Primary }} />
                                    <Typography
                                        variant="body2"
                                        className={classes.buttonLabel}
                                        style={{
                                            fontSize: '14px',
                                            overflow: 'hidden',
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {email}
                                    </Typography>
                                </Stack>
                            </a>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
};

export default LotContactDisplay;