import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import AuctionStackedCard from "@/components/Cards/AuctionStackedCard";
import { Button, CircularProgress, Container, Grid } from "@mui/material";
import { useRetrieveHomePageData } from "prembid-shared-library-npm/networking";
import {
    hideProgressSpinner,
    showProgressSpinner,
} from "@/lib/utils/ProgressSpinnerService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useStyles from '../Home/HomeStyles';
import { HeaderBlock } from "../../components/composite/HeaderBlock";
import { SectionTitle } from "../../components/dumb/SectionTitle";

const responsiveStyles = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

interface Props {
    settings: any;
    loading: boolean;
}

const Home: React.FC<Props> = ({ settings, loading }) => {
    const [upcomingAuctions, setUpcomingAuctions] = React.useState<any[]>();
    const [recentAuctions, setRecentAuctions] = React.useState<any[]>();
    const { classes } = useStyles();
    const { data: homePageData, isLoading: isLoadingHomePageData, isFetching: isFetchingHomePageData } =
        useRetrieveHomePageData(undefined, { cacheTime: Infinity });

    const [headerBlockData, setHeaderBlockData] = React.useState({
        header: "",
        description: "",
        timerHeader: "",
        timerDescription: "",
    });

    const splitAuctionData = useCallback(() => {
        if (homePageData.upcomingAndRecentAuctions !== undefined) {
            let upcomingArr: any[] = [];
            let recentArr: any[] = [];
            let iCounter = 0;

            homePageData.upcomingAndRecentAuctions.map((x) => {
                if (x.status === "UPCOMING" && iCounter < 10) {
                    iCounter++;
                    upcomingArr.push(x);
                }
            });

            iCounter = 0;

            homePageData.upcomingAndRecentAuctions.map((x) => {
                if (x.status === "RECENT" && iCounter < 10) {
                    iCounter++;
                    recentArr.push(x);
                }
            });

            const upcoming = upcomingArr;
            const recent = recentArr;

            setUpcomingAuctions(upcoming);
            setRecentAuctions(recent);
        }
    }, [homePageData?.upcomingAndRecentAuctions]);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: "auto" });
    }, []);

    useEffect(() => {
        setHeaderBlockData({
            header: settings.VendorName,
            description: settings.VendorSlogan,
            timerHeader: "Next " + settings.AuctionName,
            timerDescription: "",
        });
    }, [settings]);

    useEffect(() => {
        if (homePageData) {
            splitAuctionData();
        }
    }, [homePageData, splitAuctionData]);

    useEffect(() => {
        if (isFetchingHomePageData === false) {
            hideProgressSpinner();
        }

        if (isFetchingHomePageData === true) {
            showProgressSpinner();
        }
    }, [isFetchingHomePageData]);

    return (
        <div
            style={{
                background: settings?.Styles?.OverridePageBackgroundColor
                    ? settings?.Styles?.OverridePageBackgroundColor
                    : "#ffffff",
            }}
        >
            <div>
                <div className="mb-5">
                    {upcomingAuctions !== undefined &&
                        upcomingAuctions[0] !== undefined &&
                        upcomingAuctions instanceof Array ? (
                        <HeaderBlock
                            description={upcomingAuctions[0].name}
                            timerHeader={headerBlockData.timerHeader}
                            timerDescription={upcomingAuctions[0].startDateTimeAt}
                            timerBtnText={"View " + settings.AuctionName}
                            timerBtnSrc={`/Lots/${upcomingAuctions[0].id}`}
                            imageSrc={settings.VendorBannerUrl}
                        />
                    ) : (
                        <HeaderBlock imageSrc={settings.VendorBannerUrl} />
                    )}
                </div>

                <div className="text-center mb-5" >
                    <SectionTitle title={"Open and Upcoming " + settings.AuctionName + "s"} subTitle={"See what exciting " + settings.AuctionName + "s and " + settings.LotName + "s are open and upcoming"} />
                </div>

                {!isLoadingHomePageData ? (
                    <>
                        <div className="pb-5">
                            <Container key={"upcomingContainer"} maxWidth={false} className={classes.containerWidth}>
                                {upcomingAuctions &&
                                    upcomingAuctions.length > 0 ? (
                                    <Carousel
                                        swipeable={true}
                                        draggable={true}
                                        showDots={true}
                                        responsive={responsiveStyles}
                                    >
                                        {upcomingAuctions instanceof Array &&
                                            upcomingAuctions.map((auction, idx) => {
                                                return (
                                                    <div key={"upcoming" + idx} className="pl-2 pr-2 pt-1 pb-4">
                                                        <AuctionStackedCard
                                                            key={auction.id}
                                                            imgSrc={
                                                                auction.imageUrl
                                                            }
                                                            infoBadgeText={auction.depositAmount || 0}
                                                            auctionType={auction.auctionType}
                                                            hasBadge
                                                            hasExtensions={
                                                                auction?.lotBidExtensionCount > 0 ? true : false
                                                            }
                                                            hasDeposit
                                                            settings={settings}
                                                            header={auction.name}
                                                            description={auction.description}
                                                            successBadgeText={auction.auctionType.toString()}
                                                            dangerBadgeText={`${auction.numberOfLots} ${settings.LotName +
                                                                (auction.numberOfLots > 1 ? "s" : "")
                                                                }`}
                                                            startDateTimeAt={auction?.startDateTimeAt}
                                                            endDateTimeAt={auction?.endDateTimeAt}
                                                            auctionEndDateTimeAt={
                                                                auction?.auctionEndDateTimeAt
                                                            }
                                                            btnText={
                                                                "View " +
                                                                `${auction.numberOfLots} ${settings.LotName +
                                                                (auction.numberOfLots > 1 ? "s" : "")
                                                                }`
                                                            }
                                                            btnSrc={`/Lots/${auction.id}`}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </Carousel>
                                ) : (
                                    <div className="text-center mb-5" >
                                        <SectionTitle title={"We currently have no upcoming " + settings.AuctionName + "s at this time. Please check back soon to see our next " + settings.AuctionName + "s"} />
                                    </div>
                                )}
                            </Container>
                        </div>

                        {recentAuctions && recentAuctions.length > 0 ? (
                            <>
                                <div
                                    className="bg-composed-wrapper mb-5"
                                    style={{
                                        background: settings?.Styles
                                            ?.OverrideHomeMiddleSectionBackgroundColor
                                            ? settings?.Styles
                                                ?.OverrideHomeMiddleSectionBackgroundColor
                                            : settings?.Styles?.Primary,
                                    }}
                                >
                                    <div className="bg-composed-wrapper--content text-center text-light">
                                        <div className="pt-4 pb-4" />
                                    </div>
                                </div>

                                <div className="pb-5">
                                    <Container key={"recentContainer"} maxWidth={false} className={classes.containerWidth}>
                                        {
                                            recentAuctions && recentAuctions.length > 0 &&
                                            <>
                                                <div className="text-center mb-5" >
                                                    <SectionTitle title={"Completed " + settings.AuctionName + "s"} subTitle={"See the " + settings.AuctionName + "s and associated " + settings.LotName + "s that have closed"} />
                                                </div>

                                                <Carousel
                                                    dotListClass="pt-5"
                                                    swipeable={true}
                                                    draggable={true}
                                                    showDots={true}
                                                    responsive={responsiveStyles}
                                                >
                                                    {recentAuctions instanceof Array &&
                                                        recentAuctions.map((auction, idx) => {
                                                            return (
                                                                <div key={"recent" + idx} className="pl-2 pr-2 pt-1 pb-4">
                                                                    <AuctionStackedCard
                                                                        key={auction.id}
                                                                        infoBadgeText={auction.depositAmount}
                                                                        startDateTimeAt={auction.startDateTimeAt}
                                                                        endDateTimeAt={auction.endDateTimeAt}
                                                                        auctionEndDateTimeAt={
                                                                            auction?.auctionEndDateTimeAt
                                                                        }
                                                                        imgSrc={
                                                                            auction.imageUrl
                                                                        }
                                                                        auctionType={auction.auctionType}
                                                                        hasExtensions={
                                                                            auction?.lotBidExtensionCount > 0
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        settings={settings}
                                                                        header={auction.name}
                                                                        description={auction.description}
                                                                        successBadgeText={1000}
                                                                        dangerBadgeText={""}
                                                                        btnText={
                                                                            "View " +
                                                                            `${auction.numberOfLots} ${settings.LotName +
                                                                            (auction.numberOfLots > 1 ? "s" : "")
                                                                            }`
                                                                        }
                                                                        btnSrc={`/Lots/${auction.id}`}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                </Carousel>
                                            </>
                                        }
                                    </Container>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <div className='pb-5'>
                        <Grid container justifyContent="center" >
                            <Grid xl={2} item container justifyContent="center">
                                <CircularProgress className="text-center" style={{ color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
                            </Grid>
                        </Grid>
                    </div>
                )}

            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    loading: state.progressSpinner.loading,
});

export default connect(mapStateToProps)(Home);
