import { apiPaths } from '@/networking/api/ApiPaths';
import requestClient from '@/networking/api/RequestClient';

//Action
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_RESET_STATE = 'REGISTER_RESET_STATE';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false,
    identityUserId: undefined,
}


export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case REGISTER_SUCCESS: return { ...state, success: true, error: false, identityUserId: action.payload }
        case REGISTER_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        case REGISTER_RESET_STATE: return { ...state, success: false, error: false }
        default: return state;
    }
}

//Action creator
export const registerSuccess = (id: any) => {
    return { type: REGISTER_SUCCESS, payload: id }

}

export const registerError = (errorText: string) => {
    return { type: REGISTER_ERROR, payload: errorText }
}

export const registerResetState = () => {
    return { type: REGISTER_RESET_STATE, payload: REGISTER_RESET_STATE }
}


//Thunks
export const handleSubmit = (email: string, phoneNumber: string, logoUrl: string, password: string, firstName: string, lastName: string) => {

    let userInfo = {
        email: email.replace(" ", ""),
        phoneNumber: phoneNumber,
        logoUrl: logoUrl,
        password: password,
        firstName: firstName,
        lastName: lastName
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.register, userInfo)
            .then(function (response) {
                if (response.success) {
                    let now = new Date(Date.now()).toISOString();
                    let termsAcceptanceInfo = {
                        termsAcceptedAt: now,
                        userId: response.response,
                    };
                    requestClient.post(apiPaths.account.upsertTermsAcceptance, termsAcceptanceInfo).then(function (upsertTermsResponse) {
                        dispatch(registerSuccess(response.response))
                    });
                }
                else {
                    dispatch(registerError(response.error))
                }
            })
    }

}